<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <flash-message></flash-message>
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">
              <router-link :to="{ name: 'coachInquiryList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link>Inquiry View
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12"> 
            <div class="block-content p-5 bg-white min-h"> 
              <div class="mb-5" v-for="(inquiryData,index) in inquiryListData.data" :key="index">
                <label :class="['mb-2 fw-medium para18', (id==inquiryData.created_by)?'text-green':'text-muted']">
                  <img :src="(id==inquiryData.created_by)?baseUrl+'/public/images/coach-thumb.svg':baseUrl+'/public/images/pic.svg'" class="user-pic me-3">{{(id==inquiryData.created_by)?inquiryData.coach_first_name+ ' '+inquiryData.coach_last_name:inquiryData.user_first_name+ ' '+inquiryData.user_last_name}}
                </label>
                <div class="bg-grey border p-4 rounded-4">
                  <p class="para-sm text-dark"><span class="fw-medium">Subject:</span> {{inquiryData.subject}}</p>
                  <p class="para-sm text-dark"><span class="fw-medium">Message:</span> {{inquiryData.message}}</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="text-center pagger mt-5">
        <pagination align="center" :limit=limit size="large" :data="inquiryListData" @pagination-change-page="getInquiryData" />
      </div>
    </div>
  </section>
</div>
</template>

<script>
import axiosInstance from '../../../helper/axiosInstance'
import jwt_decode from "jwt-decode";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
	data() {
		return {
      baseUrl: configObj.baseUrl,
			inquiryListData:{
        type:Object,
        default: null
      },
		};
	},
  computed: {
    coachFullName(){
      const getLocalToken = this.$store.state.auth.authData.token;
      if(getLocalToken){
        const roleInToken = jwt_decode(getLocalToken)
        return roleInToken.first_name +' '+ roleInToken.last_name ;
      }
    },
    id(){
      const getLocalToken = this.$store.state.auth.authData.token;
      if(getLocalToken){
        const roleInToken = jwt_decode(getLocalToken)
        return roleInToken.sub ;
      }
    },
  },
  mounted(){
    this.getInquiryData()
  },
  methods: {
     async getInquiryData(page = 1) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      axiosInstance.get(`coach/inquiries/conversation/${vueInstance.$route.params.groupId}?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }
  
        if(response && Object.keys( response.data.response.data).length > 0)
          //vueInstance.usersListData = JSON.stringify(response.data.response.data)
          vueInstance.inquiryListData = response.data.response
      }).catch(error=> { 
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
  }
	
	
}
</script>

