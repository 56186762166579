<template>
  <div class="modal show" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <div class="m-4">
              <div class="mb-5">
                <img :src="baseUrl+'/public/images/rest-password.svg'">
              </div>
              <div class="heading20 text-green mb-5">{{modalMsg}}</div>

              <div class="text-center mx-md-5 d-grid gap-2">
                <a href="#" class="btn btn-primary text-center" data-bs-dismiss="modal" aria-label="Close">Done</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>

export default {
  props:['modalMsg'],
  data() {
    return {
      baseUrl: configObj.baseUrl,
    };
  },
};
</script>
