<template>
  
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Resources</div>
          </div>
          <div class="col-md-4 text-end mb-3">
            <a href="#"  v-if="previewImage && mediaUrl" data-bs-toggle="modal" data-bs-target="#previewModal"  class="btn btn-primary btn-rounded btn-green me-4">Preview</a>
          </div>
        </div>
        <div class="row justify-content-center"> <flash-message></flash-message>
          <div class="col-lg-12">
            <form @submit="addPodcast" enctype="multipart/form-data">
              <div class="block-content p-5 bg-white min-h">
                <div class="text22 fw-medium mb-5 text-green text-center">Create New Podcast</div>
                <div class="mb-4">
                  <label class="mb-2">Podcast Title</label>
                  <input v-model.trim="title" @input="$v.title.$touch()" type="text" class="form-control bg-grey border " placeholder="">
                  <div v-if="$v.title.$dirty">
                    <p class="text-danger" v-if="!$v.title.required">Please enter podcast title.</p>
                    <p class="text-danger" v-if="!$v.title.minLength || !$v.title.maxLength ">The podcast title must be between {{$v.title.$params.minLength.min}} and {{$v.title.$params.maxLength.max}} characters.</p>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="mb-2">Upload Banner Image</label>
                  <div class="input-group">
                    <input @change="onImageChange" type="file" class="form-control bg-grey border " id="inputGroupFile02">
                    <label class="input-group-text input-bg-green text-white" for="inputGroupFile02">Browse Image</label>
                  </div>
                  <div class="text-end text-muted para-sm">Image size (1000x600)</div>
                  <div v-if="$v.image.$dirty">
                    <p class="text-danger" v-if="!$v.image.required">Please upload article image.</p>
                  </div>

                </div>
                <div class="mb-4">
                  <label class="mb-2">Media url</label>
                  <div class="input-group">
                    <input v-model.trim="mediaUrl" @input="$v.mediaUrl.$touch()" type="text" class="form-control bg-grey border " placeholder="">
                  </div>
                  <div v-if="$v.mediaUrl.$dirty">
                      <p class="text-danger" v-if="!$v.mediaUrl.required">Please enter podcast title.</p>
                      <p class="text-danger" v-if="!$v.mediaUrl.url">Please enter valid url.</p>
                    </div>
                </div>
                <div class="mb-4">
                  <label class="mb-2">Status</label>
                  <div class="input-group">
                    <select v-model="status" class="browser-default custom-select form-control">
                      <option selected value="publish">Publish</option>
                      <option value="draft">Draft</option>
                    </select>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="mb-2">Podcast Description (Body Content)</label>
                  <textarea v-model.trim="content" class="form-control bg-grey border " rows="6" placeholder="Enter Description here"></textarea>
                  <div v-if="$v.content.$dirty">
                    <p class="text-danger" v-if="!$v.content.required">Please enter article Description.</p>
                  </div>
                </div>
                <div class="text-end mt-5">
                  <button type="submit"  class="btn btn-primary text-center btn-rounded">Save</button>
                </div>
              </div>
            </form>  
          </div>
        </div>
      </div>
    </section>
    <div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog rounded-0">
        <div class="modal-content rounded-0">
          <div class="modal-header border-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="blog-list-box">
              <img :src="previewImage" class="w-100 mb-5" alt="audio">
              <div class="player-box py-5 text-center"  v-html="previewMediaUrl"></div>
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <h3 class="heading22 fw-medium mb-4">{{title}}</h3>
                  <p class="para-md mb-5">{{content}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, url, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from 'vuex'	

//const imageRule = helpers.regex('image', /\.(jpe?g|png)$/)
export default {
  
	data() {
		return {
      baseUrl: configObj.baseUrl,
			spinnerShow: false,
      error: null,
      previewImage: null,
      previewMediaUrl: null,
      title:"",
      image: "",
      mediaUrl:"",
      content: "",
      status:"publish",
      type:"public",
		};
	},
  watch: {
    mediaUrl: function (val) {
      let vueInstance = this;
      if (!vueInstance.$v.mediaUrl.$invalid) { 
        vueInstance.previewMediaUrl = `<audio controls>
          <source src="${val}" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>`
      }
      
    },
    
  },
  validations: {
    image: {
      required,
    //  imageRule
    },
    mediaUrl: {
      required,
      url
    },
    title: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350)
    },
    content: {
      required,
    },
    
  },
  methods: {
    onImageChange(e){ 
      this.image =e.target.files[0];
      this.previewImage = URL.createObjectURL(this.image);
    },
    ...mapActions("assessment", {
      actionAddPodcast: "addPodcast",
    }),
    async addPodcast(e){
      e.preventDefault();
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        const authData =vueInstance.$store.getters['auth/getAuthData'];

        const config = {
          headers: { 
            'content-type': 'multipart/form-data',
            'Authorization' : `Bearer ${authData.token}`
          }
        }

        let formData = new FormData();
        
        formData.append('title', vueInstance.title);
        formData.append('type', vueInstance.type);
      //  formData.append('image', vueInstance.image);
        formData.append('status', vueInstance.status);
        formData.append('description', vueInstance.content);
        formData.append('media', vueInstance.mediaUrl);
        //formData.append('access_token', vueInstance.mediaFile);
        axios.post(vueInstance.baseUrl+'/api/admin/podcasts', formData, config)
        .then(results => {
          if (results.data.error == true) { 
            vueInstance.flashError(results.data.message, { timeout: 4000 });
            return false
          }
          vueInstance.flashSuccess(results.data.message, { timeout: 4000 });
          vueInstance.$router.push({ name: "adminResourcesArticle" });
          return false;
        }).catch(error => {
          vueInstance.flashError(err.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });

        vueInstance.spinnerShow = false;
      }
    },
  }
}

</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
