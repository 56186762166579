<template>
  <div>
    <div
      class="
        banner-section
        d-flex
        w-100
        align-items-center
        position-relative
        inner-banner
        justify-content-center
      "
    >
      <img
        :src="baseUrl + '/public/images/banner3.svg'"
        class="d-inline-block banner-img"
        alt="..."
      />
      <div
        class="position-absolute w-100 h-100 d-flex align-items-center top-0"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <h1 class="mb-5 text-white text-center">Cards</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="py-5">
      <div class="container">
        <div class="row justify-content-center">
             <flash-message></flash-message>
          <div class="col-xxl-6">
            <div class="mb-5">
              <router-link :to="{ name: 'cardsList', params: { assessmentId: this.$route.params.assessmentId } }">
                <img
                  :src="baseUrl + '/public/images/arrow_left.svg'"
                  class="me-4"
                />
                Add New Card
              </router-link>
            </div>
            <div class="mb-5">
              <label for="name" class="form-label para-md">Name on Card</label>
              <input
                type="text"
                class="form-control border-bottom rounded-0 ps-0"
                id="name"
                placeholder="John Doe"
                v-model.trim="cardName" @input="$v.cardName.$touch()"
              />
              <div v-if="$v.cardName.$dirty">
                <p class="text-danger" v-if="!$v.cardName.required">
                    Please enter card name field.
                </p>
                </div>
            </div>
            <div class="mb-5">
              <label for="Card_Number" class="form-label para-md"
                >Card Number</label
              >
              <input
                type="text"
                class="form-control border-bottom rounded-0 ps-0 master"
                id="Card_Number"
                placeholder="XXXX XXXX XXXX XXXX"
                v-model.trim="cardNo" @input="$v.cardNo.$touch()"
                maxlength="19" @keyup="formatCardNumber"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              />
              <div v-if="$v.cardNo.$dirty">
                <p class="text-danger" v-if="!$v.cardNo.required">
                    Please enter valid card number field.
                </p>
                </div>
            </div>
            <div class="d-flex mb-5">
              <div class="flex-fill me-3">
                <label for="ex" class="form-label para-md">Expiry Date</label>
                <input
                  type="text"
                  class="form-control border-bottom rounded-0 ps-0"
                  id="ex"
                  placeholder="00 / 00"
                  v-model.trim="cardExpiry" @input="$v.cardExpiry.$touch()"
                  autocomplete="off"  v-on:keyup="formatCardExpirationDate" maxlength='5'
                />
                <div v-if="$v.cardExpiry.$dirty">
                    <p class="text-danger" v-if="!$v.cardExpiry.required">
                      Please enter card expiry date.
                    </p>
                </div>
              </div>
              <div class="flex-fill ms-3">
                <label for="cvv" class="form-label para-md">CVV</label>
                <input
                  type="password"
                  class="form-control border-bottom rounded-0 ps-0"
                  id="cvv"
                  placeholder="000"
                  v-model.trim="cardCVV" @input="$v.cardCVV.$touch()"
                  autocomplete="off" maxlength='4'
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
                <div v-if="$v.cardCVV.$dirty">
                    <p class="text-danger" v-if="!$v.cardCVV.required">
                      Please enter card CVV number.
                    </p>
                </div>
              </div>
            </div>

            <div class="form-check mb-5">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked
                v-model.trim="cardSave"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Save this card details
              </label>
            </div>
            <div class="pt-md-5 text-center d-flex">
              <a href="#" class="btn btn-light m-2 flex-fill">Cancel</a>
              <button
                type="button"
                class="btn btn-primary br-radius m-2 flex-fill"
                 @click.prevent="cardSubmit"
              > <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from "../../../../helper/axiosInstance";
import { required, email } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            baseUrl: configObj.baseUrl,
            spinnerShow: false,
            cardName: '',
            cardNo: '',
            cardExpiry: '',
            cardCVV: '',
            cardSave: true,
        };
    },
    validations: {
        cardName: {
            required
        },
        cardNo: {
            required,
        },
        cardExpiry: {
            required
        },
        cardCVV: {
            required,
        },

    },

  methods: {
      async cardSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();
        let cardYearMonth =  vueInstance.cardExpiry.split('/');

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        const data = {
            card_name: vueInstance.cardName,
            card_number: vueInstance.cardNo,
            expiration_date: '20'+cardYearMonth[1]+'-'+cardYearMonth[0],
            card_code: vueInstance.cardCVV,
            save_card: vueInstance.cardSave,
        };

        axiosInstance.post(`assessments/${vueInstance.$route.params.assessmentId}/subscribe`,data).then(results => {
            if (results.data.error == false) {
                vueInstance.flashSuccess(results.data.message, { timeout: 10000});
                vueInstance.spinnerShow = false;
                $(".btn").prop("disabled", false);
                vueInstance.$router.push({name:'cardsList', params:{  assessmentId: this.$route.params.assessmentId}})
                return false;
            }else{
                vueInstance.flashError(results.data.message, { timeout: 10000});
                vueInstance.spinnerShow = false;
                $(".btn").prop("disabled", false);
                return false;
          }
        }).catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000});
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
      }
    },

    async formatCardExpirationDate(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }

      event.target.value = event.target.value.replace(
        /^([1-9]\/|[2-9])$/g, '0$1/'
      ).replace(
        /^(0[1-9]|1[0-2])$/g, '$1/'
      ).replace(
        /^([0-1])([3-9])$/g, '0$1/$2'
      ).replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2'
      ).replace(
        /^([0]+)\/|[0]+$/g, '0'
      ).replace(
        /[^\d\/]|^[\/]*$/g, ''
      ).replace(
        /\/\//g, '/'
      );
    },

    async formatCardNumber(e) {
      var str = this.cardNo.replace(/[^0-9]/gim,'')
      if(str){

        var numberChunks = str.match(/.{1,4}/g);
        var result = numberChunks.join(' ');
        $(e.currentTarget).val(result)
      }
    }
  },
};
</script>
