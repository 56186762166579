<template>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Resources</div>
          </div>
          <div class="col-md-4 text-end mb-3">
            <a href="#" @click.prevent="editArticle" class="btn btn-primary text-center btn-rounded">Post</a>
          </div>
        </div>
        <div class="row justify-content-center"> <flash-message></flash-message>
          <div class="col-lg-12">
        <div class="block-content p-5 bg-white min-h">
          <div class="blog-list-box">
            <div class="img-box position-relative blog-img-box">
              <img v-if="image" :src="image" class="img-fluid w-100" alt="Blog">
            </div>
            <div class="p-4">
              <div class="mb-4">
                <input v-model.trim="title" @input="$v.title.$touch()" type="text" class="form-control bg-grey border " placeholder="Good Life Practices And Peaceful Destinations Is The Goal">
                <div v-if="$v.title.$dirty">
                  <p class="text-danger" v-if="!$v.title.required">Please enter aticle title.</p>
                  <p class="text-danger" v-if="!$v.title.minLength || !$v.title.maxLength ">The aticle title must be between {{$v.title.$params.minLength.min}} and {{$v.title.$params.maxLength.max}} characters.</p>
              </div>
              </div>
              <div class="mb-4">
                <textarea v-model.trim="content" class="form-control bg-grey border " rows="6" />
                <div v-if="$v.content.$dirty">
                  <p class="text-danger" v-if="!$v.content.required">Please enter article Description.</p>
              </div>
              </div>
            </div>
          </div>

          <div class="text-end mt-5">
            <a href="#" @click.prevent="editArticle" class="btn btn-primary text-center btn-rounded">Post</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from 'vuex'	

export default {
  
	data() {
		return {
			spinnerShow: false,
      error: null,
      articleData: {},
      title:"",
      image: "",
      content: "",
		};
	},
  validations: {
    title: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350)
    },
    content: {
      required,
    },
    
  },
  mounted() {
      this.getArticlesData();
  },
  methods: {
    ...mapActions("assessment", {
      actionEditArticle: "editArticle",
    }),
    ...mapActions("assessment", {
      actionGetSignalArticle: "getSignalArticle",
    }),
    async getArticlesData(){
      let vueInstance = this;
      await vueInstance.actionGetSignalArticle({id : this.$route.params.id, assessmentId: this.$route.params.assessmentId}).then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        vueInstance.articleData = results.response;
        vueInstance.title = vueInstance.articleData.title;
        vueInstance.content = vueInstance.articleData.description;
        vueInstance.image = vueInstance.articleData.image;
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },
    async editArticle(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
         
        await vueInstance.actionEditArticle({
          title: vueInstance.title,
          articleId: vueInstance.$route.params.id,
          assessmentId: vueInstance.$route.params.assessmentId,
          content: vueInstance.content,
        })
        .then(results  => {
          $(".btn").prop("disabled", false);
          if (results.error == true) { 
            vueInstance.flashError(results.message, { timeout: 4000 });
            return false
          }
          vueInstance.flashSuccess(results.message, { timeout: 4000 });
          vueInstance.$router.push({ name: "adminResourcesArticle" });
          return false;
            
        }).catch((err) => {
          vueInstance.flashError(err.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        })

        vueInstance.spinnerShow = false;
      }
    },
  }
}

</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
