<template>
  <div>
    <div class="section bg-grey wrap">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-5 col-xl-4">
            <h3 class="text-green text-center mb-3">Reset Password</h3>
            <p class="text-center mb-5 text-green">Please reset your password.</p>
            
            <!-- <div class="mb-5">
              <label for="exampleInputPassword1" class="form-label d-none">Old Password</label>
              <div class="input-group mb-3" id="show_hide_password">
                <input :type="oldPasswordFieldType" v-model.trim="oldPassword" @input="$v.oldPassword.$touch()" class="form-control password-input" placeholder="Enter your old password" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <span @click.prevent="switchVisibilityOldPW" class="input-group-text" id="basic-addon2"><a href="#" class="eye"><i :class="[oldPwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
              </div>
              <div v-if="$v.oldPassword.$dirty">
                <p class="text-danger" v-if="!$v.oldPassword.required">Please enter old password.</p>
              </div>
            </div>   -->
            <div class="mb-5">
              <label for="exampleInputPassword1" class="form-label d-none">Password</label>
              <div class="input-group mb-3" id="show_hide_password">
                <input :type="passwordFieldType" v-model.trim="password" @input="$v.password.$touch()" class="form-control password-input" placeholder="Enter your new password" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <span @click.prevent="switchVisibilityPW" class="input-group-text" id="basic-addon2"><a href="#" class="eye"><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
              </div>
              <div v-if="$v.password.$dirty">
                <p class="text-danger" v-if="!$v.password.required">Please enter password.</p>
                <p class="text-danger" v-if="!$v.password.strongPassword">
                  Invalid password. Please use minimum 8 character in your password must include 1 special
                  character and 1 capital letter. 
                </p>
              </div>
            </div>  

            <div class="mb-5">
              <label for="exampleInputPassword1" class="form-label d-none">Password</label>
              <div class="input-group mb-3" id="show_hide_password2">
                <input :type="passwordFieldTypeConf" v-model.trim="confimPassword" @input="$v.confimPassword.$touch()" class="form-control password-input" placeholder="Confirm your password" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <span @click.prevent="switchVisibilityConPW" class="input-group-text" id="basic-addon3"><a href="#" class="eye"><i :class="[pwConIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
              </div>
              <div v-if="$v.confimPassword.$dirty">
                <p class="text-danger" v-if="!$v.confimPassword.sameAsPassword">Please enter confim password.</p>
              </div>
            </div>

            <div v-if="error" v-html="error">{{error}}</div>
            <div class="text-center mx-md-5 d-grid gap-2">
            <button @click.prevent="changeSubmit" type="submit" class="btn btn-primary text-center" data-bs-toggle="modal" data-bs-target="#staticForgot">
              <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Reset Password
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { Role } from '../../helper/role'
export default {
  data() {
    return {
    //  existUserId:  JSON.parse(localStorage.getItem("userLogData")).id,
    //  oldPassword: "",          
    //  oldPasswordFieldType: 'password',
    //  oldPwIcon: false,
      password: "",
      confimPassword: null,            
      passwordFieldType: 'password',
      pwIcon: false,
      passwordFieldTypeConf: 'password',
      pwConIcon: false,
      spinnerShow: false,
      error: null,
    };
  },

  validations: {
    // oldPassword:{
    //   required,
    // },
    password: {
      required,
      strongPassword(password) {
        return (
          /[A-Z]/.test(password) && 
          /[@$!%*#?&]/.test(password) && 
          password.length >= 8
        );
      }
    },
    confimPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('userLogData')
    localStorage.removeItem('userData')
    return	next()
  },
  mounted() {
    if(!JSON.parse(localStorage.getItem("userLogData")))
      this.$router.push({ name: "login" });
  },
  methods: {
    ...mapActions("auth", {
      actionCFirstTimeChangePassword: "firstTimeChangePassword",
    }),
    async changeSubmit(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        
        await vueInstance.actionCFirstTimeChangePassword({
          user_name: JSON.parse(localStorage.getItem("userLogData")).mobile,
        //  old_password: vueInstance.oldPassword,
          password: vueInstance.password,          
        }).then((results) => {
            
            if (results.error == false) {
              if(results.response.user.account.role == Role.SubAdmin){
                vueInstance.$router.push({ name: "adminDashboard" });
                return false
              }
              if(results.response.user.account.role == Role.User){
                vueInstance.$router.push({ name: "assessmentsInfo" });
                return false
              }
              vueInstance.$router.push({ name: "coachInquiryList" });
              return false
            }else{
              vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
         
            
        }).catch((err) => {
          
            vueInstance.error = err.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
        })
      }
    },
    // switchVisibilityOldPW() {
    //   this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password';
    //   this.oldPwIcon =!this.oldPwIcon;
    // },
    switchVisibilityPW() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.pwIcon =!this.pwIcon;
    },
    switchVisibilityConPW() {
      this.passwordFieldTypeConf = this.passwordFieldTypeConf === 'password' ? 'text' : 'password';
      this.pwConIcon =!this.pwConIcon;
    },
  }
}

</script>