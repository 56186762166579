<template>
<div class="section bg-green bg-green-white position-relative main">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-12 col-lg-8">
				<div class="white-box">
					<div class="text-center mb-5">
						<img :src="baseUrl+'/public/images/logo.svg'">
					</div>
					<h3 class="text-center text-green fw-medium mb-5 text28">Coach Sign Up</h3>
					<form id="myform" action="#">
						<div class="row">
							<div class="col-md-6">
								<div class="mb-4">
									<label for="fristName" class="form-label d-none">First Name</label>
									<input v-model.trim="firstName" @input="$v.firstName.$touch()" type="text" class="form-control user-text" id="fristName" aria-describedby="user-text" placeholder="Enter your first name">
                  <div v-if="$v.firstName.$dirty">
                    <p class="text-danger" v-if="!$v.firstName.required">Please enter your first name.</p>
                    <p class="text-danger" v-if="!$v.firstName.minLength || !$v.firstName.maxLength ">The first name must be between {{$v.firstName.$params.minLength.min}} and {{$v.firstName.$params.maxLength.max}} characters.</p>
                  </div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-4">
									<label for="lastName" class="form-label d-none">Last Name</label>
									<input v-model.trim="lastName" @input="$v.lastName.$touch()" type="text" class="form-control user-text" id="lastName" aria-describedby="user-text" placeholder="Enter your first name">
                  <div v-if="$v.lastName.$dirty">
                    <p class="text-danger" v-if="!$v.lastName.required">Please enter your last name.</p>
                    <p class="text-danger" v-if="!$v.lastName.minLength || !$v.lastName.maxLength ">The last name must be between {{$v.lastName.$params.minLength.min}} and {{$v.lastName.$params.maxLength.max}} characters.</p>
                  </div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-4">
									<label for="exampleInputEmail1" class="form-label d-none">Email address</label>
									<input v-model.trim="email" @input="$v.email.$touch()" type="email" class="form-control email email-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email">
                  <div v-if="$v.email.$dirty">
                    <p class="text-danger" v-if="!$v.email.required">Please enter your email address.</p>
                    <p class="text-danger" v-if="!$v.email.email">Please enter valid email address.</p>
                  </div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-4">
									<label for="Phone" class="form-label d-none">Phone</label>
									<input @keyup="acceptNumber" v-model.trim="phoneNo" @input="$v.phoneNo.$touch()" type="text" class="form-control phone-text" id="Phone" aria-describedby="user-text" placeholder="Enter your mobile number">
                   <div v-if="$v.phoneNo.$dirty">
                    <p class="text-danger" v-if="!$v.phoneNo.required">Please enter your phone no.</p>
                  </div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-4">
									<label for="exampleInputPassword1" class="form-label d-none">Password</label>
									<div class="input-group mb-3" id="show_hide_password">
										<input :type="passwordFieldType" v-model.trim="password" @input="$v.password.$touch()" class="form-control password-input" placeholder="Enter your password" aria-label="Recipient's username" aria-describedby="basic-addon2">
										<span @click.prevent="switchVisibilityPW" class="input-group-text" id="basic-addon2"><a href="#" class="eye"><i class="fas fa-eye-slash"></i></a> </span>
									</div>
                  <div v-if="$v.password.$dirty">
                    <p class="text-danger" v-if="!$v.password.required">Please enter password.</p>
                    <p class="text-danger" v-if="!$v.password.strongPassword">
                      Invalid password. Please use minimum 8 character in your password must include 1 special
                      character and 1 capital letter. 
                    </p>
                  </div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-4">
									<label for="exampleInputPassword1" class="form-label d-none">Password</label>
									<div class="input-group mb-3" id="show_hide_password2">
										<input :type="passwordFieldTypeConf" v-model.trim="confimPassword" @input="$v.confimPassword.$touch()" class="form-control password-input" placeholder="Confirm your password" aria-label="Recipient's username" aria-describedby="basic-addon2">
										<span @click.prevent="switchVisibilityConPW" class="input-group-text" id="basic-addon3"><a href="#" class="eye"><i class="fas fa-eye-slash"></i></a> </span>
									</div>
                  <div v-if="$v.confimPassword.$dirty">
                    <p class="text-danger" v-if="!$v.confimPassword.sameAsPassword">Please enter confim password.</p>
                  </div>
								</div>
							</div>
						</div>

						<div class="text-center mt-5">
							<div class="d-inline-block">
								<div class="mb-5 form-check">
									<input  v-model.trim="termCondition" type="checkbox" class="form-check-input" id="exampleCheck1">
									<label class="form-check-label text-black-50" for="exampleCheck1">Click here to agree with our
										<a href="#" class="text-green">Terms & Conditions </a> </label>
                  <p v-if="$v.termCondition.$dirty && !$v.termCondition.required" class="text-danger">
                    Please read our term & condition.
                  </p>  
								</div>
							</div>
						</div>
            <div v-if="error" v-html="error">{{error}}</div>
            <br>
            <div class="text-center ">
              <button  @click.prevent="signUpSubmit" type="submit" class="btn btn-primary text-center btn-rounded">
                <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Sign Up</button>
            </div>
						<p class="mt-5 text-center">Already have an account?<router-link :to="{ name: 'coachLogin' }" class="text-green fw-bold">LOG IN</router-link> </p>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      spinnerShow: false,
      error: null,
      firstName:"",
      lastName:"",
      phoneNo: "",
      email: "",
      password: "",
      termCondition: "",
      confimPassword: null,            
      passwordFieldType: 'password',
      pwIcon: false,
      passwordFieldTypeConf: 'password',
      pwConIcon: false,
    };
  },

  validations: {
    firstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16)
    },
    lastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16)
    },
    phoneNo: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      strongPassword(password) {
        return (
          /[A-Z]/.test(password) && 
          /[@$!%*#?&]/.test(password) && 
          password.length >= 8
        );
      }
    },
    confimPassword: {
      sameAsPassword: sameAs('password')
    },
    termCondition: {
      required,
    }
  },

  methods: {
    ...mapActions("auth", {
      actionCheckValidation: "signupValidation",
    }),
    async signUpSubmit(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var phoneNumber = vueInstance.phoneNo.replace(/[^\d]/g, "");
        var phoneNo = phoneNumber.substr(0, 3) + phoneNumber.substr(3, 3) + phoneNumber.substr(6);
        
        await vueInstance.actionCheckValidation({
          firstName: vueInstance.firstName,
          lastName: vueInstance.lastName,
          email: vueInstance.email,
          mobile: phoneNo,
          password: vueInstance.password,
        })
        .then((req ) => {

            if(req.error == true){
              vueInstance.error = req.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${req.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
            var signUpData = {
              first_name: vueInstance.firstName,
              last_name: vueInstance.lastName,
              email: vueInstance.email,
              mobile: phoneNo,
            }
            localStorage.setItem("userLogData", JSON.stringify(signUpData));
            
            const payloadData = {
                user_name: phoneNo,
                password: vueInstance.password,
            };
            localStorage.setItem("userData", JSON.stringify(payloadData));
            vueInstance.spinnerShow = false;
            vueInstance.$router.push({ name: "coachVerification" });
        }).catch((err) => {
            vueInstance.error = err.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
        })
      }
    },
    acceptNumber() {
      var x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNo = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    switchVisibilityPW() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.pwIcon =!this.pwIcon;
    },
    switchVisibilityConPW() {
      this.passwordFieldTypeConf = this.passwordFieldTypeConf === 'password' ? 'text' : 'password';
      this.pwConIcon =!this.pwConIcon;
    }
  }
}
</script>
<style scoped>
main .section .btn-primary{
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>