<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Launch Plan</div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">  
        <div class="block-content p-5 bg-white min-h">
          <div class="row">
            <div v-for="(assessmentLists,index) in assessmentListData" :key="index" class="col-lg-6 mb-5">
              <div class="text-center para18 fw-medium mb-4">{{assessmentLists.title}}</div>
              <div class="border rounded p-5 rounded-3">
                <div class="text-green text22 fw-medium mb-4">Information About {{assessmentLists.title}}</div>
                <p class="mb-5">{{assessmentLists.description | strLimit(100)}}</p>
                <div class="text-center para18 fw-medium mb-4">
                  <router-link :to="{ name: 'launchPlan', params: {assessmentId: assessmentLists.assessment_id } }" class="btn btn-primary text-center btn-rounded">Launch Plan</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
 
  data() {
    return {
      assessmentListData:{},
      notFoundError: false
    };
  },
  mounted () {
    this.getAssessmentList();
  },

  methods: {
    ...mapActions("assessment", {
      actionAssessmentLists: "UsercomplatedAssessmentList",
    }),
    async getAssessmentList() { 
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      await vueInstance.actionAssessmentLists().then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentListData = req.response
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
      
	  },
	}
}
</script>


<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
