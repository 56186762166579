import { render, staticRenderFns } from "./userAssessmentInfo.vue?vue&type=template&id=2396f238&scoped=true&"
import script from "./userAssessmentInfo.vue?vue&type=script&lang=js&"
export * from "./userAssessmentInfo.vue?vue&type=script&lang=js&"
import style0 from "./userAssessmentInfo.vue?vue&type=style&index=0&id=2396f238&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2396f238",
  null
  
)

export default component.exports