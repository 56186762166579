<template>
  <div>
    <component v-bind:is="currentComponent" v-if="forgotMobileNo.length>=10"/>
    <modalOTP :modalMsg="modalMsg" />
  </div>
</template>

<script>
import forgotVerificationCode from '../../../components/FrontEnd/Admin/forgotVerificationComponent'
import changePassword from '../../../components/FrontEnd/Admin/changePasswordComponent'
import evenBus from "../../../event-bus";
import modalOTP from '../modalOTP';

export default {
  components:{modalOTP},
  data() {
    return {
      forgotMobileNo:  JSON.parse(localStorage.getItem("enterData")) ? JSON.parse(localStorage.getItem("enterData")).mobile : '',
      currentComponent: forgotVerificationCode,
      modalMsg: "You will receive a one time passcode on your cellphone number registered with us",
    };
  },
  created() { 
    if(!this.forgotMobileNo)
      this.$router.push({ name: "adminForgotPassword" });

    evenBus.$on("changePassword", () => { 
      this.currentComponent = changePassword;
    });
  },

}

</script>
<style scoped>
main .section .btn-primary{
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>