<template>
  <div class="wrap">
    <div class="inner-small-h-banner">
      <div class="container">
        <h1 class="text-center text-white">Restricted Request</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center g-md-5">
          <div class="col-xxl-11">
            <!-- <div class="text-green text-uppercase py-3 border-bottom mb-5">Unauthorized </div> -->
            <div class="row justify-content-center g-md-5">
              <div class="col-md-12 text-center">
                <h4>You are already logged in as {{currentRoleLoggedIn}}.  Please log out and sign in with your valid login credentials to continue.</h4>
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script> 
import { Role } from '../../helper/role'
    export default {
      data() {
        return {
          currentRoleLoggedIn: '',
        };
      },
      // beforeCreate() {
      //   const authData = this.$store.getters['auth/getAuthData'];
      //   let currentRole = authData.loggedInRole;
      //   this.currentRoleLoggedIn = currentRole;
      //   if(currentRole == 'USER') this.currentRoleLoggedIn = 'Client';
      //     // if(currentRole == Role.User)
      //     //   this.$router.push({ name: "assessmentsInfo" });
          
      // },

      mounted() {
        const authData = this.$store.getters['auth/getAuthData'];
        let currentRole = authData.loggedInRole;
        if(currentRole == Role.User) this.currentRoleLoggedIn = 'Client';
        if(currentRole == Role.Coach) this.currentRoleLoggedIn = 'Coach';
        if(currentRole == Role.Admin) this.currentRoleLoggedIn = 'Admin';
        if(currentRole == Role.SubAdmin) this.currentRoleLoggedIn = 'Admin';
          // if(currentRole == Role.User)
          //   this.$router.push({ name: "assessmentsInfo" });
          
      },
    } 
</script>