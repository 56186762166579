<template>
  <div class="container mb-5">
    <div class="row">
      <div class="col-md-12">
        <div class="text22 fw-medium mb-5"> {{title.pageTitle}}</div>
      </div>
    </div>
    <component v-bind:is="currentComponent"/>
  </div>
</template>

<script>
import AddedSuccessComponent from '../../../components/BackEnd/Admin/addedSuccessComponent.vue';
import addUserComponent from '../../../components/BackEnd/Admin/addUserComponent.vue';
import evenBus from "../../../event-bus";	
export default {
  components: { addUserComponent, AddedSuccessComponent },
	data() {
		return {
			currentComponent: addUserComponent,
      userID: this.$route.params.userId 
		};
	},
  computed: {
    title(){ 
      if(this.$route.params.userId){  
        return {
          pageTitle: "Edit a new user",
        }
      }else{
        return {
          pageTitle: "Add a new user",
        }
      }
    }
  },
  created () {
    evenBus.$on("successfulAddedUser", (userIdData) => { 
      if(userIdData.userId){
        this.flashSuccess(`Users successfully updated.`, { timeout: 3000 });
        this.$router.push({ name: "adminUserList" });
        return
      }
      this.currentComponent = AddedSuccessComponent;
    });
    evenBus.$on("addNewUser", () => { 
      this.currentComponent = addUserComponent;
    });
  },
	
	
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
