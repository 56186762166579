<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">
              <router-link
                :to="{ name: 'adminSubAdminList' }"
                class="d-inline-block me-4 text-dark"
                ><i class="fas fa-arrow-left"></i
              ></router-link>
              Add a New Sub Admin User
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <flash-message></flash-message>
          <div class="col-lg-12">
            <div class="block-content p-5 bg-white min-h">
              <div class="mb-4">
                <label class="mb-2">First Name</label>
                <input
                  v-model.trim="firstName"
                  @input="$v.firstName.$touch()"
                  type="text"
                  class="form-control bg-grey border"
                  placeholder=""
                />
                <div v-if="$v.firstName.$dirty">
                  <p class="text-danger" v-if="!$v.firstName.required">
                    Please enter first name.
                  </p>
                  <p
                    class="text-danger"
                    v-if="!$v.firstName.minLength || !$v.firstName.maxLength"
                  >
                    The first name must be between
                    {{ $v.firstName.$params.minLength.min }} and
                    {{ $v.firstName.$params.maxLength.max }} characters.
                  </p>
                </div>
              </div>
              <div class="mb-4">
                <label class="mb-2">Last Name</label>
                <input
                  v-model.trim="lastName"
                  @input="$v.lastName.$touch()"
                  type="text"
                  class="form-control bg-grey border"
                  placeholder=""
                />
                <div v-if="$v.lastName.$dirty">
                  <p class="text-danger" v-if="!$v.lastName.required">
                    Please enter last name.
                  </p>
                  <p
                    class="text-danger"
                    v-if="!$v.lastName.minLength || !$v.lastName.maxLength"
                  >
                    The last name must be between
                    {{ $v.lastName.$params.minLength.min }} and
                    {{ $v.lastName.$params.maxLength.max }} characters.
                  </p>
                </div>
              </div>
              <div class="mb-4">
                <label class="mb-2">Email</label>
                <input
                  v-model.trim="email"
                  @input="$v.email.$touch()"
                  type="text"
                  class="form-control bg-grey border"
                  placeholder=""
                />
                <div v-if="$v.email.$dirty">
                  <p class="text-danger" v-if="!$v.email.required">
                    Please enter email address.
                  </p>
                  <p class="text-danger" v-if="!$v.email.email">
                    Please enter valid email address.
                  </p>
                </div>
              </div>
              <div class="mb-4">
                <label class="mb-2">Phone No</label>
                <input
                  @keyup="acceptNumber"
                  v-model.trim="phoneNo"
                  @input="$v.phoneNo.$touch()"
                  type="text"
                  class="form-control bg-grey border"
                  placeholder=""
                />
                <div v-if="$v.phoneNo.$dirty">
                  <p class="text-danger" v-if="!$v.phoneNo.required">
                    Please enter phone no.
                  </p>
                </div>
              </div>

              <div class="text-end mt-5">
                <a
                  href="#"
                  @click.prevent="addSubAdmin"
                  class="btn btn-primary text-center btn-rounded"
                  >Create Sub Admin</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { Role } from '../../../../helper/role'
export default {
  data() {
    return {
      spinnerShow: false,
      error: null,
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      role: "SUB_ADMIN",
    };
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16),
    },
    lastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16),
    },
    phoneNo: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  mounted () {
    if(this.$store.state.auth.authData.loggedInRole == Role.SubAdmin)
        this.$router.push({ name: "adminDashboard" });
  },
  methods: {
    ...mapActions("adminDashboard", {
      actionAddSubAdmin: "addSubAdmin",
    }),
    async addSubAdmin() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var phoneNumber = vueInstance.phoneNo.replace(/[^\d]/g, "");
        var phoneNo =
          phoneNumber.substr(0, 3) +
          phoneNumber.substr(3, 3) +
          phoneNumber.substr(6);

        await vueInstance
          .actionAddSubAdmin({
            first_name: vueInstance.firstName,
            last_name: vueInstance.lastName,
            email: vueInstance.email,
            mobile: phoneNo,
            role: vueInstance.role,
          })
          .then((results) => {
            $(".btn").prop("disabled", false);
            if (results.error == true) {
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false;
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.$router.push({ name: "adminSubAdminList" });
            return false;
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
        vueInstance.spinnerShow = false;
      }
    },
    acceptNumber() {
      var x = this.phoneNo
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNo = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>


<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
