<template>
  <div> 
    <section class="pt-5 section bg-grey">
      <div class="container">
        
        <div class="row">
          
          <div class="col-md-8">
            <!-- <div class="text-green fw-medium ms-5 ps-3">Retirement Plan</div> -->
            <div class="mb-5 text22 fw-medium"><router-link :to="{ name: 'adminAssessmentList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link> Add Modules</div>
          </div>
          <div class="mb-3 col-md-4 text-end">
            <a href="#" class="text-center btn btn-primary btn-rounded" @click.prevent="addModulePlan">Save</a>
          </div>

        </div>
        <div class="row justify-content-center">
          <flash-message></flash-message>
          <div class="col-lg-12"> 
            <div class="p-5 bg-white block-content min-h">
              <div v-for="(v, index) in $v.addModules.$each.$iter" :key="index" class="mb-5 d-flex field-list align-items-center">
                <div class="flex-grow-1">
                  <input v-model.trim="v.title.$model" type="text" class="mb-5 border form-control bg-grey" placeholder="Module Name">
                    <div v-if="$v.addModules.$dirty">
                      <p class="text-danger" v-if="!v.title.required">Module name is required.</p>
                      <!-- <p class="text-danger" v-if="!v.title.minLength || !v.title.maxLength">module must have at least {{ v.title.$params.minLength.min }} and {{ v.title.$params.maxLength.max }} letters.</p> -->
                    </div>
                    
                    <ckeditor :editor="editor" v-model.trim="v.description.$model" :config="editorConfig" type="text" class="mb-5 border form-control bg-grey" placeholder="Module Description" />
                    <div v-if="$v.addModules.$dirty">
                      <p class="text-danger" v-if="!v.description.required">Module description is required.</p>
                    </div>

                    <input v-model.trim="v.slogan.$model" type="text" class="mt-5 border form-control bg-grey" placeholder="Module Quote">
                    <div v-if="$v.addModules.$error">
                      <p class="text-danger" v-if="!v.slogan.required">Module quote is required.</p>
                    </div>
                      
                </div>
                <div class="flex-shrink-1 ms-4">
                  
                  <a href="#" v-if="addModules.length == ++index  && addModules.length < remainingAdd"  @click.prevent="addModules.push({title: '', description: '', slogan: ''})" class="addMore"><svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                      <g id="add" transform="translate(-3.375 -3.375)">
                        <path id="Path_2295" data-name="Path 2295" d="M25.523,19.477H19.477v6.047H16.523V19.477H10.477V16.523h6.047V10.477h2.953v6.047h6.047Z" fill="#8ac73c"/>
                        <path id="Path_2296" data-name="Path 2296" d="M18,6.328A11.667,11.667,0,1,1,9.745,9.745,11.623,11.623,0,0,1,18,6.328m0-2.953A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" fill="#8ac73c"/>
                      </g>
                    </svg>
                  </a>
                  <a href="#" v-else @click.prevent="addModules.splice(--index, 1)" class="remove"><i class="far fa-times-circle" aria-hidden="true"></i></a>
                </div>
              </div>
               
              <div class="form-group fieldGroupCopy" style="display: none;">
                <div class="flex-grow-1">
                  <input type="text" class="border form-control bg-grey" placeholder="Module Name">
                </div>
                <div class="flex-shrink-1 ms-4">
                  <a href="#" class="addMore"><svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                      <g id="add" transform="translate(-3.375 -3.375)">
                        <path id="Path_2295" data-name="Path 2295" d="M25.523,19.477H19.477v6.047H16.523V19.477H10.477V16.523h6.047V10.477h2.953v6.047h6.047Z" fill="#8ac73c"/>
                        <path id="Path_2296" data-name="Path 2296" d="M18,6.328A11.667,11.667,0,1,1,9.745,9.745,11.623,11.623,0,0,1,18,6.328m0-2.953A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" fill="#8ac73c"/>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>

              <div class="mt-5 text-end">
                <router-link :to="{ name: 'adminAssessmentList'}" class="text-center btn btn-primary btn-rounded">Cancel</router-link>
              </div>
              <p class="text-center fs-3 fw-bolder" v-if="addModules.length < 10">Please add a total of 10 modules to complete an assessment</p>
            </div>
          </div>
      </div>
    </div>
  </section>  
</div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
 
  data() {
    return {
      assessmentId: JSON.parse(localStorage.getItem("assesstListData")) ? JSON.parse(localStorage.getItem("assesstListData")).assessmentId: this.$router.push({ name: "adminAssessmentList" }),
      remainingAdd: JSON.parse(localStorage.getItem("assesstListData")) ? 10-JSON.parse(localStorage.getItem("assesstListData")).count: '',
      addModules: [
        {
          title: '',
          description: '',
          slogan: '',
        }
      ],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList' ],
      },
    };
  },
 
  validations: {
    addModules: {
       required,
      $each: {
        slogan: {
          required,
        },
        title: {
          required,
          // minLength: minLength(2),
          // maxLength: maxLength(50),
        },
        description: {
          required,
        },
        
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('assesstListData')
    return	next()
  },
  
  methods: {
    ...mapActions("assessment", {
      actionAddModule: "addModule",
    }),
    
    async addModulePlan(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.addModules.$each.$invalid) { 
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        if(vueInstance.addModules.length < vueInstance.remainingAdd){
          vueInstance.flashError(`Please add ${vueInstance.remainingAdd} modules`, { timeout: 10000 });
          return
        }
        
        await vueInstance.actionAddModule({
          moduleAdd: vueInstance.addModules,
          assessmentId: vueInstance.assessmentId,
        }).then((results) => {
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            if (results.error == true) { 
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.$router.push({ name: "adminAssessmentList" });
            return false;
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
   
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
