<template>
  <div class="col-md-6 col-lg-5 col-xl-4">
    <h3 class="text-green text-center mb-3">Phone Verification</h3>
    <p class="text-center mb-5 text-green">Enter your code here</p>

    <div class="row g-5 text-center mb-5">
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code1"
          @input="$v.code1.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
          autofocus
        />
      </div>
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code2"
          @input="$v.code2.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
        />
      </div>
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code3"
          @input="$v.code3.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
        />
      </div>
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code4"
          @input="$v.code4.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
        />
      </div>
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code5"
          @input="$v.code5.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
        />
      </div>
      <div class="col-2">
        <input
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          v-model.trim="code6"
          @input="$v.code6.$touch()"
          class="code-input form-control"
          type="text"
          maxlength="1"
        />
      </div>
    </div>

    <div class="timer mb-5 text-center py-5">
      {{ timerCountDown }}
    </div>
    <div v-if="error" v-html="error">{{ error }}</div>
    <div class="text-center mx-md-5 d-grid gap-2 mb-5">
      <button
        type="submit"
        @click.prevent="verifiedSubmit"
        :disabled="$v.$invalid"
        class="btn btn-primary text-center"
      >
        <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Verified
      </button>
    </div>
    <p class="para-md text-green fw-medium text-center">
      Didn’t receive a code?
    </p>
    <p class="mb-5 text-center">
      <a href="#" @click.prevent="sendOTP" class="text-dark">Resend Code</a>
    </p>
    <div id="recaptcha-container"></div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as firebase from "../../firebaseConfig";
// import { mapActions } from "vuex";
import evenBus from "../../event-bus";
export default {
  data() {
    return {
      forgotMobileNo: JSON.parse(localStorage.getItem("enterData"))
        ? JSON.parse(localStorage.getItem("enterData")).mobile
        : "",
      spinnerShow: false,
      code1: "",
      code2: null,
      code3: null,
      code4: null,
      code5: null,
      code6: null,
      error: null,
      timerCount: "300",
      timerCountDown: "",

      countryCode: firebase.countryCode,
      appVerifier: "",
      confirmResult: "",
    };
  },
  validations: {
    code1: {
      required,
    },
    code2: {
      required,
    },
    code3: {
      required,
    },
    code4: {
      required,
    },
    code5: {
      required,
    },
    code6: {
      required,
    },
  },
  mounted() {
    $("input").keyup(function () {
      var $this = $(this);
      setTimeout(function () {
        if ($this.val().length >= parseInt($this.attr("maxlength"), 10))
          $this.parent().next(".col-2").find("input").focus();
      }, 0);
    });
  },
  created() {
    if (this.forgotMobileNo) this.sendingOTP();
  },

  watch: {
    timerCount: {
      handler(seconds) {
        if (seconds >= 0) {
          setTimeout(() => {
            const format = (val) => `0${Math.floor(val)}`.slice(-2);
            const minutes = (seconds % 3600) / 60;
            this.timerCountDown = [minutes, seconds % 60].map(format).join(":");
            this.timerCount--;
          }, 2000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async sendingOTP() {
      let vueInstance = this;
      setTimeout(function () {
        window.recaptchaVerifier = new firebase.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
          },
          firebase.auth
        );
        vueInstance.appVerifier = window.recaptchaVerifier;

        vueInstance.sendOTP();
      }, 2000);
    },
    async sendOTP() {
        let vueInstance = this;
        vueInstance.error = null;
        vueInstance.code1 = null;
        vueInstance.code2 = null;
        vueInstance.code3 = null;
        vueInstance.code4 = null;
        vueInstance.code5 = null;
        vueInstance.code6 = null;
        setTimeout(function() { console.log('sendOTP');

            var phoneNumber = vueInstance.countryCode+vueInstance.forgotMobileNo;
            var appVerifier = vueInstance.appVerifier;

            firebase.signInWithPhoneNumber(firebase.auth, phoneNumber, appVerifier).then(confirmationResult => {
                vueInstance.confirmResult = confirmationResult;console.log('sent');
                $("#myModal").modal("show");
            }).catch(error => {
                console.log(error);
                vueInstance.error = `<p class='alert alert-danger'>Too many requests.Please try again later.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
                return false;
            });
        },1000);
    },

    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var code =
          vueInstance.code1 +
          vueInstance.code2 +
          vueInstance.code3 +
          vueInstance.code4 +
          vueInstance.code5 +
          vueInstance.code6;

        vueInstance.confirmResult.confirm(code).then((result)=>{
            evenBus.$emit("changePassword" );
            firebase.signOut(firebase.auth)
        }).catch((err)=>{
            vueInstance.error = `<p class='alert alert-danger'>Invalid verification code<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
        })
      }
    },
    /*
    ...mapActions("auth", {
      actionCheckOTP: "otpVerify",
    }),
    ...mapActions("auth", {
      actionSendOTP: "sendOTP",
    }),

    async sendOTP(){
      let vueInstance = this;
      vueInstance.error = null;

      vueInstance.code1 = null
      vueInstance.code2 = null
      vueInstance.code3 = null
      vueInstance.code4 = null

      await vueInstance.actionSendOTP({
          mobileNo: vueInstance.forgotMobileNo,
        }).then((results) => {
          if (results.error == true) {
            vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            return false;
          }else{
            $('#myModal').modal('show');
          }
        })
    },
    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var code = vueInstance.code1 + vueInstance.code2 + vueInstance.code3 + vueInstance.code4

        await vueInstance.actionCheckOTP({
          mobileNo: vueInstance.forgotMobileNo,
          code: code,
        }).then((results) => {
            if (results.error == false) {
              evenBus.$emit("changePassword" );
            }else{
              vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
    */
  },
};
</script>
