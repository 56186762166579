<template>
  <div class="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
		<div class="modal-dialog modal-sm modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header border-bottom-0">
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center">
					<h3 class="fw-medium text-center text-green mb-5 text28 pb-5">Forgot Password</h3>
					<div class="bg-dark mx-5 btn-rounded">
						<div class="mb-0 mt-n5">
							<img :src="baseUrl + '/public/images/rest-password.svg'">
						</div>

						<p class="text-green mb-5 pb-5 mt-5">{{modalMsg}}</p>
						<div class="text-center mb-5 text-center">
							<a href="#" data-bs-dismiss="modal" aria-label="Close" class=" text-center text-white">Done</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>

export default {
  props:['modalMsg'],
  data() {
    return {
      baseUrl: configObj.baseUrl,
    };
  },
};
</script>
