<template>
  	<div>
		<div class="banner-section d-flex w-100 align-items-center position-relative inner-banner banner-bg">
			<div class="w-100 h-100 d-flex align-items-center position-relative">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-xl-10 ">
							<div class="mb-5 back-page">
								<a href="#" @click.prevent="redirectBack"><img :src="baseUrl+'/public/images/arrow_left.svg'"> <span class="text-white backBtn">Back</span></a>
							</div>
							<h1 class="text-center text-white">{{getModuleData.assessment_info ? getModuleData.assessment_info.title: ''}}</h1>
						</div>
					</div>
				</div>
			</div>
		</div>

		<component v-bind:is="currentComponent" v-if="getModuleData.assessment_info" :getNotesData="getNotesData" :getModuleData="getModuleData" :getFeedbackData="getFeedbackData" :showNotesData="showNotesData"/>
	</div>
</template>

<script>
import { mapActions } from 'vuex'	
import evenBus from "../../../../event-bus";
import planModuleComponent from '../../../../components/BackEnd/User/planModuleComponent.vue';	
import moduleFeedbackComponent from '../../../../components/BackEnd/User/moduleFeedbackComponent.vue';	
import showNoteComponent from '../../../../components/BackEnd/User/showNoteComponent.vue';	
export default {
	
	data() {
		return {
			currentComponent: planModuleComponent,
			baseUrl: configObj.baseUrl,
			getNotesData:[],
			getModuleData:{},
			getFeedbackData:{},
			showNotesData: []
		};
	},

	created() { 
		this.planModuleData(this.$route.params.assessmentId,this.$route.params.moduleId);
		evenBus.$on("feedback", () => {
			this.currentComponent = moduleFeedbackComponent
		});
		evenBus.$on("addedNote", (id) => {
			let noteFilterData = this.getNotesData.filter(r => r.id == id); 
			this.showNotesData = noteFilterData[0]; 
			this.currentComponent = showNoteComponent
		});
	},
  	methods: {
		...mapActions("plan", {
			actionPlanModule: "planModule",
		}),

		async planModuleData(assessmentId,moduleId){
			let vueInstance = this;
			let options = {
				okText: 'Close',
				animation: 'bounce',
				type: 'basic',
				backdropClose: true, 
			};
			await vueInstance.actionPlanModule({assessmentId: assessmentId,moduleId: moduleId}).then((req) => {
				
				if(req.error == true){
					vueInstance.$dialog.alert(req.message,options)
					return false;
				}
				let notesDataResponse = req.response.notes;
				notesDataResponse.forEach((iteams,index) => {
					for (let i = 0; i < iteams.length; i++) {
						let element = {
							id: iteams[i].id,
							user_id: iteams[i].user_id,
							module_id: iteams[i].module_id,
							subject: iteams[i].subject,
							comment: iteams[i].comment,
							created_at: iteams[i].created_at,
							updated_at: iteams[i].updated_at,
						};

						vueInstance.getNotesData.push(element)
					}
				});				
				vueInstance.getFeedbackData = req.response.feedback;
				vueInstance.getModuleData = req.response;
				
			}).catch((err) => {
				vueInstance.$dialog.alert(err.message,options)
				return false;
			})
		},
		redirectBack(){
			let vueInstance = this;
			if(vueInstance.currentComponent.name == 'feedback' || vueInstance.currentComponent.name ==  'noteDetail'){
				vueInstance.currentComponent = planModuleComponent
				return false
			}
			vueInstance.$router.push({name: 'launchPlan',params: { assessmentId: this.$route.params.assessmentId }})	
		},
  	}
}

</script>

<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
.backBtn {
  font-size: 1.6rem;
}
</style>