<template>
  <div>
    <div class="section bg-grey wrap">
      <div class="container">
        <div class="row justify-content-center">
            <component v-bind:is="currentComponent" v-if="forgotMobileNo.length>=10"/>
        </div>
      </div>
    </div>
    <modalOTP :modalMsg="modalMsg" />
  </div>
</template>

<script>
import forgotVerificationCode from '../../components/FrontEnd/forgotVerificationComponent'
import changePassword from '../../components/FrontEnd/changePasswordComponent'
import evenBus from "../../event-bus";
import modalOTP from './modalOTP';

export default {
  components:{modalOTP},
  data() {
    return {
      forgotMobileNo:  JSON.parse(localStorage.getItem("enterData")) ? JSON.parse(localStorage.getItem("enterData")).mobile : '',
      currentComponent: forgotVerificationCode,
      modalMsg: "You will receive a one-time passcode on your cellphone number registered with us",
    };
  },
  created() {
    if(!this.forgotMobileNo)
      this.$router.push({ name: "forgotPassword" });

    evenBus.$on("changePassword", () => { 
      this.currentComponent = changePassword;
    });
  },

}

</script>