<template>
	<div class="col-xxl-10 scrollOn">
		 <div v-if="error" v-html="error">{{ error }}</div>
		 <div class="mb-5 d-flex bd-highlight">
			<div  :class="['p-2 flex-fill', (completedModules <=0 ? 'disabled' : 'cursor-pointer')]" v-on="(completedModules <= 0) ? '' : { click: prevPage}" ><img :src="baseUrl+'/public/images/arrow-round-back-left.svg'"></div>
			<div :class="['p-2 flex-fill text-end', (completedModules >= 9 ? 'disabled' : 'cursor-pointer')]"  v-on="(completedModules >= 9) ? '' : { click: nextPage}"><img :src="baseUrl+'/public/images/arrow-round-back.svg'"></div>
		</div>
		<div class="question-box" v-for="assessmentQuestion in getAssessmentQuestion" :key="assessmentQuestion.question_id"> 		
			<h3 class="mb-5 text-green fw-medium" >{{assessmentQuestion.question }}</h3>
			<div class="form-check" v-for="(questionOption, indexOptions) in assessmentQuestion.options" :key="indexOptions">
				<input @change.prevent="answerChecked(assessmentQuestion.question_id,questionOption)" :value="questionOption" :checked="questionOption == assessmentQuestion.selected_option" :name="'answer'+(assessmentQuestion.question_id)"   class="form-check-input" type="radio" :id="'flexRadioDefault6'+(questionOption)+(assessmentQuestion.question_id)">
				<!-- <input :value="questionOption" :checked="questionOption == assessmentQuestion.selected_option" :name="'answer'+(assessmentQuestion.question_id)"   class="form-check-input" type="radio" :id="'flexRadioDefault6'+(questionOption)+(assessmentQuestion.question_id)"> -->
				<label class="form-check-label" :for="'flexRadioDefault6'+(questionOption)+(assessmentQuestion.question_id)">
					{{ questionOption}} 
				</label>
			</div>
		</div>

		<div class="mb-5 text-center quote-box">
			<div class="mb-5">
				<img :src="baseUrl+'/public/images/quote.svg'">
			</div>
			<div class="text-white heading36">{{getSlogan}}</div>
		</div>
		<br>
		<div class="d-flex bd-highlight">
			<div  :class="['p-2 flex-fill', (completedModules <=0 ? 'disabled' : 'cursor-pointer')]" v-on="(completedModules <= 0) ? '' : { click: prevPage}" ><img :src="baseUrl+'/public/images/arrow-round-back-left.svg'"></div>
			<div :class="['p-2 flex-fill text-end', (completedModules >= 9 ? 'disabled' : 'cursor-pointer')]"  v-on="(completedModules >= 9) ? '' : { click: nextPage}"><img :src="baseUrl+'/public/images/arrow-round-back.svg'"></div>
		</div>
		<div class="mb-5 text-center mx-md-5" v-if="completedModules >= 9"><!--@click.prevent="completedSubmit"-->
          <button type="submit" @click.prevent="nextPage" class="text-center btn btn-primary">
            <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Submit
          </button>
        </div>
	</div>
</template>

<script>
import evenBus from "../../../event-bus";
import { mapActions } from 'vuex'	
export default {
	props:['assessmentQuestionData','totalCompletedModules'],
	data() {
		return {
			baseUrl: configObj.baseUrl,
			completedModules:this.totalCompletedModules,
			getAssessmentQuestion:[],   
			setAssessmentQuestion:[],   
			getSlogan: null,
			error: null,
			spinnerShow: null,
		};
	},
	created() {
		this.getQuestions(--this.completedModules)
	},
	methods: {
		...mapActions("assessment", {
			actionAssessmentAnswer: "AssessmentAnswerSubmit",
		}),
		async getQuestions(setCompletedModules){ 
			//if(setCompletedModules < 1)
			if(setCompletedModules <= 0){
				setCompletedModules = 0;
				this.completedModules = 0;
			}
			this.getAssessmentQuestion =  this.assessmentQuestionData[setCompletedModules].questions;
			this.getSlogan =  this.assessmentQuestionData[setCompletedModules].slogan;
			this.setAssessmentQuestion= [];
			this.getAssessmentQuestion.map(data => {
				this.setAssessmentQuestion.push({ question_id: data.question_id, selected_option: data.selected_option})
			})
		},
		async answerChecked(questionId, answerOption) { //alert("flexRadioDefault6"+answerOption+questionId);
			this.setAssessmentQuestion.map((data, i) => { 
				if(data.question_id == questionId){
					this.setAssessmentQuestion[i].selected_option = answerOption
				//	$("#flexRadioDefault6"+answerOption+questionId).attr('checked', true)
				}
			})
		},
		async nextPage(){
			
			let vueInstance = this;
			vueInstance.error = null;
			var setData={
				moduleId : vueInstance.getAssessmentQuestion[0].module_id,
				assessmentId : vueInstance.$route.params.assessmentId,
				anwser : vueInstance.setAssessmentQuestion,
			}
			await vueInstance.actionAssessmentAnswer(setData).then((results) => {
				$("html, body").animate({ scrollTop: $(".progress-bar-section").offset().top }, "slow");
				if(results.error == true){
					vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
					return false;
				}
				vueInstance.completedModules++;
			//	if(vueInstance.totalCompletedModules < vueInstance.completedModules)
					evenBus.$emit("updateTotalCompletedModules", vueInstance.completedModules);
				if(vueInstance.completedModules == 10 )	 return false;

				evenBus.$emit("refreshQuestionList");	
				vueInstance.getQuestions(vueInstance.completedModules);
				return true;
			}).catch((err) => {
				vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
				return false;
			})
		},
		prevPage(){
			this.completedModules--;
			this.getQuestions(this.completedModules)
			$("html, body").animate({ scrollTop: $(".progress-bar-section").offset().top }, "slow");
			return true;
		},
		// completedSubmit(){
		// 	this.nextPage()
		// //	evenBus.$emit("showCongratulationPage");
		// },
	},
}

</script>