<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Clients</div>
          </div>
          <div class="col-md-4 text-end mb-3">
            <router-link
              :to="{ name: 'adminUserAdd' }"
              class="btn btn-primary text-center btn-rounded"
              >Create A New Client User</router-link
            >
          </div>
        </div>

        <div class="block-content min-h p-4">
          <flash-message></flash-message>
          <div class="table-responsive border-table">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Phone #</th>
                  <th scope="col">Registered Date</th>
                  <th scope="col">Details</th>
                  <th scope="col" class="text-center" width="100">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(userListData, index) in usersListData.data"
                  :key="index"
                >
                  <td>{{ ++index }}</td>
                  <td>{{ userListData.first_name }}</td>
                  <td>{{ userListData.last_name }}</td>
                  <td>{{ userListData.email }}</td>
                  <td>{{ userListData.mobile | phoneNoFormate }}</td>
                  <td>{{ userListData.created_at | longDate }}</td>
                  <td>
                    <div
                      @click.prevent="assessmentPyment(userListData.id)"
                      class="btn btn-primary btn-white btn-sm align-items-center"
                    >
                      View
                    </div>
                  </td>
                  <!-- <td>
                    <router-link
                      :to="{
                        name: 'assessmentPaymentDetails',
                        params: { userId: userListData.id },
                      }"
                      class="btn btn-primary btn-white btn-sm"
                    >
                      View</router-link
                    >
                  </td> -->
                  <td class="text-end">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <router-link
                        :to="{
                          name: 'adminUserInfo',
                          params: { userId: userListData.id },
                        }"
                        class="edit-icon me-4"
                        ><i class="fas fa-pencil-alt"></i
                      ></router-link>
                      <div class="d-flex me-4">
                        <div class="form-check form-switch">
                          <input
                            :checked="userListData.status"
                            @change="changeUserStatus(userListData.id, $event)"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          ></label>
                        </div>
                      </div>
                      <!-- <div
                        @click.prevent="deleteUser(userListData.id, index)"
                        class="btn btn-danger"
                      >
                        Delete
                      </div> -->
                      <div
                        @click.prevent="assignCoachModal(userListData)"
                        class="btn btn-orange text-center btn-sm"
                      >
                        Coach
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="checkUserData">
                  No user data available
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center pagger mt-5">
          <pagination
            align="center"
            :limit="limit"
            size="large"
            :data="usersListData"
            @pagination-change-page="getUsersList"
          />
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text20 fw-medium" id="exampleModalLabel">
              {{ userFullName }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-4" v-if="checkAsseign">
              <div class="block-content p-5 bg-white min-h">
                <div class="text-center mb-4"></div>
                <div class="text-center">
                  {{ coachInfo.coach_first_name }}
                  {{ coachInfo.coach_last_name }}
                </div>
                <div class="text-center">{{ coachInfo.coach_email }}</div>
                <div class="text-center">{{ coachPhoneNo }}</div>
              </div>
            </div>
            <div class="mb-4" v-else>
              <select
                v-model.trim="selectCoach"
                class="browser-default custom-select form-control"
              >
                <option value="">Select Coach</option>
                <option
                  v-for="(coach, index) in coachData.data"
                  :key="index"
                  :value="coach.id"
                >
                  {{ coach.first_name }} {{ coach.last_name }}
                </option>
              </select>
              <div v-if="$v.selectCoach.$dirty">
                <p class="text-danger" v-if="!$v.selectCoach.required">
                  Please select coach.
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 mb-4">
            <button
              v-if="!checkAsseign"
              @click.prevent="assignCoach"
              type="button"
              class="btn btn-primary text-center btn-rounded"
            >
              <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="modal fade" id="exampleModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ assessmentUserName }} Assessment Status
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive border-table">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">Assessment</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Enrollment Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(getAssessmentData, indexJ) in allGetAssessmentData"
                    :key="indexJ"
                  >
                    <td>{{ getAssessmentData.title }}</td>
                    <td>{{ getAssessmentData.payment_method }}</td>
                    <td>{{ getAssessmentData.enrollment_date | longDate }}</td>
                  </tr>
                  <tr v-if="notAssessmentData">
                    <td colspan="5" class="text-center">
                      <b>No assessment data available</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer mt-4 mb-4"></div>
        </div>
      </div>
    </div>
    --->
  </div>
</template>
<script>
import axiosInstance from "../../../helper/axiosInstance";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
        return limit - 1;
      },
    },
  },
  validations: {
    selectCoach: {
      required,
    },
  },
  data() {
    return {
      usersListData: {
        type: Object,
        default: null,
      },
      checkUserData: false,
      // allGetAssessmentData: [],
      assessmentUserName: "",
      // notAssessmentData: false,
      coachData: {},
      coachInfo: {},
      selectCoach: "",
      selectedUserId: "",
      coachPhoneNo: null,
      userFullName: null,
      checkAsseign: false,
      spinnerShow: false,
      error: null,
    };
  },
  mounted() {
    this.getUsersList();
    this.getCoachData();
  },

  methods: {
    // ...mapActions("adminDashboard", {
    //   actionDeleteUser: "deleteUser",
    // }),
    ...mapActions("adminDashboard", {
      actionUpdateStatus: "updateStatus",
    }),
    ...mapActions("adminDashboard", {
      actionCoachUsers: "coachUsers",
    }),
    ...mapActions("adminDashboard", {
      actionAssignCoach: "assignCoach",
    }),
    // ...mapActions("adminDashboard", {
    //   actionAllGetAssessmentStatus: "allGetAssessmentStatus",
    // }),
    /*
    async deleteUser(userId, index) {
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      vueInstance.$dialog
        .confirm("Do you want to delete?")
        .then(function (dialog) {
          vueInstance
            .actionDeleteUser({ id: userId })
            .then((req) => {
              if (req.error == true) {
                vueInstance.$dialog.alert(req.message, options);
                return false;
              }
              vueInstance.getUsersList();
              //  vueInstance.usersListData.data.splice(index, 1);
            })
            .catch((err) => {
              vueInstance.$dialog.alert(err.message, options);
              return false;
            });
        });
    },
    */
    async getCoachData() {
      let vueInstance = this;
      await vueInstance
        .actionCoachUsers()
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }
          vueInstance.coachData = results.response;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
    async assignCoach() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();
      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        $("html, body").animate({ scrollTop: 0 }, "slow");
        await vueInstance
          .actionAssignCoach({
            id: vueInstance.selectedUserId,
            coachId: vueInstance.selectCoach,
          })
          .then((results) => {
            if (results.error == true) {
              vueInstance.flashError(results.message, { timeout: 4000 });
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
            vueInstance.getUsersList();
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 10000 });
          });
        vueInstance.spinnerShow = false;
        $(".btn").prop("disabled", false);
        $("#exampleModal").modal("hide");
      }
    },
    async changeUserStatus(userId, event) {
      $(".form-check-input").prop("disabled", true);
      var vueInstance = this;
      let value = event.target.checked == true ? 1 : 0;
      $(".flash__wrapper").empty();
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      const data = {
        id: userId,
        status: value,
      };

      await vueInstance
        .actionUpdateStatus(data)
        .then((req) => {
          $(".form-check-input").prop("disabled", false);
          if (req.error == true) {
            vueInstance.$dialog.alert(req.message, options);
            return false;
          }
          vueInstance.flashSuccess(req.message, { timeout: 3000 });
        })
        .catch((err) => {
          vueInstance.$dialog.alert(err.message, options);
          $(".form-check-input").prop("disabled", false);
          return false;
        });
    },
    async getUsersList(page = 1) {
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      axiosInstance
        .get(`admin/users?page=${page}`)
        .then((response) => {
          if (response.error == true) {
            vueInstance.$dialog.alert(response.message, options);
            return false;
          }

          if (response && Object.keys(response.data.response.data).length > 0)
            //vueInstance.usersListData = JSON.stringify(response.data.response.data)
            vueInstance.usersListData = response.data.response;
          else vueInstance.checkUserData = true;
        })
        .catch((error) => {
          vueInstance.flashError(error.message, { timeout: 5000 });
        });
    },
    assessmentPyment(userId) {
      this.$router.push({
        name: "assessmentPaymentDetails",
        params: { userId: userId },
      });
    },
    /*
    async getAssessment(userId, userName) {
      let vueInstance = this;
      vueInstance.allGetAssessmentData = [];
      vueInstance.assessmentUserName = "";
      vueInstance.notAssessmentData = false;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      await vueInstance
        .actionAllGetAssessmentStatus({ id: userId })
        .then((req) => {
          vueInstance.assessmentUserName = userName;
          if (req.error == true) {
            vueInstance.$dialog.alert(req.message, options);
            return false;
          }
          if (req.response.length < 1) {
            vueInstance.notAssessmentData = true;
            return false;
          }
          vueInstance.allGetAssessmentData = req.response;
        })
        .catch((err) => {
          vueInstance.$dialog.alert(err.message, options);
          return false;
        });
      $("#exampleModal").modal("show");
    },
    */
    async assignCoachModal(userData) {
      let vueInstance = this;
      vueInstance.checkAsseign = false;
      vueInstance.selectCoach = "";
      vueInstance.$v.$reset();
      vueInstance.selectedUserId = userData.id;
      vueInstance.userFullName = userData.first_name + " " + userData.last_name;
      const coachInfos = userData.coach_info;
      if (coachInfos != null) {
        vueInstance.coachInfo = coachInfos;
        var coachCleaned = ("" + vueInstance.coachInfo.coach_mobile).replace(
          /\D/g,
          ""
        );
        var matchCoach = coachCleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (matchCoach) {
          vueInstance.coachPhoneNo =
            "(" + matchCoach[1] + ") " + matchCoach[2] + "-" + matchCoach[3];
        }
        vueInstance.checkAsseign = true;
      }
      $("#exampleModal").modal("show");
    },
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: center;
}
.btn-orange {
  background: #8ac73c;
  border-color: #8ac73c;
  color: #fff;
  border-radius: 8px;
}
.form-control {
  border-color: #8ac73c;
}
</style>
