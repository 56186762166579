import axios from 'axios'
import store from '../store/index'

const baseUrl = $ ('head base').attr ('href')+'/api/';
//const baseUrl = 'http://work.splitreef.com/client/development/life_launch/api/';
const axiosInstance = axios.create({
    baseURL:baseUrl,

     headers: {
        post: {
            'Content-Type': 'application/json'
        }
     },
    responseType: "json"
});

axiosInstance.interceptors.request.use(config=> {
    NProgress.start()
    const authData = store.getters['auth/getAuthData'];
    if (authData.token) {
        config.headers["Authorization"] = `Bearer ${authData.token}`;
    }
   return config;
},error => {
        return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    document.body.classList.remove('loading-indicator');
    NProgress.done()
    return response;
},
async (error) => {

    if(error.response.status === 401) {

        const authData = store.getters['auth/getAuthData'];
        return axios.get(baseUrl+'refresh-token', {
            headers: {
              'Authorization':  `Bearer ${authData.token}`
            }
          }).then(res => {
            if (res.status === 200) {
                const getNewToken = res.data.response.token_info.access_token
                 const data = {
                    token : getNewToken
                  }
                  store.commit('auth/setAuthData' , data);
                error.config.headers["Authorization"] = `bearer ${getNewToken}`;

                return axios(error.config);
            } else {
                document.body.classList.remove('loading-indicator');
                NProgress.done()
                store.dispatch('auth/WhenError');

            }
        }).catch((error) => {
            document.body.classList.remove('loading-indicator');
            NProgress.done()
            store.dispatch('auth/WhenError');

            return false;
        });

   }else if(error.response.status === 500 || error.response.status === 404) {
        document.body.classList.remove('loading-indicator');
        NProgress.done()
        store.dispatch('auth/WhenError');
        return Promise.reject(error.response.data);
   }
    return Promise.reject(error.message);
});

export default axiosInstance;
