<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">Resources</div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
            <div class="mt-5 row justify-content-center">
              <ul class="mb-5 nav nav-pills justify-content-center tabs-links" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Articles</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Podcast</button>
                </li>

						</ul>
						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								
								<div class="table-responsive border-table">
									<table class="table mb-0">

										<thead>
											<tr>
												<th class="text-center" scope="col">Assessment Name</th>
												<th class="text-center" scope="col">Action</th>
											</tr>
										</thead> 
										<tbody>
											<tr v-for="(assessmentLists,indexKey) in assessmentListData" :key="indexKey">
												<td class="text-center">{{assessmentLists.title}}</td>

												<td class="text-end">
													<div class="d-flex align-items-center justify-content-center">
														<router-link :to="{ name: 'adminResourcesArticle', params: {assessmentId: assessmentLists.id }  }" class="btn btn-primary btn-white btn-lg">
                              {{assessmentLists.title}} Articles
                            </router-link>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
								<div class="h-auto p-5 bg-white block-content">
                  <div class="row">
                    <div v-for="(podcastData,index) in podcastListData.episodes" :key="index" class="mb-5 col-md-4">
                      <div class="blog-list-box">
                        <div class="img-box position-relative blog-img-box">
                          <router-link :to="{ name: 'adminPodcastEdit',params: {  id: podcastData.id } }" class="edit-icon ms-auto position-absolute"><i class="fas fa-eye"></i></router-link>
                          <img :src="podcastData.logo" class="img-fluid w-100" alt="Blog">
                        </div>
                        <div class="p-4">
                          <div class="d-flex justify-content-between">
                            <div class="mb-2 para-sm text-green">Life |  {{ podcastData.publish_time | longDateSec}}</div>
                            <div class="mb-2 para-sm text-green">{{podcastData.duration | convertSecToMin}}</div>
                          </div>
                          <h3 class="mb-4 text22 fw-medium"><a href="#" @click.prevent="" :inner-html.prop="podcastData.title | strLimit(20)"></a> </h3>
                          <p class="mb-2 para-md" :inner-html.prop="podcastData.content | strLimit(30)"> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 text-center pagger">
                  <pagination align="center" :limit=limit size="large" :data="podcastListData" @pagination-change-page="getPodcastList" />
                </div>
							</div>

						</div>
              <!-- <div v-for="(assessmentLists,index) in assessmentListData" :key="index" class="mb-5 col-lg-4">
                <div class="mb-5 text-center para18 fw-medium text-green" >{{assessmentLists.title}}</div>
                <div class="gap-4 d-grid">
                  <router-link :to="{ name: 'adminResourcesArticle', params: {assessmentId: assessmentLists.id }  }" class="btn btn-primary btn-white btn-lg">
                    {{assessmentLists.title}} Articles
                  </router-link>
                  <router-link :to="{ name: 'adminPodcastList' }" class="btn btn-green btn-lg">
                    {{assessmentLists.title}} Podcast
                  </router-link>
                </div>
              </div> -->
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</template>

<script>
import axiosInstance from '../../../../helper/axiosInstance'
import { mapActions } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      assessmentListData:{},
      podcastListData:{
        type:Object,
        default: null
      },
      checkUserData: false, 
    };
  },
  mounted () {
    this.getAssessmentList();
    this.getPodcastList();
  },

  methods: {
    ...mapActions("assessment", {
      actionAssessmentLists: "assessmentLists",
    }),
    async getAssessmentList() {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      await vueInstance.actionAssessmentLists().then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentListData = req.response
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
      
	  },

    async getPodcastList(page = 1) {
      let vueInstance = this;
      axiosInstance.get(`admin/podcasts?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.checkUserData= true
          return false;
        }
        
        if(response && Object.keys( response.data.response.episodes).length > 0)
          vueInstance.podcastListData = response.data.response
        else
          vueInstance.checkUserData= true
      }).catch(error=> { 
        vueInstance.checkUserData= true
      });
	  },
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.6rem;
  min-width: 70px;
  text-align: center;
}
</style>
