<template>
  <div>
    <div
      class="
        banner-section
        d-flex
        w-100
        align-items-center
        position-relative
        inner-banner
        justify-content-center
      "
    >
      <img
        :src="baseUrl + '/public/images/banner3.svg'"
        class="d-inline-block banner-img"
        alt="..."
      />
      <div
        class="position-absolute w-100 h-100 d-flex align-items-center top-0"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <h1 class="mb-5 text-white text-center">Payment Information</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section pt-0 payment-done">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="payment-success bg-white p-5 text-center">
              <img
                :src="baseUrl + '/public/images/done.svg'"
                class="img-fluid"
              />

              <div class="text20 fw-medium text-dark mb-5">
                Congratulations!<br />
                You can start your Assessment Now!
              </div>
              <div class="d-grid gap-2 col-md-9 mx-auto">
                <router-link :to="{ name: 'assessmentsInfo' }" class="text-center btn btn-primary br-radius">
                  Start Assessment
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
    };
  },

};
</script>
