<template>
  <div class="section bg-green bg-green-white position-relative main">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-6 col-xl-5">
          <div class="white-box">
            <div class="text-center mb-5">
              <img :src="baseUrl + '/public/images/logo.svg'">
            </div>
            <h3 class="text-center text-green fw-medium mb-5 text28">
              Verify Information
            </h3>
            <p class="text-center mb-5 text-green">Enter your code here</p>

              <div class="mb-4">
                <label for="exampleInputPassword1" class="form-label d-none"
                  >Enter OTP</label
                >
                <div class="input-group mb-3" id="show_hide_password2">
                  <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model.trim="code" @input="$v.code.$touch()"
                   :type="passwordFieldType"
                    class="form-control otp-input"
                    placeholder="Enter OTP"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    maxlength="6"
                  />
                  <span @click.prevent="switchVisibilityCode" class="input-group-text" id="basic-addon3"
                    ><a href="#" class="eye"><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a>
                  </span>
                </div>
                <div v-if="$v.code.$dirty">
                  <p class="text-danger" v-if="!$v.code.required"> Please enter code.</p>
                  <p class="text-danger" v-if="!$v.code.maxLength"> Code must have at most {{$v.code.$params.maxLength.max}} digits.</p>
                </div>
              </div>

              <br />
              <br />
              <div v-if="error" v-html="error">{{ error }}</div>
              <div class="text-center mb-5">
                <button  @click.prevent="verifiedSubmit" :disabled="$v.$invalid" type="submit" class="btn btn-primary text-center btn-rounded">
                  <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Verify
                </button>
              </div>
            <div id="recaptcha-container"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <h3 class="fw-medium text-center text-green mb-5 text28 pb-5">Forgot Password</h3>
            <div class="bg-dark mx-5 btn-rounded">
              <div class="mb-0 mt-n5">
                <img :src="baseUrl+'/public/images/rest-password.svg'">
              </div>

              <p class="text-green mb-5 pb-5 mt-5">Reset link sent to the registered email address</p>
              <div class="text-center mb-5 text-center">
                <a href="#" data-bs-dismiss="modal" aria-label="Close" class=" text-center text-white">Done</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from "../../../firebaseConfig";
import { required, maxLength } from "vuelidate/lib/validators";
// import { mapActions } from "vuex";
import evenBus from "../../../event-bus";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      forgotMobileNo: JSON.parse(localStorage.getItem("enterData"))
        ? JSON.parse(localStorage.getItem("enterData")).mobile
        : "",
      spinnerShow: false,
      code: "",
      passwordFieldType: 'password',
      pwIcon: false,
      error: null,
      countryCode: firebase.countryCode,
      appVerifier: "",
      confirmResult: "",
    };
  },
  validations: {
    code: {
      required,
      maxLength: maxLength(6)
    },
  },
  created() {
    if (this.forgotMobileNo) this.sendingOTP();
  },
  methods: {
    // ...mapActions("auth", {
    //   actionCheckOTP: "otpVerify",
    // }),
    // ...mapActions("auth", {
    //   actionSendOTP: "sendOTP",
    // }),
    async sendingOTP() {
      let vueInstance = this;
      setTimeout(function () {
        window.recaptchaVerifier = new firebase.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
          },
          firebase.auth
        );
        vueInstance.appVerifier = window.recaptchaVerifier;

        vueInstance.sendOTP();
      }, 1000);
    },
    async sendOTP() {
        let vueInstance = this;

        var phoneNumber = vueInstance.countryCode+vueInstance.forgotMobileNo;
        var appVerifier = vueInstance.appVerifier;

        firebase.signInWithPhoneNumber(firebase.auth, phoneNumber, appVerifier).then(confirmationResult => {
            vueInstance.confirmResult = confirmationResult;console.log('sent');
            $("#myModal").modal("show");
        }).catch(error => {
            console.log(error);
            vueInstance.error = `<p class='alert alert-danger'>Too many requests.Please try again later.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            return false;
        });
    },
    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        vueInstance.confirmResult.confirm(vueInstance.code).then((result)=>{
            evenBus.$emit("changePassword" );
            firebase.signOut(firebase.auth)
        }).catch((err)=>{
            vueInstance.error = `<p class='alert alert-danger'>Invalid verification code<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
        })
      }
    },


/*
    async sendOTP() {
      let vueInstance = this;
      vueInstance.error = null;

      await vueInstance
        .actionSendOTP({
          mobileNo: vueInstance.forgotMobileNo,
        })
        .then((results) => {
          if (results.error == true) {
            vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            return false;
          } else {
            $("#myModal").modal("show");
          }
        });
    },
    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance
          .actionCheckOTP({
            mobileNo: vueInstance.forgotMobileNo,
            code: vueInstance.code,
          })
          .then((results) => {
            if (results.error == false) {
              evenBus.$emit("changePassword");
            } else {
              vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
    */
    switchVisibilityCode() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.pwIcon =!this.pwIcon;
    },
  },
};
</script>

<style scoped>
main .section .btn-primary{
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
