<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">Details</div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
              <flash-message></flash-message>
              <div class="mt-5 row justify-content-center">
                <ul
                  class="mb-5 nav nav-pills justify-content-center tabs-links"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'assessmentPaymentDetails',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                      >Payment status</router-link
                    >
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'assessmentCompleteDetails',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Completed Assessments
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'uploadedExercise',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Uploaded Exercises
                    </router-link>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="h-auto p-5 bg-white block-content">
                      <div class="table-responsive border-table">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th scope="col">Date & Time</th>
                              <th scope="col">Article Title</th>
                              <th scope="col">Assessment Title</th>
                              <th scope="col">Download Exercise</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                getUploadedExerciseData, indexJ
                              ) in allGetUploadedExerciseData.data"
                              :key="indexJ"
                            >
                              <td>
                                {{
                                  getUploadedExerciseData.created_at | dateTime
                                }}
                              </td>
                              <td>{{ getUploadedExerciseData.title }}</td>
                              <td>
                                {{ getUploadedExerciseData.assessments_title }}
                              </td>
                              <td>
                                <a
                                  :href="getUploadedExerciseData.file"
                                  target="_blank"
                                >
                                  {{ getUploadedExerciseData.file_name }}
                                </a>
                              </td>
                              <td>
                                <button
                                  @click.prevent="
                                    deleteExercise(
                                      getUploadedExerciseData.id,
                                      indexJ
                                    )
                                  "
                                  class="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                            <tr v-if="notAssessmentData">
                              <td colspan="5" class="text-center">
                                <b>No data available</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-center pagger mt-5">
                      <pagination
                        align="center"
                        size="large"
                        :data="allGetUploadedExerciseData"
                        @pagination-change-page="getUploadedExercise"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from "../../../../helper/axiosInstance";
import { mapActions } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
        return limit - 1;
      },
    },
  },
  data() {
    return {
      allGetUploadedExerciseData: {
        type: Object,
        default: null,
      },
      notAssessmentData: false,
    };
  },
  mounted() {
    this.getUploadedExercise();
  },

  methods: {
    ...mapActions("adminDashboard", {
      actionUploadedExercise: "uploadedExercise",
    }),
    ...mapActions("adminDashboard", {
      actionDeleteExercise: "deleteExercise",
    }),
    async getUploadedExercise(page = 1) {
      let vueInstance = this;
      vueInstance.notAssessmentData = false;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      axiosInstance
        .get(
          `admin/resources-exercise/${vueInstance.$route.params.userId}?page=${page}`
        )
        .then((response) => {
          if (response.error == true) {
            vueInstance.$dialog.alert(response.message, options);
            return false;
          }

          if (response && Object.keys(response.data.response.data).length > 0)
            vueInstance.allGetUploadedExerciseData = response.data.response;
          else vueInstance.notAssessmentData = true;
        })
        .catch((error) => {
          vueInstance.flashError(error.message, { timeout: 5000 });
        });
    },
    async deleteExercise(id, index) {
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      vueInstance.$dialog
        .confirm("Do you want to delete?")
        .then(function (dialog) {
          vueInstance
            .actionDeleteExercise({ id: id })
            .then((req) => {
              if (req.error == true) {
                vueInstance.$dialog.alert(req.message, options);
                return false;
              }
              vueInstance.flashError(err.message, { timeout: 4000 });
              vueInstance.getUploadedExercise();
            })
            .catch((err) => {
              vueInstance.flashError(err.message, { timeout: 4000 });
              return false;
            });
        });
    },
  },
};
</script>
