<template>
  <div class="wrap">
    <div class="inner-small-h-banner">
      <div class="container">
        <h1 class="text-center text-white">{{title.pageTitle}}</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center g-md-5">
          <div class="col-xxl-11">
            <div class="text-green text-uppercase py-3 border-bottom mb-5">{{title.pageFormName}}</div>
            <div class="row justify-content-center g-md-5">
              <div class="col-md-11">
                <flash-message></flash-message>
                <div class="mb-3">
                  <label for="subject" class="form-label d-none">subject</label>
                  <input :maxlength="maxWordSubject" v-model.trim="subject" type="text" class="form-control bg-grey" id="subject" placeholder="Subject">
                  <div>subject you must be no longer than {{(maxWordSubject - subject.length)}} word</div>
                  <div v-if="$v.subject.$dirty">
                    <p class="text-danger" v-if="!$v.subject.required">
                      Please enter subject field.
                    </p>
                  </div>
                </div>
                <div class="mb-5" v-if="checkPlanList">
                  <label for="message" class="form-label d-none">Message</label>
                  <select v-model.trim="selectAssessment" class="browser-default custom-select form-control">
                    <option value="">Select Plan</option>
                    <option  v-for="(assessmentLists,index) in assessmentListData" :key="index" :value="assessmentLists.id" >{{assessmentLists.title}} </option>
                  </select>
                  <div v-if="$v.selectAssessment.$dirty">
                    <p class="text-danger" v-if="!$v.selectAssessment.required">
                      Please select plan.
                    </p>
                  </div>
                </div>
                <div class="mb-5">
                  <label for="message" class="form-label d-none">Message</label>
                  <textarea :maxlength="maxWord" v-model.trim="message" class="form-control bg-grey" id="message" rows="8" placeholder="Write your message here"></textarea>
                  <div>Message you must be no longer than {{(maxWord - message.length)}} word</div>
                  <div v-if="$v.message.$dirty">
                    <p class="text-danger" v-if="!$v.message.required">
                      Please enter message field.
                    </p>
                  </div>
                </div>
                <div class="d-grid gap-2 col-md-6 col-lg-4 mx-auto">
                  <button @click.prevent="formSubmit" type="submit" class="btn btn-primary text-center rounded-3">
                    <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Submit
                  </button>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import axiosInstance from '../../../helper/axiosInstance'
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      spinnerShow: false,
      error: null,
      subject: '',
      message: '',
      maxWord: 250,
      maxWordSubject: 32,
      isAskChatScreen:true,
      isSupportChatScreen:true,
      routerName: this.$route.name,
      selectAssessment: "", 
      assessmentListData:{},
      checkPlanList: false,
      userHaveAskCoachData: JSON.parse(localStorage.getItem("userHaveAskCoachData")),
      userHaveSupportRequestData: JSON.parse(localStorage.getItem("userHaveSupportRequestData")),
    };
  },
  computed: {
    title(){ 
      this.subject= '';
      this.message= '';
      if(this.routerName == 'askCoach'){  
       axiosInstance.get('assessments').then(response => {
        if(response)
          this.assessmentListData = response.data.response; 
        })
        this.checkPlanList = true
        if(this.isAskChatScreen && !this.$route.params.form && this.userHaveAskCoachData)
          this.$router.push({name:'chatList', params:{ 'chatScreen':this.routerName}})

        return {
          pageTitle: "Ask A Coach",
          pageFormName: "Contact Your Life Coach",
        }
        
      }else{
        this.subject= '';
        this.message= '';
        if(this.isSupportChatScreen && !this.$route.params.form && this.userHaveSupportRequestData)
          this.$router.push({name:'chatList', params:{ 'chatScreen':this.routerName}})
          
        return {
          pageTitle: "Support",
          pageFormName: "Inquiry Form",
        }
      }
    }
  },
 
  validations() {
    if (this.checkPlanList) {
      return {
          subject: {
          required,
        },
        message: {
          required,
        },
        selectAssessment: {
          required,
        },
      }
    } else {
      return {
        subject: {
            required,
          },
        message: {
          required,
        },
      }
    }
   
    },

  methods: {
    async formSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        
        const data = {
            subject: vueInstance.subject,
            message: vueInstance.message,
        };
        var postUrl = 'support-request';
        if(vueInstance.$route.name == 'askCoach'){
          vueInstance.$set(data, 'assessment_id', vueInstance.selectAssessment)
        //  vueInstance.$delete(data, 'assessment_id')
          postUrl = 'ask-coach'
        }
        axiosInstance.post(postUrl,data).then(results => {
          if (results.data.error == false) {
            vueInstance.flashSuccess(results.data.message, { timeout: 10000});
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            vueInstance.$router.push({name:'chatList', params:{ 'chatScreen':this.$route.name}})
            return false;
          }else{
            vueInstance.flashError(results.data.message, { timeout: 10000});
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          }
        }).catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000});
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
      }
    },
  }
}

</script>