<template>
  	<div>
		<div class="banner-section d-flex w-100 align-items-center position-relative inner-banner banner-bg">

			<div class="w-100 h-100 d-flex align-items-center position-relative">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-xl-10 ">
							<div class="mb-5 back-page">
								<router-link :to="{ name: 'planModule',params: { assessmentId: this.$route.params.assessmentId,moduleId:this.$route.params.moduleId  } }">
									<img :src="baseUrl+'/public/images/arrow_left.svg'"><span class="text-white backBtn">Back</span>
								</router-link>
							</div>
							<h1 class="text-center text-white">{{title}}</h1>

						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="pt-0 section bg-grey">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-xxl-10">
						<div class="meter-content">
							<div class="pb-3 mb-5 d-flex">
								<div class="flex-grow-1 para-md ps-3">Add Notes</div>
								<div class="text-end pe-3">
									<button type="submit" @click.prevent="formSubmit" class="btn btn-primary btn-sm">
										<i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Save
									</button>
								</div>
							</div><flash-message></flash-message>
							<div class="add-note-box">
								<input :maxlength="maxWordSubject" @input="$v.subject.$touch()" v-model.trim="subject" type="text" class="pb-4 mb-4 form-control fw-bold border-bottom"  placeholder="Title of the note">
								<div>Subject you must be no longer than {{(maxWordSubject - subject.length)}} word</div>
								<div v-if="$v.subject.$dirty">
									<p class="text-danger" v-if="!$v.subject.required">
									Please enter subject field.
									</p>
								</div>
								<textarea :maxlength="maxWord" v-model.trim="message" class="bg-transparent border-0 form-control" rows="10" placeholder="Enter the text"></textarea>
								<div>Message you must be no longer than {{(maxWord - message.length)}} word</div>
								<div v-if="$v.message.$dirty">
									<p class="text-danger" v-if="!$v.message.required">
									Please enter message field.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions } from 'vuex'	
import { required} from "vuelidate/lib/validators";	
export default {
	
	data() {
		return {
			baseUrl: configObj.baseUrl,
			spinnerShow: false,
			title: decodeURI(this.$route.params.title),
			error: null,
			subject: '',
			message: '',
			maxWord: 250,
			maxWordSubject: 60,
			
		};
	},
	validations: {
		subject: {
			required,
		},
		message: {
		required,
		},
	},

	methods: {
		...mapActions("plan", {
			actionNoteSubmit: "noteSubmit",
		}),

		async formSubmit() {
		let vueInstance = this;
		vueInstance.error = null;
		vueInstance.$v.$touch();

		if (!vueInstance.$v.$invalid) {
			$(".btn").prop("disabled", true);
			vueInstance.spinnerShow = true;
			var data={
				assessmentId: vueInstance.$route.params.assessmentId,
				moduleId: vueInstance.$route.params.moduleId,
				subject: vueInstance.subject,
				message: vueInstance.message
			}
			await vueInstance.actionNoteSubmit(data).then(results => {
			if (results.error == false) {
				vueInstance.flashSuccess(results.message, { timeout: 10000});
				vueInstance.spinnerShow = false;
				$(".btn").prop("disabled", false);
				return false;
			}else{
				vueInstance.flashError(results.message, { timeout: 10000});
				vueInstance.spinnerShow = false;
				$(".btn").prop("disabled", false);
				return false;
			}
			}).catch((err) => {
				vueInstance.flashError(err.message, { timeout: 10000});
				vueInstance.spinnerShow = false;
				$(".btn").prop("disabled", false);
				return false;
			});
		}
		},
	}

}

</script>
<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
.backBtn {
  font-size: 1.6rem;
}
</style>