<template>
   <div class="section bg-green bg-green-white position-relative main">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-6 col-xl-5">
          <div class="white-box">
            <div class="text-center mb-5">
              <img :src="baseUrl + '/public/images/logo.svg'" />
            </div>
            <h3 class="text-center text-green fw-medium mb-5 text28">Change Password</h3>
            <p class="para18 text-center mb-5">Enter your new password details below</p>
            <div v-if="error" v-html="error">{{ error }}</div>

            <div class="mb-4">
              <label for="exampleInputEmail1" class="form-label d-none"
                >>New Password</label
              >
              <div class="input-group mb-3" id="show_hide_password">
              <input :type="passwordFieldType" v-model.trim="password" @input="$v.password.$touch()" class="form-control password-input" placeholder="Enter your new password" aria-label="Recipient's username" aria-describedby="basic-addon2">
              <span @click.prevent="switchVisibilityPW" class="input-group-text" id="basic-addon2"><a href="#" class="eye"><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
            </div>
            <div v-if="$v.password.$dirty">
              <p class="text-danger" v-if="!$v.password.required">Please enter password.</p>
              <p class="text-danger" v-if="!$v.password.strongPassword">
                Invalid password. Please use minimum 8 character in your password must include 1 special
                character and 1 capital letter. 
              </p>
            </div>
            </div>
            <div class="mb-4">
              <label for="exampleInputPassword1" class="form-label d-none"
                >Password</label
              >
              <div class="input-group mb-3" id="show_hide_password2">
              <input :type="passwordFieldTypeConf" v-model.trim="confimPassword" @input="$v.confimPassword.$touch()" class="form-control password-input" placeholder="Confirm your password" aria-label="Recipient's username" aria-describedby="basic-addon2">
              <span @click.prevent="switchVisibilityConPW" class="input-group-text" id="basic-addon3"><a href="#" class="eye"><i :class="[pwConIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
            </div>
            <div v-if="$v.confimPassword.$dirty">
              <p class="text-danger" v-if="!$v.confimPassword.sameAsPassword">Please enter confim password.</p>
            </div>
            </div>

            <div class="text-center mb-5">
              <button
                @click.prevent="newPasswordSubmit"
                type="submit"
                class="btn btn-primary text-center btn-rounded"
              >
                <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required,  sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      forgotMobileNo:  JSON.parse(localStorage.getItem("enterData")).mobile,
      spinnerShow: false,
      error: null,
      password: "",
      confimPassword: null,
      passwordFieldType: 'password',
      pwIcon: false,
      passwordFieldTypeConf: 'password',
      pwConIcon: false,
    };
  },

  validations: {
    password: {
      required,
      strongPassword(password) {
        return (
          /[A-Z]/.test(password) && 
          /[@$!%*#?&]/.test(password) && 
          password.length >= 8
        );
      }
    },
    confimPassword: {
      sameAsPassword: sameAs('password')
    },
  },

  mounted() { 
    localStorage.removeItem("enterData")
  },

  methods: {
    ...mapActions("auth", {
      actionUpdatePssword: "updatePssword",
    }),

    async newPasswordSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance.actionUpdatePssword({
          userName: vueInstance.forgotMobileNo,
          password: vueInstance.password,
        }).then((results) => {
            if (results.error == false) {
               vueInstance.flashSuccess(results.message,{
                  timeout: 10000,
                });
              vueInstance.$router.push({ name: "coachLogin" });
              return false
            }else{

            vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
            }
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });

        return false;
      }
    },
    switchVisibilityPW() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.pwIcon =!this.pwIcon;
    },
    switchVisibilityConPW() {
      this.passwordFieldTypeConf = this.passwordFieldTypeConf === 'password' ? 'text' : 'password';
      this.pwConIcon =!this.pwConIcon;
    }
  },
};
</script>

<style scoped>
main .section .btn-primary{
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>