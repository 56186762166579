<template>
  <div class="wrap">
    <div class="inner-small-h-banner">
      <div class="container">
        <h1 class="text-center text-white">My Profile</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="row justify-content-center g-md-5">
          <flash-message></flash-message>
          <div class="col-xxl-11">
            <div class="row g-md-5">
              <div class="mb-5 col-md-6 mb-md-0">
                <div class="profile-box me-md-4">
                  <div class="row">
                  <div class="p-3 mb-2 text-green col-md-11 text-uppercase border-bottom">Log In Info</div>
                  <div class="p-3 mb-2 text-green col-md-1 border-bottom" role="button">
                    <i v-if="!editIcon" @click.prevent="editIcon = !editIcon" class="fa fa-pencil" aria-hidden="true"></i>
                    <i v-else  @click.prevent="editIcon = !editIcon" class="fa fa-times" aria-hidden="true"></i>
                  </div>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Phone Number</p>
                      <p v-if="!editIcon">{{ phoneNo | phoneNoFormate}}</p>
                      <div v-if="editIcon">
                        <p><input type="text" @keyup="acceptNumber" v-model.trim="phoneNo" @input="$v.phoneNo.$touch()" class="form-control phone-text" id="Phone" aria-describedby="user-text" placeholder="Enter your mobile number" /></p>
                        <div v-if="$v.phoneNo.$dirty">
                          <p class="text-danger" v-if="!$v.phoneNo.required">Please enter your phone no.</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Email Address</p>
                      <p v-if="!editIcon">{{ email}}</p>
                        <div v-if="editIcon">
                        <p><input v-model.trim="email" @input="$v.email.$touch()" type="email" class="form-control email email-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="johndoe@gmail.com" /></p>
                        <div v-if="$v.email.$dirty">
                          <p class="text-danger" v-if="!$v.email.required">Please enter your email address.</p>
                          <p class="text-danger" v-if="!$v.email.email">Please enter valid email address.</p>
                        </div>
                      </div>  
                    </li>
                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Password</p>
                      <p v-if="!editIcon">**********</p>
                      <div class="mb-3 input-group" v-if="editIcon">
                        <input :type="passwordFieldType" v-model.trim="password" @input="$v.password.$touch()" class="form-control password-input" placeholder="Enter your password" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <span @click.prevent="switchVisibilityPW" class="input-group-text" id="basic-addon2"><a href="#" class="eye"><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
                        
                        <div v-if="$v.password.$dirty">
                          <p class="text-danger" v-if="!$v.password.strongPassword">
                            Invalid password. Please use minimum 8 character in your password must include 1 special
                            character and 1 capital letter.  
                          </p>
                        </div>
                      </div>  
                    </li>

                  </ul>
                </div>  
                <div class="gap-2 mt-5 mb-5 text-center" v-if="editIcon">
                  <button
                    @click.prevent="updateSubmit"
                    type="submit"
                    class="text-center btn btn-primary"
                  >
                    <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Update login info
                  </button>
                </div>
              </div>
              <div class="mb-5 col-md-6 mb-md-0">
                <div class="profile-box ms-md-4">
                  <div class="p-3 mb-2 text-green text-uppercase border-bottom">Contact Info of Life Coach</div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Name</p>
                      <p>{{ coachInfoData.coach_first_name}} {{ coachInfoData.coach_last_name}}</p>
                    </li>

                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Email Address</p>
                      <p>{{ coachInfoData.coach_email}}</p>
                    </li>
                    <li class="list-group-item">
                      <p class="mb-0 para-md text-d-light">Phone Number</p>
                      <p>{{ coachInfoData.coach_mobile | phoneNoFormate}}</p>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      profileData:"",
      coachInfoData:{},
      spinnerShow: false,
      error: null,
      phoneNo: "",
      email: "",
      password: "",
      passwordFieldType: 'password',
      pwIcon: false,
      editIcon: false,
    };
  },

  validations: {
      phoneNo: {
        required
      },
      email: {
        required,
        email,
      },
      password: {
        strongPassword(password) {
          if(password){ 
          
          return (
              /[A-Z]/.test(password) && 
              /[@$!%*#?&]/.test(password) && 
              password.length >= 8
            );
          }
          return true;
        }
      }
  
  },
created() {
    this.getProfileData();
  },
  methods: {
      
    ...mapActions("auth", {
      actionProfile: "profile",
    }),

    async getProfileData(){
      let vueInstance = this;
      await vueInstance.actionProfile().then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        vueInstance.profileData = results.response.user;
        if(vueInstance.profileData.coach_info != null) 
        vueInstance.coachInfoData = vueInstance.profileData.coach_info;
        
        var cleaned = ('' + vueInstance.profileData.mobile).replace(/\D/g, '')
        var match= cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          vueInstance.phoneNo= '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        vueInstance.email = vueInstance.profileData.email;
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },

    async updateSubmit(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        var phoneNumber = vueInstance.phoneNo.replace(/[^\d]/g, "");
        var phoneNo = phoneNumber.substr(0, 3) + phoneNumber.substr(3, 3) + phoneNumber.substr(6);
        var profileVerifyData = {
          email: vueInstance.email,
          password: vueInstance.password,
          mobile: phoneNo,
        }
        if(vueInstance.profileData.mobile != phoneNo){
          let message = "A one-time code will be sent to your registered phone number. Please contact support if you don't have access to your phone number.";
          
          let options = { 
            okText: 'Continue',
            animation: 'bounce', 
            type: 'basic',
            backdropClose: true, 
          };
          
          vueInstance.$dialog.confirm(message, options).then(dialog => { 
            profileVerifyData.mobile = vueInstance.profileData.mobile
            profileVerifyData.newMobileNo = phoneNo
            localStorage.setItem("editMob", JSON.stringify(true));
            localStorage.setItem("updateProfileData", JSON.stringify(profileVerifyData));
            this.$router.push({ name: "profileMobileVerification" });
            dialog.close();
          })
        }else if((vueInstance.password) || vueInstance.profileData.email != vueInstance.email ){
          localStorage.setItem("editMob", false);
          localStorage.setItem("updateProfileData", JSON.stringify(profileVerifyData));
          vueInstance.$router.push({ name: "profileVerification" });
        }else{
          $(".btn").prop("disabled", false);
          vueInstance.spinnerShow = false;
        }
      }
    },
    switchVisibilityPW() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.pwIcon =!this.pwIcon;
    },
    acceptNumber() {
      var x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNo = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}

</script>