<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Clients</div>
          </div>
        </div>

        <div class="block-content min-h p-4">
          <flash-message></flash-message>
          <div class="table-responsive border-table">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Phone #</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(userListData, index) in usersListData.data"
                  :key="index"
                >
                  <td>{{ ++index }}</td>
                  <td>{{ userListData.first_name }}</td>
                  <td>{{ userListData.last_name }}</td>
                  <td>{{ userListData.email }}</td>
                  <td>{{ userListData.mobile | phoneNoFormate }}</td>
                  <td>
                    <div
                      @click.prevent="
                        assessmentCompleteDetails(userListData.id)
                      "
                      class="btn btn-primary btn-white btn-sm align-items-center"
                    >
                      View
                    </div>
                  </td>
                </tr>
                <tr colspan="6" v-if="checkUserData">
                  No user data available
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center pagger mt-5">
          <pagination
            align="center"
            :limit="limit"
            size="large"
            :data="usersListData"
            @pagination-change-page="getUsersList"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axiosInstance from "../../../helper/axiosInstance";
import { mapActions } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
        return limit - 1;
      },
    },
  },
  data() {
    return {
      usersListData: {
        type: Object,
        default: null,
      },
      checkUserData: false,
      assessmentUserName: "",
    };
  },
  mounted() {
    this.getUsersList();
  },

  methods: {
    async getUsersList(page = 1) {
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      axiosInstance
        .get(`coach/users?page=${page}`)
        .then((response) => {
          if (response.error == true) {
            vueInstance.$dialog.alert(response.message, options);
            return false;
          }

          if (response && Object.keys(response.data.response.data).length > 0)
            vueInstance.usersListData = response.data.response;
          else vueInstance.checkUserData = true;
        })
        .catch((error) => {
          vueInstance.flashError(error.message, { timeout: 5000 });
        });
    },
    assessmentCompleteDetails(userId) {
      this.$router.push({
        name: "coachCompleteDetails",
        params: { userId: userId },
      });
    },
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: center;
}
</style>
