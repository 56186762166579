<template>
<div>
	<section class="pt-0 section bg-grey">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xxl-10">
					<div class="meter-content">
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="heading1">
									<a @click.prevent="" :class="['accordion-button collapsed', (Math.round(getModuleData.score/4) < 3)?'border-left-secondary':(Math.round(getModuleData.score/4) == 5)?'':'border-green-light']" href="#">
										<div class="flex-grow-1 text-start">
											<div class="small-text d-block">Module</div>
											{{getModuleData.title}}
										</div>
										<div class="score-text me-3">Score <span>{{Math.round(getModuleData.score/4)}}</span></div>
									</a>
								</h2>
								<div class="accordion-body">
									<div class="my-5">
										<h3 class="heading28 text-green fw-medium">{{getModuleData.title}}</h3>
										<p class="mb-5" v-html="getModuleData.description"></p>
										<div class="py-4 note-box">
											<div class="pb-3 mb-5 d-flex border-bottom">
												<div class="flex-grow-1 para-md ps-3">Notes</div>
												<div class="text-end pe-3">
													<router-link :to="{ name: 'addModuleNote',params: { assessmentId: getModuleData.assessment_id,moduleId: getModuleData.id,title: encodeURI(getModuleData.assessment_info.title) } }" class="btn btn-primary btn-sm">
														<i class="fas fa-plus"></i> Add
													</router-link>
												</div>
											</div>
											<div class="p-3">
												<div class="d-flex inner-list-items" v-for="(notesData, index) in getNotesData" :key="index">
													<div class="cursor-pointer flex-grow-1" @click.prevent="showAddedNote(notesData.id)"> 
														<div class="para-sm fw-medium">{{notesData.comment}} </div>
													</div>
													<div class="cursor-pointer text-end" @click.prevent="showAddedNote(notesData.id)">
														<div class="text-small">{{notesData.created_at | noteDayFormate}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5 text-center">
						<button @click.prevent="feedbackForm" type="submit" class="btn btn-primary">
							Next Step <i class="fas fa-arrow-right"></i>
						</button>	
					</div>

				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
import evenBus from "../../../event-bus";	
export default {
	props:['getNotesData','getModuleData'],
	name:"planModule",
	data() {
		return {
			
		};
	},
	methods: {
		feedbackForm(){
			evenBus.$emit("feedback");
		},
		showAddedNote(id){
			evenBus.$emit("addedNote",id);
		}
	},
}

</script>
<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
</style>