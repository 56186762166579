import Vue from 'vue';
import VueRouter from 'vue-router';
import jwt_decode from "jwt-decode";
import 'nprogress/nprogress.css';
import { Role } from './helper/role'
import store from './store/index'

import home from './views/FrontEnd/home';
import unauthenticated from './views/FrontEnd/unauthenticated';
import NotFound from './views/FrontEnd/notFound';
import login from './views/FrontEnd/login';
import changePassword from './views/FrontEnd/changePassword';
import signUp from './views/FrontEnd/signUp';
import forgotPassword from './views/FrontEnd/forgotPassword';
import forgotPasswordVerification from './views/FrontEnd/forgotPasswordVerification';
import verification from './views/FrontEnd/verification';
import support from './views/BackEnd/user/supportAndCoach';
import askCoach from './views/BackEnd/user/supportAndCoach';
import myProfile from './views/BackEnd/user/myProfile';
import profileVerification from './views/BackEnd/user/profileVerification';
import profileMobileVerification from './views/BackEnd/user/profileMobileVerification';
import resources from './views/BackEnd/user/resourcesArticle';
import articleDetails from './views/BackEnd/user/articleDetail';
import uploadedExerciseList from './views/BackEnd/user/uploadedExerciseList';
import podcastDetails from './views/BackEnd/user/podcastDetail';
import assessmentsInfo from './views/BackEnd/user/assessments/assessmentInfo';
import cardsList from './views/BackEnd/user/payment/cardsList';
import addCards from './views/BackEnd/user/payment/addCard';
import paymentInfo from './views/BackEnd/user/payment/paymentInfo';
import paymentSuccess from './views/BackEnd/user/payment/paymentSuccess';
import assessmentQuestion from './views/BackEnd/user/assessments/assessmentQuestion';
import launchPlan from './views/BackEnd/user/plans/launchPlan';
import userPlanList from './views/BackEnd/user/plans/planList';
import planModule from './views/BackEnd/user/plans/planModule';
import addModuleNote from './views/BackEnd/user/plans/addModuleNote';
import notification from './views/BackEnd/user/notifyChat/notification';
import chatList from './views/BackEnd/user/notifyChat/chatList';
//const Gaming = () => import('@/components/gaming.vue');

/* Admin Routing  */
import adminLogin from './views/FrontEnd/admin/login';
import adminVerification from './views/FrontEnd/admin/verification';
import adminForgotPassword from './views/FrontEnd/admin/forgotPassword';
import adminForgotVerification from './views/FrontEnd/admin/forgotVerification';
import adminDashboard from './views/BackEnd/admin/dashboard';
import adminUserList from './views/BackEnd/admin/userList';
import adminUserAdd from './views/BackEnd/admin/addUsers';
import adminUserInfo from './views/BackEnd/admin/userInfo';
import adminInfo from './views/BackEnd/admin/adminInfo';
import adminUpdateProfile from './views/BackEnd/admin/updateProfile';
import adminAssessmentList from './views/BackEnd/admin/assessments/assessmentList';
import adminQuestion from './views/BackEnd/admin/assessments/questionList';
import adminQuestionAdd from './views/BackEnd/admin/assessments/addQuestion';
import adminModuleAdd from './views/BackEnd/admin/assessments/addModule';
import assessmentPaymentDetails from './views/BackEnd/admin/assessments/assessmentPaymentDetails';
import assessmentCompleteDetails from './views/BackEnd/admin/assessments/assessmentCompleteDetails';
import userAssessmentInfoList from './views/BackEnd/admin/assessments/userAssessmentInfo';
import planUserQuestionsAnswer from './views/BackEnd/admin/assessments/planQuestionsAnswer';
import uploadedExercise from './views/BackEnd/admin/assessments/uploadedExercise';
import adminPlanList from './views/BackEnd/admin/plans/planList';
import adminPlanAdd from './views/BackEnd/admin/plans/addPlan';
import adminPlanEdit from './views/BackEnd/admin/plans/editPlan';
import adminResourcesList from './views/BackEnd/admin/resources/resourcesList';
import adminResourcesArticle from './views/BackEnd/admin/resources/articleList';
import adminArticleAdd from './views/BackEnd/admin/resources/addArticle';
import adminArticleEdit from './views/BackEnd/admin/resources/editArticle';
import adminPodcastList from './views/BackEnd/admin/resources/podcastList';
import adminPodcastAdd from './views/BackEnd/admin/resources/addPodcast';
import adminPodcastEdit from './views/BackEnd/admin/resources/editPodcast';
import adminInquiryList from './views/BackEnd/admin/inquiry';
import adminInquiryReply from './views/BackEnd/admin/inquiryReply';
import adminInquiryView from './views/BackEnd/admin/inquiryView';
import admininviteCoachList from './views/BackEnd/admin/inviteCoach';
import adminCoachAdd from './views/BackEnd/admin/addCoach';
import adminCoachEdit from './views/BackEnd/admin/editCoach';
import adminCoachInfo from './views/BackEnd/admin/coachInfo';
import adminNotification from './views/BackEnd/admin/notification';
import adminPayment from './views/BackEnd/admin/paymentInfo';
import adminLaterCodeList from './views/BackEnd/admin/payLaterCodeList';
import adminSubAdminList from './views/BackEnd/admin/subAdmin/subAdminList';
import adminAddSubAdmin from './views/BackEnd/admin/subAdmin/addSubAdmin';
import adminSubAdminInfo from './views/BackEnd/admin/subAdmin/subAdminInfo';
import adminSubAdminEdit from './views/BackEnd/admin/subAdmin/editSubAdmin';
//import adminGenerateCode from './views/BackEnd/admin/generateCode';

/* Coach Routing  */
import coachLogin from './views/FrontEnd/coach/login';
import coachSignUp from './views/FrontEnd/coach/signUp';
import coachVerification from './views/FrontEnd/coach/verification';
import coachForgotPassword from './views/FrontEnd/coach/forgotPassword';
import coachForgotVerification from './views/FrontEnd/coach/forgotVerification';
import coachInquiryList from './views/BackEnd/coach/inquiryList';
import coachInquiryReply from './views/BackEnd/coach/inquiryReply';
import coachInquiryView from './views/BackEnd/coach/inquiryView';
import coachLaunchPlanList from './views/BackEnd/coach/launchPlanList';
import userAssessmentInfo from './views/BackEnd/coach/userAssessmentInfo';
import planQuestionsAnswer from './views/BackEnd/coach/planQuestionsAnswer';
import coachInfo from './views/BackEnd/coach/coachInfo';
import coachNotification from './views/BackEnd/coach/notification';
import coachUpdateProfile from './views/BackEnd/coach/updateProfile';
import coachResourcesList from './views/BackEnd/coach/resources/resourcesList';
import coachResourcesArticle from './views/BackEnd/coach/resources/articleList';
import coachPodcastEdit from './views/BackEnd/coach/resources/editPodcast';
import coachArticleEdit from './views/BackEnd/coach/resources/editArticle';
import coachUserList from './views/BackEnd/coach/userList';
import coachCompleteDetails from './views/BackEnd/coach/assessmentCompleteDetails';
import coachUploadedExercise from './views/BackEnd/coach/uploadedExercise';

import appPaymentInfo from './views/appPayment/paymentInfo';
import appPaymentSuccess from './views/appPayment/paymentSuccess';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);


let router = new VueRouter({
  mode: 'history',
  // base: '/client/development/life_launch/',
  base: '/',
  linkActiveClass: "active",
  routes: [
    {
      name: "home",
      path: '/',
      component: home,
      meta: {
        title: 'Life Launch Consulting',
        footerUrl: true
      },
    },
    {
      name: "login",
      path: '/client/login',
      component: login,
      meta: {
        title: 'Login',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "changePassword",
      path: '/change-password',
      component: changePassword,
      meta: {
        title: 'Change Password',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "signUp",
      path: '/sign-up',
      component: signUp,
      meta: {
        title: 'Sign Up',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "forgotPassword",
      path: '/forgot-password',
      component: forgotPassword,
      meta: {
        title: 'Forgot Password',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "forgotPasswordVerification",
      path: '/forgot-verification',
      component: forgotPasswordVerification,
      meta: {
        title: 'Forgot Password verification',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "verification",
      path: '/verification',
      component: verification,
      meta: {
        title: 'Verification Code',
        requiresUnauth: true,
        footerUrl: true
      },
    },
    {
      name: "support",
      path: '/client/support/:form?',
      component: support,
      meta: {
        title: 'Support',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "askCoach",
      path: '/client/ask-coach/:form?',
      component: askCoach,
      meta: {
        title: 'Ask Coach',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "myProfile",
      path: '/client/my-profile',
      component: myProfile,
      meta: {
        title: 'My Profile',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "profileVerification",
      path: '/client/profile-verification',
      component: profileVerification,
      meta: {
        title: 'Profile Verification',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "profileMobileVerification",
      path: '/client/mobile-verification',
      component: profileMobileVerification,
      meta: {
        title: 'Mobile Verification',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "resource",
      path: '/client/resource',
      component: resources,
      meta: {
        title: 'Resources',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "articleDetail",
      path: '/client/article-detail',
      component: articleDetails,
      meta: {
        title: 'Article Detail',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "uploadedExerciseList",
      path: '/client/upload-exercise-list',
      component: uploadedExerciseList,
      meta: {
        title: 'Uploaded Exercise List',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "podcastDetail",
      path: '/client/podcast-detail/:podCastId',
      component: podcastDetails,
      meta: {
        title: 'Podcast Detail',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "assessmentsInfo",
      path: '/client/assessment-info',
      component: assessmentsInfo,
      meta: {
        title: 'Assessment Info',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "cardsList",
      path: '/client/cards/:assessmentId/list',
      component: cardsList,
      meta: {
        title: 'Card List',
        requiresAuth:true,
        userAuth:true,
    },
    },
    {
        name: "addCards",
        path: '/client/add/:assessmentId/card',
        component: addCards,
        meta: {
          title: 'Add Card',
          requiresAuth:true,
          userAuth:true,
        },
    },
    {
        name: "paymentInfo",
        path: '/client/payment/:assessmentId/info/:customerPaymentProfileId?',
        component: paymentInfo,
        meta: {
          title: 'Payment Info',
          requiresAuth:true,
          userAuth:true,
        },
    },
    {
        name: "paymentSuccess",
        path: '/client/:assessmentId/payment-success',
        component: paymentSuccess,
        meta: {
          title: 'Payment Success',
          requiresAuth:true,
          userAuth:true,
        },
    },
    {
      name: "assessmentQuestion",
      path: '/client/assessment/:assessmentId/questions',
      component: assessmentQuestion,
      meta: {
        title: 'Assessment Question',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "launchPlan",
      path: '/client/launch-plan/:assessmentId',
      component: launchPlan,
      meta: {
        title: 'Launch Plan',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "userPlanList",
      path: '/client/plan/list',
      component: userPlanList,
      meta: {
        title: 'Launch Plan List',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "planModule",
      path: '/client/plan-module/:assessmentId/detail/:moduleId',
      component: planModule,
      meta: {
        title: 'Plan Module',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "addModuleNote",
      path: '/client/add/:assessmentId/note/:moduleId/:title',
      component: addModuleNote,
      meta: {
        title: 'Module Note',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "notification",
      path: '/client/notification',
      component: notification,
      meta: {
        title: 'Notifications',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "chatList",
      path: '/client/:chatScreen/chat-list',
      component: chatList,
      meta: {
        title: 'chat-list',
        requiresAuth:true,
        userAuth:true,
      },
    },
    {
      name: "unauthenticated",
      path: '/unauthenticated',
      component: unauthenticated,
      meta: {
        title: 'Access denied'
      },
    },
    {
      name: "adminLogin",
      path: '/admin/login',
      component: adminLogin,
      meta: {
        title: 'Sign In',
        permission: Role.Admin,
        requiresUnauth: true,
      },
    },
    {
      name: "adminVerification",
      path: '/admin/verification',
      component: adminVerification,
      meta: {
        title: 'Verification',
        permission: Role.Admin,
        requiresUnauth: true,
      },
    },
    {
      name: "adminForgotPassword",
      path: '/admin/forgot-password',
      component: adminForgotPassword,
      meta: {
        title: 'Forgot Password',
        permission: Role.Admin,
        requiresUnauth: true,
      },
    },
    {
      name: "adminForgotVerification",
      path: '/admin/forgot-verification',
      component: adminForgotVerification,
      meta: {
        title: 'Forgot Password',
        permission: Role.Admin,
        requiresUnauth: true,
      },
    },
    {
      name: "adminDashboard",
      path: '/admin/dashboard',
      component: adminDashboard,
      meta: {
        title: 'Dashoard',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminUserList",
      path: '/admin/user-list',
      component: adminUserList,
      meta: {
        title: 'User List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminUserAdd",
      path: '/admin/add-user',
      component: adminUserAdd,
      meta: {
        title: 'User Add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminUserInfo",
      path: '/admin/user-info/:userId',
      component: adminUserInfo,
      meta: {
        title: 'User Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminUpdateProfile",
      path: '/admin/edit-info',
      component: adminUpdateProfile,
      meta: {
        title: 'Update Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminInfo",
      path: '/admin/info',
      component: adminInfo,
      meta: {
        title: 'Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminUserEdit",
      path: '/admin/add-edit/:userId',
      component: adminUserAdd,
      meta: {
        title: 'User Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminAssessmentList",
      path: '/admin/assessment-list',
      component: adminAssessmentList,
      meta: {
        title: 'Assessment List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminQuestion",
      path: '/admin/assessment/:assessmentId/question-list/:moduleId',
      component: adminQuestion,
      meta: {
        title: 'Question List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminQuestionAdd",
      path: '/admin/assessment/:assessmentId/question-add/:moduleId',
      component: adminQuestionAdd,
      meta: {
        title: 'Question add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminModuleAdd",
      path: '/admin/assessment/module-add',
      component: adminModuleAdd,
      meta: {
        title: 'Module add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "assessmentPaymentDetails",
      path: '/admin/assessment/payment-detail/:userId',
      component: assessmentPaymentDetails,
      meta: {
        title: 'Payment Details',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "assessmentCompleteDetails",
      path: '/admin/assessment/complete-assessment/:userId',
      component: assessmentCompleteDetails,
      meta: {
        title: 'Complete Assessment',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "uploadedExercise",
      path: '/admin/assessment/uploaded-exercise/:userId',
      component: uploadedExercise,
      meta: {
        title: 'Uploaded Exercise',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "userAssessmentInfoList",
      path: '/admin/:userId/assessment/:assessmentId',
      component: userAssessmentInfoList,
      meta: {
        title: 'Assessemnt info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "planUserQuestionsAnswer",
      path: '/admin/:userId/question/:assessmentId',
      component: planUserQuestionsAnswer,
      meta: {
        title: 'Assessemnt info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPlanList",
      path: '/admin/plan/plan-list',
      component: adminPlanList,
      meta: {
        title: 'Plan list',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPlanAdd",
      path: '/admin/plan/plan-add',
      component: adminPlanAdd,
      meta: {
        title: 'Plan Add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPlanEdit",
      path: '/admin/plan/plan-edit/:assessmentId',
      component: adminPlanEdit,
      meta: {
        title: 'Plan Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminResourcesList",
      path: '/admin/resources/list',
      component: adminResourcesList,
      meta: {
        title: 'Resources',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminResourcesArticle",
      path: '/admin/resources/:assessmentId/article',
      component: adminResourcesArticle,
      meta: {
        title: 'Resources Articles',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminArticleAdd",
      path: '/admin/resources/:assessmentId/article-add',
      component: adminArticleAdd,
      meta: {
        title: 'Articles Add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminArticleEdit",
      path: '/admin/resources/:id/article-edit/:assessmentId',
      component: adminArticleEdit,
      meta: {
        title: 'Articles Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPodcastList",
      path: '/admin/resources/podcast',
      component: adminPodcastList,
      meta: {
        title: 'Poadcast List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPodcastAdd",
      path: '/admin/resources/podcast-add',
      component: adminPodcastAdd,
      meta: {
        title: 'Poadcast Add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPodcastEdit",
      path: '/admin/resources/:id/podcast',
      component: adminPodcastEdit,
      meta: {
        title: 'Poadcast Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    // {
    //   name: "adminInquiryList",
    //   path: '/admin/inquiry',
    //   component: adminInquiryList,
    //   meta: {
    //     title: 'Inquiry',
    //     permission: Role.Admin,
    //     requiresAuth: true,
    //     adminAuth: true,
    //   },
    // },
    {
      name: "adminInquiryReply",
      path: '/admin/:id/inquiry-reply/:groupId',
      component: adminInquiryReply,
      meta: {
        title: 'Inquiry Reply',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminInquiryView",
      path: '/admin/inquiry-view/:groupId',
      component: adminInquiryView,
      meta: {
        title: 'Inquiry Reply',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "admininviteCoachList",
      path: '/admin/coach-list',
      component: admininviteCoachList,
      meta: {
        title: 'Coach List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminCoachAdd",
      path: '/admin/coach-add',
      component: adminCoachAdd,
      meta: {
        title: 'Coach Add',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminCoachInfo",
      path: '/admin/coach-info/:id',
      component: adminCoachInfo,
      meta: {
        title: 'Coach Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminSubAdminInfo",
      path: '/admin/admin-info/:id',
      component: adminSubAdminInfo,
      meta: {
        title: 'SubAdmin Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminCoachEdit",
      path: '/admin/coach-edit/:id',
      component: adminCoachEdit,
      meta: {
        title: 'Coach Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminSubAdminEdit",
      path: '/admin/admin-edit/:id',
      component: adminSubAdminEdit,
      meta: {
        title: 'Sub Admin Update',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminNotification",
      path: '/admin/notification',
      component: adminNotification,
      meta: {
        title: 'Inquiry',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminInquiryList",
      path: '/admin/inquiry',
      component: adminInquiryList,
      meta: {
        title: 'Inquiry',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminPayment",
      path: '/admin/payment',
      component: adminPayment,
      meta: {
        title: 'Payment Info',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminLaterCodeList",
      path: '/admin/pay-code',
      component: adminLaterCodeList,
      meta: {
        title: 'Pay Via Code',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminSubAdminList",
      path: '/admin/admin-list',
      component: adminSubAdminList,
      meta: {
        title: 'Sub Admin List',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      name: "adminAddSubAdmin",
      path: '/admin/add-admin',
      component: adminAddSubAdmin,
      meta: {
        title: 'Add Admin',
        permission: Role.Admin,
        requiresAuth: true,
        adminAuth: true,
      },
    },
    // {
    //   name: "adminGenerateCode",
    //   path: '/admin/generate-code',
    //   component: adminGenerateCode,
    //   meta: {
    //     title: 'Pay Via Code',
    //     permission: Role.Admin,
    //     requiresAuth: true,
    //     adminAuth: true,
    //   },
    // },
    {
      name: "coachSignUp",
      path: '/coach/sign-up',
      component: coachSignUp,
      meta: {
        title: 'Sign Up',
        permission: Role.Coach,
        requiresUnauth: true,
      },
    },
    {
      name: "coachLogin",
      path: '/coach/login',
      component: coachLogin,
      meta: {
        title: 'Sign In',
        permission: Role.Coach,
        requiresUnauth: true,
      },
    },
    {
      name: "coachVerification",
      path: '/coach/verification',
      component: coachVerification,
      meta: {
        title: 'Verification',
        permission: Role.Coach,
        requiresUnauth: true,
      },
    },
    {
      name: "coachForgotPassword",
      path: '/coach/forgot-password',
      component: coachForgotPassword,
      meta: {
        title: 'Forgot Password',
        permission: Role.Coach,
        requiresUnauth: true,
      },
    },
    {
      name: "coachForgotVerification",
      path: '/coach/forgot-verification',
      component: coachForgotVerification,
      meta: {
        title: 'Forgot Password',
        permission: Role.Coach,
        requiresUnauth: true,
      },
    },
    {
      name: "coachInquiryList",
      path: '/coach/inquiry-list',
      component: coachInquiryList,
      meta: {
        title: 'Inquiry List',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachInquiryReply",
      path: '/coach/:id/inquiry-reply/:groupId',
      component: coachInquiryReply,
      meta: {
        title: 'Inquiry Reply',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachInquiryView",
      path: '/coach/inquiry-view/:groupId',
      component: coachInquiryView,
      meta: {
        title: 'Inquiry View',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachLaunchPlanList",
      path: '/coach/:id/plan-list/:title',
      component: coachLaunchPlanList,
      meta: {
        title: 'Plan List',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "userAssessmentInfo",
      path: '/coach/:userId/assessment/:assessmentId',
      component: userAssessmentInfo,
      meta: {
        title: 'Assessemnt info',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "planQuestionsAnswer",
      path: '/coach/:userId/question/:assessmentId',
      component: planQuestionsAnswer,
      meta: {
        title: 'Question Answer',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachInfo",
      path: '/coach/info',
      component: coachInfo,
      meta: {
        title: 'Info',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachUpdateProfile",
      path: '/coach/edit-info',
      component: coachUpdateProfile,
      meta: {
        title: 'Edit Info',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachNotification",
      path: '/coach/notification',
      component: coachNotification,
      meta: {
        title: 'Notification',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachResourcesList",
      path: '/coach/resources-list',
      component: coachResourcesList,
      meta: {
        title: 'Resources List',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
        name: "coachResourcesArticle",
        path: '/coach/resources/:assessmentId/article',
        component: coachResourcesArticle,
        meta: {
            title: 'Resources Articles',
            permission: Role.Coach,
            requiresAuth: true,
            coachAuth: true,
        },
    },
    {
        name: "coachArticleEdit",
        path: '/coach/resources/:id/article-edit/:assessmentId',
        component: coachArticleEdit,
        meta: {
          title: 'Articles Show',
          permission: Role.Coach,
          requiresAuth: true,
          coachAuth: true,
        },
    },
    {
        name: "coachPodcastEdit",
        path: '/coach/resources/:id/podcast',
        component: coachPodcastEdit,
        meta: {
            title: 'Poadcast Update',
            permission: Role.Coach,
            requiresAuth: true,
            coachAuth: true,
        },
    },
    {
        name: "coachUserList",
        path: '/coach/user-list',
        component: coachUserList,
        meta: {
            title: 'User List',
            permission: Role.Coach,
            requiresAuth: true,
            coachAuth: true,
        },
    },
    {
      name: "coachCompleteDetails",
      path: '/coach/complete-assessment/:userId',
      component: coachCompleteDetails,
      meta: {
        title: 'Complete Assessment',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
      name: "coachUploadedExercise",
      path: '/coach/uploaded-exercise/:userId',
      component: coachUploadedExercise,
      meta: {
        title: 'Uploaded Exercise',
        permission: Role.Coach,
        requiresAuth: true,
        coachAuth: true,
      },
    },
    {
        name: "appPaymentInfo",
        path: '/payment/info',
        component: appPaymentInfo,
        meta: {
            title: 'Payment Info',
        },
    },
    {
        name: "appPaymentSuccess",
        path: '/payment/:assessmentId/success',
        component: appPaymentSuccess,
        meta: {
            title: 'Payment Success',
        },
    },
    {
      name: "NotFound",
      path: '/:NotFound(.*)*',
      component: NotFound,
      meta: {
        title: 'Not Found'
      },
    },

  ]

});


router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userAuth = to.matched.some(record => record.meta.userAuth);
  const coachAuth = to.matched.some(record => record.meta.coachAuth);
  const adminAuth = to.matched.some(record => record.meta.adminAuth);
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth);

  var isAuthenticated = true;
  const authData = await store.getters['auth/getAuthData']
  let loginRole = authData.loggedInRole;
  if(!authData.token){
    const getLocalToken = localStorage.getItem('token');
    if(getLocalToken){
      const roleInToken = await jwt_decode(getLocalToken)
      const getFullName = roleInToken.first_name+ ' '+ roleInToken.last_name
      loginRole = roleInToken.role;
      const data = {
        token : getLocalToken,
        fullName : getFullName,
        role : loginRole,
      }
      if(loginRole == Role.User){
        const userHaveAskCoachData = localStorage.getItem('userHaveAskCoachData');
        const userHaveSupportRequestData = localStorage.getItem('userHaveSupportRequestData');
        data.userHaveAskCoachData = userHaveAskCoachData
        data.userHaveSupportRequestData = userHaveSupportRequestData
      }
      await store.commit('auth/setAuthData' , data);
    }else{
       isAuthenticated = false;
    }
  }

  if(isAuthenticated && requiresAuth) {
    if(userAuth){ 
        if(loginRole == Role.User) return next();
        else return next({name: 'unauthenticated' });
        // if(loginRole == Role.User) return next({name: 'assessmentsInfo' });
        // else if(loginRole == Role.Coach) return next({name: 'coachInquiryList' });
        // else if(loginRole == Role.Admin || loginRole == Role.SubAdmin) return next({name: 'adminDashboard' });
        // else return next({name: 'unauthenticated' });
    }else if(coachAuth){ 
        if(loginRole == Role.Coach) return next();
        else  return next({name: 'unauthenticated' });
        // if(loginRole == Role.User) return next({name: 'assessmentsInfo' });
        // else if(loginRole == Role.Coach) return next({name: 'coachInquiryList' });
        // else if(loginRole == Role.Admin || loginRole == Role.SubAdmin) return next({name: 'adminDashboard' });
        // else return next({name: 'unauthenticated' });
    }else if(adminAuth){
        if(loginRole == Role.Admin || loginRole == Role.SubAdmin) return next();
        else  return next({name: 'unauthenticated' });
        // if(loginRole == Role.User) return next({name: 'assessmentsInfo' });
        // else if(loginRole == Role.Coach) return next({name: 'coachInquiryList' });
        // else if(loginRole == Role.Admin || loginRole == Role.SubAdmin) return next({name: 'adminDashboard' });
        // else return next({name: 'unauthenticated' });
    }else{
      return next({name: 'unauthenticated' });
    }
  }else if (requiresUnauth &&  isAuthenticated){  
    // return next({name: 'unauthenticated' });
    if((loginRole == Role.User) && (to.name=='login')) return next({name: 'assessmentsInfo' });
    else if((loginRole == Role.Coach)  && (to.name=='coachLogin')) return next({name: 'coachInquiryList' });
    else if((loginRole == Role.Admin || loginRole == Role.SubAdmin)  && (to.name=='adminLogin')) return next({name: 'adminDashboard' });
    else return next({name: 'unauthenticated' }); 
  }else if(!isAuthenticated && requiresAuth){ 
    return next({name: 'login'});
  }else{
    return next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router;
