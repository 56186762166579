<template>
  	<div>
		<div class="banner-section d-flex w-100 align-items-center position-relative inner-banner banner-bg">

			<div class="w-100 h-100 d-flex align-items-end">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-xl-10">
							<div class="mb-5 back-page">
								<router-link :to="{ name: 'assessmentsInfo' }"><img :src="baseUrl+'/public/images/arrow_left.svg'"></router-link>
							</div>
							<h1 class="text-center text-white">{{title}}</h1>
							<div class="mb-5 progress-bar-section" v-if="showPrograssBar">
								
								<div class="progress">
									<div v-for="index in 10" :key="index" 
										:class="(index <= totalCompletedModules ? `progress-bar bg-${index}` : '')" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<section class="section bg-grey">
			<div class="container">
				<div class="row justify-content-center">
					<component v-bind:is="currentComponent" v-if="questionSet" :assessmentQuestionData="assessmentQuestionData" :totalCompletedModules="totalCompletedModules"/>
				</div>
			</div>
		</section>

	</div>
</template>

<script>
import questionComponent from '../../../../components/BackEnd/User/questionComponent.vue';
import questionCompletionComponent from '../../../../components/BackEnd/User/questionCompletionComponent.vue';
import { mapActions } from "vuex";
import evenBus from "../../../../event-bus";	
export default {
 	
  data() {
    return {
		currentComponent: questionComponent,
		baseUrl: configObj.baseUrl,
		assessmentQuestionData:{},
		totalCompletedModules:0,
		title:null,
		questionSet:false,    
		showPrograssBar:true,   
    };
  },
  
 created() {
	this.questionData(this.$route.params.assessmentId);
	evenBus.$on("refreshQuestionList", () => {
       this.questionData(this.$route.params.assessmentId,'NexttPage');
    });
	evenBus.$on("updateTotalCompletedModules", (updateCompletedModules) => {
       this.totalCompletedModules = updateCompletedModules
       if(updateCompletedModules == 10){
       
       this.currentComponent = questionCompletionComponent
         this.showPrograssBar = false
       }
    });
	// evenBus.$on("showCongratulationPage", () => {
    //   this.currentComponent = questionCompletionComponent
    //   this.showPrograssBar = false
    // });
	evenBus.$on("modifyQuestionAnswer", () => {
		this.showPrograssBar = true
	   	this.questionSet = false;
		this.questionData(this.$route.params.assessmentId,'showLastPage');
       	this.currentComponent = questionComponent
       
    });
 },
 methods: {
	...mapActions("assessment", {
      actionAssessmentQuestions: "assessmentQuestions",
    }),
	async questionData(assessmentId, lastPage= ""){
		let vueInstance = this;
		vueInstance.assessmentQuestionData={};
		vueInstance.totalCompletedModules={};
		let options = {
			okText: 'Close',
			animation: 'bounce',
			type: 'basic',
			backdropClose: true, 
		};
		await vueInstance.actionAssessmentQuestions({assessmentId: assessmentId}).then((req) => {
			
			if(req.error == true){
				//vueInstance.$dialog.alert(req.message,options)
				vueInstance.$router.push({ name: "assessmentsInfo"});
				return false;
			}
			if(req.response.total_completed_modules == req.response.modules.length && !lastPage && (req.response.assessment_info.started_at==null)){
				vueInstance.currentComponent = questionCompletionComponent
       			vueInstance.showPrograssBar = false
			}
			vueInstance.assessmentQuestionData = req.response.modules;
			vueInstance.totalCompletedModules = req.response.total_completed_modules;
			vueInstance.title = req.response.assessment_info.title;
			vueInstance.questionSet = true;
		}).catch((err) => {
			vueInstance.$dialog.alert(err.message,options)
			return false;
		})
		
	},
 },
}

</script>