<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Retirement Podcast</div>
          </div>
          <div class="col-md-4 text-end mb-5">
            <router-link :to="{ name: 'adminPodcastAdd' }" class="btn btn-primary text-center btn-rounded">Add New Podcast</router-link>
          </div>
        </div>
        <div class="row justify-content-center"> <flash-message></flash-message>
          <div class="col-lg-12">
        <div class="block-content p-5 bg-white h-auto">
          <div class="row">
            <div v-for="(podcastData,index) in podcastListData.episodes" :key="index" class="col-md-4 mb-5">
              <div class="blog-list-box">
                <div class="img-box position-relative blog-img-box">
                  <router-link :to="{ name: 'adminPodcastEdit',params: {  id: podcastData.id } }" class="edit-icon ms-auto position-absolute"><i class="fas fa-pencil-alt"></i></router-link>
                  <img :src="podcastData.logo" class="img-fluid w-100" alt="Blog">
                </div>
                <div class="p-4">
                  <div class="d-flex justify-content-between">
                    <div class="para-sm mb-2 text-green">Life |  {{ podcastData.publish_time | longDateSec}}</div>
                    <div class="para-sm mb-2 text-green">{{podcastData.duration | convertSecToMin}}</div>
                  </div>
                  <h3 class="text22 fw-medium mb-4"><a href="#" @click.prevent="" :inner-html.prop="podcastData.title | strLimit(100)"></a> </h3>
                  <p class="para-md mb-2" :inner-html.prop="podcastData.content | strLimit(200)"> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="text-center pagger mt-5">
            <pagination align="center" :limit=limit size="large" :data="podcastListData" @pagination-change-page="getPodcastList" />
          </div>
      </div>
      </div>
      </div>
      </section>
  </div>
</template>

<script>
import axiosInstance from '../../../../helper/axiosInstance'
import { mapActions } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      podcastListData:{
        type:Object,
        default: null
      },
      checkUserData: false,     
    };
  },
  mounted () {
    this.getPodcastList();
  },

  methods: {
    
    async getPodcastList(page = 1) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      axiosInstance.get(`admin/podcasts?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }
        
        if(response && Object.keys( response.data.response.episodes).length > 0)
          vueInstance.podcastListData = response.data.response
        else
          vueInstance.checkUserData= true
      }).catch(error=> { 
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>


<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
