<template>
  <div>
    <footer class="footer position-relative">
      <div class="container">
        <div class="row ft-bottom">
          <div class="col-md-3">
            <p class="mb-0 text-white">© 2023 Lifelaunch Consulting</p>
          </div>
          <div class="col-md-6 my-4 my-md-0">
            <ul
              v-if="
                $store.state.auth.authData.loggedInRole == roleCheck.User ||
                this.$route.meta.footerUrl
              "
              class="ft-links"
            >
              <li>
                <router-link :to="{ name: 'home' }">Home</router-link>
              </li>
              <li @click.prevent="checkLoginUser('assessmentsInfo')">
                <a href="">Assessment</a>
              </li>
              <li @click.prevent="checkLoginUser('userPlanList')">
                <a href="">Launch Plan</a>
              </li>
              <li @click.prevent="checkLoginUser('resource')">
                <a href="">Resources</a>
              </li>
              <li @click.prevent="checkLoginUser('askCoach')">
                <a href="">Ask A Coach</a>
              </li>
              <li @click.prevent="checkLoginUser('support')">
                <a href="">Support</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="ft-links">
              <li>
                <a
                  href="https://www.splitreef.com/custom-web-application-development.php"
                  target="_blank"
                >
                  Custom Web Application</a
                >
                By
                <a href="https://www.splitreef.com/" target="_blank"
                  >Split Reef</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Role } from "../../helper/role";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      roleCheck: Role,
    };
  },
  computed: {
    ...mapGetters("auth", {
      checkedAuthData: "getAuthData",
    }),
  },
  methods: {
    async checkLoginUser(redirectUrl = "") {
      if (
        this.$store.state.auth.authData.token &&
        this.$router.currentRoute.name != redirectUrl
      ) {
        this.$router.push({ name: redirectUrl });
        return false;
      }
      //    this.flashError('The Launch Plan identifies your area of opportunity and connects you with key resources so you can become launch ready', { timeout: 10000});
      if (this.$router.currentRoute.name != redirectUrl)
        this.$router.push({ name: "login" });
    },
  },
};
</script>
