<template>
  	<div>
		<div class="pb-0 inner-small-h-banner">
			<div class="container">
				<div class="row justify-content-center g-md-5">
					<div class="col-xxl-11">
						<div class="mb-5 row">
							<div class="col-md-3">
								<div class="back-page">
									<router-link :to="{ name: 'resource' }">
										<svg xmlns="http://www.w3.org/2000/svg" width="26.417" height="26.418" viewBox="0 0 26.417 26.418">
											<path id="arrow_left" d="M13.6,26.031a1.316,1.316,0,0,0,0-1.865L5.09,15.674a.659.659,0,0,1,.466-1.125H25.1a1.33,1.33,0,0,0,1.321-1.331v-.005a1.306,1.306,0,0,0-1.321-1.3H5.557a.66.66,0,0,1-.466-1.126l8.547-8.534a1.317,1.317,0,0,0,0-1.866h0a1.323,1.323,0,0,0-1.868,0l-11,10.979a2.634,2.634,0,0,0,0,3.729L11.729,26.031A1.321,1.321,0,0,0,13.6,26.031Z" fill="#fff"></path>
										</svg>
                                    </router-link>
								</div>
							</div>
							<div class="col-md-6">
								<h1 class="text-center text-white">Playing</h1>
							</div>
						</div>
						<div class="text-center">
							<div class="play-menu active">
								<img :src="baseUrl+'/public/images/playing.svg'">
							</div>
							<div class="play-menu">
								<img :src="baseUrl+'/public/images/next.svg'">
							</div>
							<div class="play-menu">
								<img :src="baseUrl+'/public/images/time.svg'">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section class="pt-0 section">
			
			<div class="container">
				<div class="row justify-content-center g-md-5">
					<div class="col-xxl-10">
						<img v-if="podCastDetailData.logo" :src="podCastDetailData.logo" class="mb-5 w-100" alt="audio">
						<div class="py-5 text-center player-box" v-html="medialUrl"></div>
						<div class="row justify-content-center"> <flash-message></flash-message>
							<div class="col-lg-10">
								<div class="d-flex">
									<div class="mb-2 text-green para-sm flex-grow-1">Life |  {{ podCastDetailData.publish_time | longDateSec }}</div>
									<div class="mb-2 text-green para-sm text-end">{{ podCastDetailData.publish_time | timeAgo }}</div>
								</div>
								<h3 class="mb-4 heading22 fw-medium" v-html="podCastDetailData.title"></h3>
								<p class="mb-5 para-md" v-html="podCastDetailData.content"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>	
export default {
	data() {
		return {
			baseUrl: configObj.baseUrl,
			podcastUrl: configObj.podcastUrl,
			podbeanToken:localStorage.getItem('podCastToken')?localStorage.getItem('podCastToken'):'',
			medialUrl: null,
			podCastDetailData:{},
			checkArticleData: false,     
		};
	},
	beforeRouteLeave(to, from, next) {
		localStorage.removeItem('podCastToken')
		return	next()
	},
	filters: {
		timeAgo: function (value) {
			if (!value) return ''
			let dateObj = new Date(value*1000);
			let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
			let day = ('0' + dateObj.getDate()).slice(-2);
			let year = dateObj.getFullYear();
			let dateString = month + "/" + day  + "/" + year;
	
			let now = new Date();
			let today = new Date(now.getYear(),now.getMonth(),now.getDate());
			let yearNow = now.getYear();
			let monthNow = now.getMonth();
			let dateNow = now.getDate();

			let dob = new Date(
				dateString.substring(6,10),
				dateString.substring(0,2)-1,                   
				dateString.substring(3,5)                  
			);

			let yearDob = dob.getYear();
			let monthDob = dob.getMonth();
			let dateDob = dob.getDate();
			let age = {};
			let ageString = "";
			let yearString = "";
			let monthString = "";
			let dayString = "";
			let monthAge="";
			let dateAge="";

			let yearAge = yearNow - yearDob;
			if (monthNow >= monthDob)
				monthAge = monthNow - monthDob;
			else {
				yearAge--;
				monthAge = 12 + monthNow -monthDob;
			}

			if (dateNow >= dateDob)
				dateAge = dateNow - dateDob;
			else {
				monthAge--;
				dateAge = 31 + dateNow - dateDob;

				if (monthAge < 0) {
				monthAge = 11;
				yearAge--;
				}
			}

			age = {
				years: yearAge,
				months: monthAge,
				days: dateAge
			};

			if ( age.years > 1 ) yearString = " years";
			else yearString = " year";
			if ( age.months> 1 ) monthString = " months";
			else monthString = " month";
			if ( age.days > 1 ) dayString = " days";
			else dayString = " day";

			if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
				ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString + " ago.";
			else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
				ageString = "Only " + age.days + dayString + " ago!";
			else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
				ageString = age.years + yearString + " ago. Happy Birthday!!";
			else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
				ageString = age.years + yearString + " and " + age.months + monthString + " ago.";
			else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
				ageString = age.months + monthString + " and " + age.days + dayString + " ago.";
			else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
				ageString = age.years + yearString + " and " + age.days + dayString + " ago.";
			else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
				ageString = age.months + monthString + " ago.";

			return ageString;
		}
	},

  	created() {
		this.podCastDetail();
	},
	methods:{
		async podCastDetail(){
			let vueInstance = this;
			let podCastId = this.$route.params.podCastId;
			if(!podCastId){
				vueInstance.flashError('Sorry! something went wrong', { timeout: 10000});
				return false
			}
			let data={
				podBeanId:  podCastId,
				token: vueInstance.podbeanToken,
			}
			await axios.post(vueInstance.baseUrl+'/api/podcastDetail', data).then((response) => {
				if (response != null) {
					if (response.status == 200) {
						vueInstance.podCastDetailData = response.data.episode;
						vueInstance.medialUrl = `<audio controls>
							<source src="${response.data.episode.media_url}" type="audio/mpeg">
							</audio>`
					}else
						vueInstance.flashError('Sorry! facing some problem', { timeout: 10000});
				}else
						vueInstance.flashError('Sorry! facing some problem', { timeout: 10000});
			})
			.catch((error) => {
				vueInstance.flashError(error, { timeout: 10000});
			})
		},
	}
}

</script>