<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">
              <router-link :to="{ name: 'coachInquiryList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link>Inquiries
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12"> 
            <div class="block-content p-5 bg-white min-h"> <flash-message></flash-message>
              <div class="mb-5">
                <label class="mb-2 fw-medium text-muted para18"><img :src="baseUrl+'/public/images/pic.svg'" class="user-pic me-3">{{name}}</label>
                <div class="bg-grey border p-4 rounded-4">
                  <p class="para-sm text-dark"><span class="fw-medium">Subject:</span> {{subject}}</p>
                  <p class="para-sm text-dark"><span class="fw-medium">Message:</span> {{message}}</p>
                </div>
              </div>
              <div class="mb-2">
                <label class="mb-2 fw-medium text-green para18"><img :src="baseUrl+'/public/images/coach-thumb.svg'" class="user-pic me-3">Coach</label>
              </div>
              <div class="mb-4">
                <input v-model.trim="subject" readonly type="text" class="form-control bg-grey border " placeholder="Subject: ">
              </div>
              <div class="mb-4">
                <textarea  v-model.trim="messageAns" @input="$v.messageAns.$touch()" class="form-control bg-grey border " rows="6"></textarea>
                <div v-if="$v.messageAns.$dirty">
                    <p class="text-danger" v-if="!$v.messageAns.required">Please enter your message.</p>
                    <p class="text-danger" v-if="!$v.messageAns.maxLength">
                      The message should not greater than {{$v.messageAns.$params.maxLength.max}} characters.
                    </p>
                  </div>
              </div>

              <div class="text-end mt-5">
                <a href="#" @click.prevent="addReplyInquiry" class="btn btn-primary text-center btn-rounded">Send</a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  
	data() {
		return {
      baseUrl: configObj.baseUrl,
			messageAns: null,
      name: null,
      subject: null,
      message:null
		};
	},
  validations: {
    messageAns: {
      required,
      maxLength: maxLength(255)
    },
  },
  mounted(){
    this.getInquiryData()
  },
  methods: {
    ...mapActions("assessment", {
      actionReplyInquiry: "coachReplyInquiry",
    }),
    ...mapActions("assessment", {
      actionSingalInquiry: "coachSingalInquiryData",
    }),
    async addReplyInquiry(){ 
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        $("html, body").animate({ scrollTop:0 }, "slow");
       
        await vueInstance.actionReplyInquiry({
          answer: vueInstance.messageAns,
          groupId: vueInstance.$route.params.groupId,
        }).then((results) => {
          if (results.error == true) { 
            vueInstance.flashError(results.message, { timeout: 4000 });
            return false
          }
          vueInstance.flashSuccess(results.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          vueInstance.$router.push({ name: "coachInquiryList" });
          return false;
          
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
      }
    },
    async getInquiryData(){
      let vueInstance = this;
      await vueInstance.actionSingalInquiry({id : vueInstance.$route.params.id}).then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        const data = results.response;
        vueInstance.name = data.user_first_name+ ' '+ data.user_last_name;
        vueInstance.subject = data.subject;
        vueInstance.message = data.message;
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },
  }
	
	
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
