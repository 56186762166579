<template>
  <div>
    <div class="inner-small-h-banner py-0 position-relative bg-transparent">
      <div class="bg-back"></div>
      <div class="container position-relative zi1">
        <img
          v-if="articleData.image"
          :src="articleData.image"
          class="img-fluid"
          alt="blog-detail"
        />
        <div class="card-img-overlay">
          <div class="row justify-content-center g-md-5 h-100">
            <div class="col-xxl-11">
              <div class="h-100 d-flex flex-column">
                <div class="back-page mt-5">
                  <router-link :to="{ name: 'resource' }">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.417"
                      height="26.418"
                      viewBox="0 0 26.417 26.418"
                    >
                      <path
                        id="arrow_left"
                        d="M13.6,26.031a1.316,1.316,0,0,0,0-1.865L5.09,15.674a.659.659,0,0,1,.466-1.125H25.1a1.33,1.33,0,0,0,1.321-1.331v-.005a1.306,1.306,0,0,0-1.321-1.3H5.557a.66.66,0,0,1-.466-1.126l8.547-8.534a1.317,1.317,0,0,0,0-1.866h0a1.323,1.323,0,0,0-1.868,0l-11,10.979a2.634,2.634,0,0,0,0,3.729L11.729,26.031A1.321,1.321,0,0,0,13.6,26.031Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="tag mt-auto">
                  <span class="d-inline-block py-1 px-3 text-white bg-dark"
                    >#Life</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <flash-message></flash-message>
        <div class="row justify-content-center g-md-5">
          <div class="col-xxl-11" id="element-to-convert">
            <div class="text-green para-sm mb-2">
              Life | {{ articleData.created_at | longDate }}
            </div>
            <div class="row">
              <div class="col-md-7">
                <h3 class="heading22 fw-medium mb-4">
                  {{ articleData.title }}
                </h3>
              </div>
              <div
                class="col-md-5 text-end mb-3"
                data-html2canvas-ignore="true"
              >
                <button
                  @click.prevent="downloadArticle"
                  class="btn btn-primary text-center btn-rounded btn-sm"
                >
                  Download
                </button>
                <button
                  type="button"
                  class="btn btn-primary text-center btn-rounded"
                  @click.prevent="uploadExercise"
                >
                  Upload Exercise
                </button>
              </div>
            </div>
            <p class="para-md mb-5">{{ articleData.description }}</p>
            <br />
            <br />
            <div
              data-html2canvas-ignore="true"
              class="grd-box text-center mb-5 d-flex align-items-center justify-content-center"
            >
              <div class="heading26 text-white">
                Retirement Goals And Future Planing
              </div>
            </div>
            <br />
            <div class="d-flex bd-highlight" data-html2canvas-ignore="true">
              <div
                :class="[
                  'p-2 flex-fill',
                  previousId == null ? 'disabled' : 'cursor-pointer',
                ]"
                @click.prevent="articleDetails(previousId.id)"
              >
                <img
                  :src="baseUrl + '/public/images/arrow-round-back-left.svg'"
                />
              </div>
              <div
                :class="[
                  'p-2 flex-fill text-end',
                  nextId == null ? 'disabled' : 'cursor-pointer',
                ]"
                @click.prevent="articleDetails(nextId.id)"
              >
                <img :src="baseUrl + '/public/images/arrow-round-back.svg'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text20 fw-medium" id="exampleModalLabel">
              Upload Exercise
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <!-- <label class="mb-2">Upload</label> -->
              <div class="input-group">
                <input
                  @change="onPDFChange"
                  type="file"
                  class="form-control bg-grey border"
                  id="inputGroupFile02"
                  :key="fileInputKey"
                />
              </div>
              <div v-if="$v.PDFUpload.$dirty">
                <p class="text-danger" v-if="!$v.PDFUpload.required">
                  Please upload exercise 
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 mb-4">
            <button
              @click.prevent="uploadPDFData"
              type="button"
              class="btn btn-primary text-center btn-rounded"
            >
              <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axiosInstance from "../../../helper/axiosInstance";
import html2pdf from "html2pdf.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      articleData: {},
      PDFUpload: "",
      spinnerShow: false,
      error: null,
      fileInputKey: 0,
      previousId: null,
      nextId: null,
      articleDetailsId: localStorage.getItem("detailsId")
        ? localStorage.getItem("detailsId")
        : "",
    };
  },
  validations: {
    PDFUpload: {
      required,
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("detailsId");
    return next();
  },
  created() {
    this.articleDetails(this.articleDetailsId);
  },
  methods: {
    async downloadArticle() {
      let vueInstance = this;
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 10,
        filename: vueInstance.articleData.title,
      });
    },
    async articleDetails(articleId = "") {
      let vueInstance = this;
      await axiosInstance
        .get(`article/${articleId}`)
        .then((response) => {
          localStorage.setItem("detailsId", articleId);
          if (response.data.error == false) {
            vueInstance.articleData = response.data.response.current;
            vueInstance.previousId = response.data.response.previous;
            vueInstance.nextId = response.data.response.next;
            $("html, body").animate(
              { scrollTop: $(".card-img-overlay").offset().top },
              "slow"
            );
          }
        })
        .catch((error) => {
          vueInstance.flashError(
            "Something went wrong. Please try to login in again",
            { timeout: 3000 }
          );
        });
    },
    async uploadPDFData() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        await vueInstance
          .actionUploadExercise({
            exercise_file: vueInstance.PDFUpload,
            article_id: vueInstance.articleDetailsId,
          })
          .then((results) => {
            $(".btn").prop("disabled", false);
            if (results.error == true) {
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false;
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.PDFUpload = "";
            vueInstance.fileInputKey++;
            $("#exampleModal").modal("hide");
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
        vueInstance.spinnerShow = false;
      }
    },

    onPDFChange(e) {
      var vm = this;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = function (event) {
        $(".btn").prop("disabled", true);
        const formData = new FormData();
        formData.append("document", e.target.files[0]);
        let options = {
          okText: "Close",
          animation: "bounce",
          type: "basic",
          backdropClose: true,
        };
        vm.actionUploadImage(formData).then((req) => {
          $(".btn").prop("disabled", false);
          if (req.error == true) {
            vm.$dialog.alert(req.message, options);
            return false;
          }
          vm.PDFUpload = req.response.file_name;
        });
      };
      reader.readAsDataURL(files[0]);
    },
    async uploadExercise() {
      this.$v.$reset();
      $("#exampleModal").modal("show");
    },
    ...mapActions("assessment", {
      actionUploadExercise: "uploadExercise",
    }),
    ...mapActions("assessment", {
      actionUploadImage: "uploadImage",
    }),
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
.btn-primary {
  color: #8ac73c;
  border-color: #8ac73c;
  background: #fff;
}
.btn-primary:hover {
  color: #fff;
  border-color: black;
  background: black;
}
</style>
