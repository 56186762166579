import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './modules/auth';
import assessmentModule from './modules/assessment';
import planModule from './modules/plan';
import notificationChatModule from './modules/notificationChat';
import adminDashboardModule from './modules/adminDashboard';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules:{
    auth:authModule,
    assessment:assessmentModule,
    plan:planModule,
    notificationChat:notificationChatModule,
    adminDashboard:adminDashboardModule,
  }
});

export default store;