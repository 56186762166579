<template>
	<div class="col-xxl-10">
		<div class="success-box">
			<div class="row">
				<div class="col-xl-8"></div>
				<div class="col-xl-4">
					<a href="#" class="mt-5" @click.prevent="modifyAnswer">Modify Questions</a>
				</div>
			</div>
			<div class="mb-5 text-center">
				<div class="svg-box d-inline-block position-relative">
					<div class="check">
						<img :src="baseUrl+'/public/images/check.svg'">
					</div>
					<div class="parcent-text">
						{{completedData.final_score}}%
					</div>
					<svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
						<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#8DC53E"></circle>
						<circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#fff" stroke-width="3"></circle>

						<circle class="donut-segment circle-chart__circle" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FDFEFF" stroke-width="3" stroke-dasharray="85 15" stroke-dashoffset="25"></circle>
					</svg>
				</div>
			</div>

			<div class="mb-5 text-center heading20 fw-medium text-dark">Congratulations on<br>
				successfully completing your<br>
				Readiness Assessment!
			</div>
			<div class="text-center">
				<router-link :to="{ name: 'launchPlan',params: { assessmentId: this.$route.params.assessmentId } }" class="btn btn-primary">Launch Your Retirement Plan</router-link>
			</div>

		</div>

	</div>
</template>

<script>
import evenBus from "../../../event-bus";
import { mapActions } from 'vuex'	
export default {
	props:['assessmentQuestionData','totalCompletedModules'],
	data() {
		return {
			baseUrl: configObj.baseUrl,
			completedData:{},
		};
	},
	created() {
		this.getCompletionData(this.$route.params.assessmentId)
	},
	methods: {
		...mapActions("assessment", {
			actionCompletion: "AssessmentCompleted",
		}),

		async getCompletionData(assessmentId){
			
			let vueInstance = this;
			let options = {
				okText: 'Close',
				animation: 'bounce',
				type: 'basic',
				backdropClose: true, 
			};
			await vueInstance.actionCompletion({assessmentId: assessmentId}).then((results) => {
				
				// if(results.error == true){
				// 	vueInstance.$dialog.alert(results.message,options)
				// 	return false;
				// }
				$("html, body").animate({ scrollTop: 0 }, "fast");
				vueInstance.completedData = results.response;
				return true;
			}).catch((err) => {
				vueInstance.$dialog.alert(err.message,options)
				return false;
			})
		},
		modifyAnswer(){
			evenBus.$emit("modifyQuestionAnswer");
		},
	
	},
}

</script>
<style scoped>
.parcent-text {
    font-size: 4.5rem;
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    text-align: center;
}
</style>