<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-5 text22 fw-medium">Assessment</div>
          </div>
        </div>
        <flash-message></flash-message>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="px-0 py-5 bg-white block-content min-h">
              <div class="d-flex align-items-start">
                <div class="col-lg-2">
              <ul
                class="mb-3 nav nav-pills justify-content-center tabs-links flex-column"
                id="pills-tab"
                role="tablist"
              >
                <li v-for="(assessmentLists,index) in assessmentListData.response" :key="index" class="nav-item" role="presentation">
                  <button
                    :class="(index == 0)? 'nav-link active':'nav-link'"
                    :id="`tab${assessmentLists.id}`"
                    data-bs-toggle="pill"
                    :data-bs-target="`#pills-home${assessmentLists.id}`"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    :aria-selected="(index == 0)? true: false"
                  >
                    {{assessmentLists.title}}
                  </button>
                </li>
              </ul>
              </div>
              <div class="col-lg-9">
              <div class="tab-content w-100" id="pills-tabContent">
                <div v-for="(assessmentLists,index) in assessmentListData.response" :key="index" 
                :class="(index == 0)?'tab-pane fade show active':'tab-pane fade'" :id="`pills-home${assessmentLists.id}`" :aria-labelledby="`tab${assessmentLists.id}`" role="tabpanel">
                  <div class="text-end">
                    <a href="#" v-if="assessmentLists.modules.length < 10" @click.prevent="redirectModuleAdd(assessmentLists.id,assessmentLists.modules.length)" class="btn btn-green">Add Module</a>
                  </div>
                  <div class="table-responsive border-table">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <!-- <th scope="col">Group</th> -->
                          <th scope="col">Module Name</th>
                          <th scope="col" width="200" class="text-center">
                            Number of Questions
                          </th>
                          <th scope="col" width="100">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(moduleData,indexModule) in assessmentLists.modules" :key="indexModule">
                          <!-- <td>{{moduleData.assessment_id}}</td> -->
                          <td :id="`moduleTitle${moduleData.assessment_id}${moduleData.id}`">{{moduleData.title}}</td>
                          <input type="hidden" :id="`moduleDesciption${moduleData.assessment_id}${moduleData.id}`" :value="moduleData.description">
                          <input type="hidden" :id="`moduleSlogan${moduleData.assessment_id}${moduleData.id}`" :value="moduleData.slogan">
                          <td class="text-center">{{moduleData.noOfQuestions}}</td>
                          <td class="text-end">
                            <div class="d-flex align-items-center justify-content-center">
                              <a  @click.prevent="editAssessmentModal(moduleData.assessment_id,moduleData.id)" href="#" class="edit-icon me-4"
                                ><i class="fas fa-pencil-alt"></i
                              ></a>
                              <a href="#" @click.prevent="deleteModule(moduleData.assessment_id,moduleData.id)" class="btn btn-danger me-4">Delete</a>
                              <router-link :to="{ name: 'adminQuestion',params: {  assessmentId: moduleData.assessment_id,moduleId: moduleData.id } }"
                                class="d-inline-block link-green para18">
                                <i class="fas fa-chevron-right"></i>
                              </router-link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p class="text-center fs-3 fw-bolder" v-if="assessmentLists.modules.length < 10">Please add a total of 10 modules to complete an assessment</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section> 

    <div class="modal fade" id="editAssessmentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg rounded-0">
        <div class="modal-content rounded-0">
          <div class="p-4 border-0 d-flex justify-content-end">
            <button @click.prevent="updateAssessment" type="button" class="btn btn-green ms-auto me-4">Save</button>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <input v-model.trim="editAssessment" @input="$v.editAssessment.$touch()"
                type="text"
                class="mb-5 border form-control bg-grey fw-medium"
                placeholder="I have a positive attitude towards my future."
              />
              <div v-if="$v.editAssessment.$dirty">
                <p class="text-danger" v-if="!$v.editAssessment.required">Please enter edit module.</p>
              </div>

              <ckeditor :editor="editor" v-model.trim="editDescription" :config="editorConfig" type="text" class="mb-5 border form-control bg-grey" placeholder="Module Description" />
              <div v-if="$v.editDescription.$dirty">
                <p class="text-danger" v-if="!$v.editDescription.required">module description is required.</p>
              </div>

              <input v-model.trim="$v.editSlogan.$model" type="text" class="mt-5 border form-control bg-grey" placeholder="Module Quote">
              <div v-if="$v.editSlogan.$dirty">
                <p class="text-danger" v-if="!$v.editSlogan.required">module quote is required.</p>
              </div>
            </div>
            <div class="mt-5 text-end">
              <!-- <a href="#" class="text-center btn btn-primary btn-rounded">Cancel</a> -->
              <button type="button" class="text-center btn btn-primary btn-rounded" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
 
  data() {
    return {
      assessmentListData:{},
      editAssessment: null,
      editDescription: null,
      editSlogan: null,
      assessmentId:null,
      moduleId: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList' ],
      },

    };
  },
  validations: {
    editAssessment: {
      required,
       minLength: minLength(2),
      maxLength: maxLength(50)
    },
    editDescription: {
      required,
    },
    editSlogan: {
      required,
    },
  },
  mounted () {
    this.getAssessmentList();
  },

  methods: {
    ...mapActions("assessment", {
      actionAssessmentList: "allAssessmentList",
    }),
    ...mapActions("assessment", {
      actionModuleDelete: "moduleDelete",
    }),
    ...mapActions("assessment", {
      actionModuleEdit: "moduleEdit",
    }),
    async editAssessmentModal(assessmentId,moduleId){
      let vueInstance = this;
      vueInstance.editAssessment = null
      vueInstance.editDescription = null
      vueInstance.editSlogan = null
      vueInstance.assessmentId = null
      vueInstance.moduleId = null
      vueInstance.editAssessment = $(`#moduleTitle${assessmentId}${moduleId}`).html()
      vueInstance.editDescription = $(`#moduleDesciption${assessmentId}${moduleId}`).val()
      vueInstance.editSlogan = $(`#moduleSlogan${assessmentId}${moduleId}`).val()
      $('#editAssessmentModal').modal('show')
      vueInstance.assessmentId = assessmentId
      vueInstance.moduleId = moduleId
    },
    async updateAssessment(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance.actionModuleEdit({
          titleUpdate: vueInstance.editAssessment,
          editDescription: vueInstance.editDescription,
          editSlogan: vueInstance.editSlogan,
          assessmentId: vueInstance.assessmentId,
          moduleId: vueInstance.moduleId,
        }).then((results) => {
            $("html, body").animate({ scrollTop:0 }, "slow");
            if (results.error == true) { 
              vueInstance.flashError(results.message, { timeout: 4000 });
              $('#editAssessmentModal').modal('hide')
              return false
            }
            vueInstance.getAssessmentList()
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            $(`#moduleDesciption${vueInstance.assessmentId}${vueInstance.moduleId}`).val(vueInstance.editDescription)
            $(`#moduleSlogan${vueInstance.assessmentId}${vueInstance.moduleId}`).val(vueInstance.editSlogan)
            $('#editAssessmentModal').modal('hide')
            return false;
            
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            $('#editAssessmentModal').modal('hide')
            return false;
          });
      }
    },
    redirectModuleAdd(assessmentId, addedCountModule){
      const localStorageData = {
          assessmentId: assessmentId,
          count:addedCountModule,
      };
      localStorage.setItem("assesstListData", JSON.stringify(localStorageData));
      this.$router.push({ name: "adminModuleAdd" });
    },
    async deleteModule(assessmentId,moduleId) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      vueInstance.$dialog.confirm('Do you want to delete?').then(function(dialog) {
      let data ={
        assessmentId: assessmentId,
        moduleId: moduleId,
      }
        vueInstance.actionModuleDelete(data).then((req) => {
			
          if(req.error == true){
            vueInstance.flashError(req.message, { timeout: 4000 });
            return false;
          }
          vueInstance.flashSuccess(req.message, { timeout: 4000 });
          vueInstance.getAssessmentList()
        }).catch((err) => {
          vueInstance.$dialog.alert(err.message,options)
          return false;
        })
      })  
	  },
    async getAssessmentList() {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      await vueInstance.actionAssessmentList().then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentListData = req
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
      
	  },
	}
}
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
.tabs-links li .nav-link{
  padding: 10px 0;
  font-size: 1.6rem;
  border-bottom: 1px solid #B1BABC;
  text-align: left;
  width: 100%;
}
.tabs-links li .nav-link::after{
  display: none;
}
.tabs-links li .nav-link.active{
  border-color: #8AC73C;
}
</style>
