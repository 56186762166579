<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">
              <router-link
                :to="{ name: 'coachUserList' }"
                class="d-inline-block me-4 text-dark"
                ><i class="fas fa-arrow-left"></i></router-link
              >Details
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
              <div class="mt-5 row justify-content-center">
                <ul
                  class="mb-5 nav nav-pills justify-content-center tabs-links"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'coachCompleteDetails',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Completed Assessments
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      title="Click Here"
                      :to="{
                        name: 'coachUploadedExercise',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Uploaded Exercises
                    </router-link>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="table-responsive border-table">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th class="text-center" scope="col">
                              Assessment Name
                            </th>
                            <th class="text-center" scope="col">Action</th>
                            <th class="text-center" scope="col">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              assessmentLists, indexKey
                            ) in allGetAssessmentCompleteData"
                            :key="indexKey"
                          >
                            <td class="text-center">
                              {{ assessmentLists.title }}
                            </td>

                            <td class="text-end">
                              <div
                                class="d-flex align-items-center justify-content-center"
                              >
                                <router-link
                                  :to="{
                                    name: 'userAssessmentInfo',
                                    params: {
                                      userId: assessmentLists.user_id,
                                      assessmentId:
                                        assessmentLists.assessment_id,
                                    },
                                  }"
                                  class="btn btn-primary btn-white btn-lg"
                                >
                                  {{ assessmentLists.title }}
                                </router-link>
                              </div>
                            </td>
                            <td class="text-center">
                              <a
                                :href="assessmentLists.pdf_url"
                                target="_blank"
                              >
                                {{ fileName(assessmentLists.pdf_url) }}
                              </a>
                            </td>
                          </tr>
                          <tr v-if="notAssessmentData">
                            <td colspan="3" class="text-center">
                              <b>No data available</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      allGetAssessmentCompleteData: [],
      notAssessmentData: false,
    };
  },
  mounted() {
    this.getAssessmentComplete();
  },

  methods: {
    ...mapActions("assessment", {
      actionAllGetAssessmentComplete: "allGetAssessmentComplete",
    }),
    async getAssessmentComplete() {
      let vueInstance = this;
      vueInstance.notAssessmentData = false;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      await vueInstance
        .actionAllGetAssessmentComplete({
          id: vueInstance.$route.params.userId,
        })
        .then((req) => {
          if (req.error == true) {
            vueInstance.$dialog.alert(req.message, options);
            return false;
          }
          if (req.response.length < 1) {
            vueInstance.notAssessmentData = true;
            return false;
          }
          vueInstance.allGetAssessmentCompleteData = req.response;
        })
        .catch((err) => {
          vueInstance.$dialog.alert(err.message, options);
          return false;
        });
    },
    fileName(url) {
      return url.substring(url.lastIndexOf("/") + 1);
    },
  },
};
</script>
