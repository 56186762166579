<template>
    <section class="section bg-grey pt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="text22 fw-medium mb-5">
                        <router-link :to="{ name: 'coachResourcesArticle' ,params: { assessmentId: $route.params.assessmentId} }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link>
                        Resources
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <flash-message></flash-message>
                <div class="col-lg-12">
                    <div class="block-content p-5 bg-white min-h">
                        <div class="blog-list-box">
                            <div class="img-box position-relative blog-img-box">
                                <img
                                    v-if="image"
                                    :src="image"
                                    class="img-fluid w-100"
                                    alt="Blog"
                                />
                            </div>
                            <div class="p-4">
                                <div class="mb-4">
                                    <label class="form-label"><b>Title:</b></label>
                                    <p v-html="title"></p>
                                </div>
                                <div class="mb-4">
                                    <label class="form-label"><b>Content:</b></label>
                                    <p v-html="content"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            articleData: {},
            title: "",
            image: "",
            content: ""
        };
    },
    mounted() {
        this.getArticlesData();
    },
    methods: {
        ...mapActions("assessment", {
            actionGetSignalArticle: "getCoachSignalArticle"
        }),
        async getArticlesData() {
            let vueInstance = this;
            await vueInstance
                .actionGetSignalArticle({
                    id: this.$route.params.id,
                    assessmentId: this.$route.params.assessmentId
                })
                .then(results => {
                    if (results.error == true) {
                        vueInstance.flashError(results.message, {
                            timeout: 10000
                        });
                        return false;
                    }
                    vueInstance.articleData = results.response;
                    vueInstance.title = vueInstance.articleData.title;
                    vueInstance.content = vueInstance.articleData.description;
                    vueInstance.image = vueInstance.articleData.image;
                })
                .catch(err => {
                    vueInstance.flashError(err.message, { timeout: 10000 });
                    return false;
                });
        }
    }
};
</script>

<style scoped>
main .section .btn-primary {
    font-size: 1.4rem;
    min-width: 70px;
    text-align: left;
}
</style>
