<template>
  <section class="section bg-grey pt-5">
    <div class="container">
      <flash-message></flash-message>
      <div class="row">
        <div class="col-md-12">
          <div class="text22 fw-medium mb-5">
            <router-link
              :to="{ name: 'adminUserList' }"
              class="d-inline-block me-4 text-dark"
              ><i class="fas fa-arrow-left"></i
            ></router-link>
            {{ profileData.first_name }} {{ profileData.last_name }}
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="block-content p-5 bg-white min-h">
            <div class="row">
              <div
                class="col-md-12 text-end mb-3"
                data-html2canvas-ignore="true"
              >
                <router-link
                  :to="{
                    name: 'adminUserEdit',
                    params: { userId: this.$route.params.userId },
                  }"
                  class="edit-icon ms-auto me-3"
                  title="Edit"
                  ><i class="fas fa-pencil-alt" aria-hidden="true"></i
                ></router-link>
                <div
                  @click.prevent="deleteUser"
                  class="btn btn-danger btn-rounded btn-sm"
                >
                  Delete
                </div>
              </div>
            </div>
            <div class="text-center mb-4">
              <div class="user-profile-box mx-auto">
                <img :src="baseUrl + '/public/images/user-big.svg'" />
              </div>
            </div>
            <div class="text-center">
              {{ profileData.first_name }} {{ profileData.last_name }}
            </div>
            <div class="text-center">{{ profileData.email }}</div>
            <div class="text-center">{{ phoneNo }}</div>
          </div>
        </div>
      </div>

      <div class="row" v-if="checkAsseign">
        <div class="col-md-12">
          <div class="text22 fw-medium mb-5">
            Coach Assigned to {{ profileData.first_name }}
            {{ profileData.last_name }}
          </div>
          <!-- <div class="text22 fw-medium mb-5" v-else>Please Assign Coach</div> -->
        </div>
      </div>
      <div class="row justify-content-center" v-if="checkAsseign">
        <div class="col-lg-12">
          <div class="block-content p-5 bg-white min-h">
            <div class="text-center mb-4"></div>
            <div class="text-center">
              {{ coachInfo.coach_first_name }} {{ coachInfo.coach_last_name }}
            </div>
            <div class="text-center">{{ coachInfo.coach_email }}</div>
            <div class="text-center">{{ coachPhoneNo }}</div>
          </div>
        </div>
      </div>
      <!--
      <div class="row" v-else>
        <div class="col-lg-12">
          <div class="block-content p-5 bg-white min-h">
            <div class="d-flex">
              <div class="field flex-grow-1">
                <select
                  v-model.trim="selectCoach"
                  class="browser-default custom-select form-control"
                >
                  <option value="">Select Coach</option>
                  <option
                    v-for="(coach, index) in coachData.data"
                    :key="index"
                    :value="coach.id"
                  >
                    {{ coach.first_name }} {{ coach.last_name }}
                  </option>
                </select>
                <div v-if="$v.selectCoach.$dirty">
                  <p class="text-danger" v-if="!$v.selectCoach.required">
                    Please select an one option.
                  </p>
                </div>
              </div>
              <div class="ms-4">
                <button
                  :disabled="$v.$invalid"
                  @click.prevent="assignCoach"
                  type="submit"
                  class="btn btn-primary btn-rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    --></div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
// import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      profileData: "",
      phoneNo: null,
      coachInfo: {},
      coachPhoneNo: null,
      checkAsseign: false,
      // coachData: {},
      // selectCoach: "",
    };
  },
  // validations: {
  //   selectCoach: {
  //     required,
  //   },
  // },
  created() {
    this.getProfileData();
    // this.getCoachData();
  },
  methods: {
    ...mapActions("adminDashboard", {
      actionUserInfo: "userInfo",
    }),
    // ...mapActions("adminDashboard", {
    //   actionAssignCoach: "assignCoach",
    // }),
    // ...mapActions("adminDashboard", {
    //   actionCoachUsers: "coachUsers",
    // }),
    ...mapActions("adminDashboard", {
      actionDeleteUser: "deleteUser",
    }),
    /*
    async assignCoach() {
      let vueInstance = this;
      $(".btn").prop("disabled", true);
      vueInstance.spinnerShow = true;
      $("html, body").animate({ scrollTop: 0 }, "slow");
      await vueInstance
        .actionAssignCoach({
          id: vueInstance.$route.params.userId,
          coachId: vueInstance.selectCoach,
        })
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 4000 });
            return false;
          }
          vueInstance.flashSuccess(results.message, { timeout: 4000 });
          vueInstance.getProfileData();
          return false;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
  
    async getCoachData() {
      let vueInstance = this;
      await vueInstance
        .actionCoachUsers()
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }
          vueInstance.coachData = results.response;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
    */
    async getProfileData() {
      let vueInstance = this;
      await vueInstance
        .actionUserInfo({ id: this.$route.params.userId })
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }
          vueInstance.profileData = results.response;
          var cleaned = ("" + vueInstance.profileData.mobile).replace(
            /\D/g,
            ""
          );
          var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            vueInstance.phoneNo =
              "(" + match[1] + ") " + match[2] + "-" + match[3];
          }

          if (vueInstance.profileData.coach_info != null) {
            vueInstance.coachInfo = vueInstance.profileData.coach_info;
            var coachCleaned = (
              "" + vueInstance.coachInfo.coach_mobile
            ).replace(/\D/g, "");
            var matchCoach = coachCleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (matchCoach) {
              vueInstance.coachPhoneNo =
                "(" +
                matchCoach[1] +
                ") " +
                matchCoach[2] +
                "-" +
                matchCoach[3];
            }
            vueInstance.checkAsseign = true;
          }
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
    async deleteUser() {
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      vueInstance.$dialog
        .confirm("Do you want to delete?")
        .then(function (dialog) {
          vueInstance
            .actionDeleteUser({ id: vueInstance.$route.params.userId })
            .then((req) => {
              if (req.error == true) {
                vueInstance.$dialog.alert(req.message, options);
                return false;
              }
              vueInstance.flashSuccess(req.message, { timeout: 4000 });
              vueInstance.$router.push({ name: "adminUserList" });
              return false;
            })
            .catch((err) => {
              vueInstance.$dialog.alert(err.message, options);
              return false;
            });
        });
    },
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
main .section .btn-danger {
  font-size: 1rem;
  text-align: left;
}
.edit-icon {
  display: inline;
  width: 27px;
  height: 27px;
  color: #8ac73c;
  font-size: 1.6rem;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background: rgba(168, 199, 60, 0.2);
}
</style>
