import axiosInstance from '../../helper/axiosInstance'
const state = () => ({
});
const mutations = {
};
const getters = {
};
const actions = {
    async allNotifications({ commit }, payload) {
        return axiosInstance.get(`notifications`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async readNotifications({ commit }, payload) { 
        let dataPlayload = {
            notification_ids:'',
        }
        if(payload.id){
            dataPlayload = {
                notification_ids:[payload.id],
            }
        }
        return axiosInstance.post(`notifications/read`, dataPlayload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async allChatList({ commit }, payload) { 
        let requestUrl = 'support-request'
        if(payload.forScreen == 'askCoach')
            requestUrl = 'ask-coach'
           
        return axiosInstance.get(`${requestUrl}?page= ${payload.page}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async chatDetails({ commit }, payload) {
        let requestUrl = 'support-request'
        if(payload.forScreen == 'askCoach')
            requestUrl = 'ask-coach'
        
        return axiosInstance.get(`${requestUrl}?groupID= ${payload.groupId}&page=${payload.page}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async chatConversation({ commit }, payload) { 
        let requestUrl = 'support-request/conversation'
        if(payload.forScreen == 'askCoach')
            requestUrl = 'ask-coach/conversation'
        let dataPlayload = {
            message: payload.chatMessage,
            group_id: payload.messageGroupId,
        }
        return axiosInstance.post(requestUrl,dataPlayload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}