require('./bootstrap');

window.Vue = require('vue');
import router from './router'; 

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import store from './store/index'
import VueFlashMessage from 'vue-flash-message';
require('vue-flash-message/dist/vue-flash-message.min.css');
Vue.use(VueFlashMessage);

import VuejsDialog from 'vuejs-dialog';
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components

import moment from 'moment-timezone';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.filter('phoneNoFormate',  function(number)  {
  if  (!number)  {
    return  "";
  }
  var x = number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return  !x[2]? x[1]: "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
});
Vue.filter('longDate',  function(value)  {
  if  (!value)  {
    return  "";
  }
  var date = new Date (value);
  //return  date.toLocaleString ('en-GB', {year: 'numeric', month: 'short', day: 'numeric'});
  return  date.toLocaleString ('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
});
Vue.filter('longDateSec',  function(value)  {
  if  (!value)  {
    return  "";
  }
  var date = new Date (value*1000);
  return  date.toLocaleString ('en-GB', {year: 'numeric', month: 'long', day: 'numeric'});
});
Vue.filter('convertSecToMin',  function(seconds)  {
  if  (!seconds)  {
    return  "";
  }
  
  return moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('HH:mm');
});
Vue.filter('noteDayFormate',  function(date)  {
  if  (!date)  {
    return  "";
  }
  var createdDate = moment(date)
  return createdDate.format('DD MM YYYY')
});
Vue.filter('noteDayTimeFormate',  function(date)  {
  if  (!date)  {
    return  "";
  }
  var createdDate = moment(date)
  var REFERENCE = moment(); 
  var TODAY = REFERENCE.clone().startOf('day');
  var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
  if(createdDate.isSame(TODAY, 'd'))
    return createdDate.tz("America/New_York").format('hh:mm a');
  else if(createdDate.isSame(YESTERDAY, 'd')) 
    return 'Yesterday'; 
  else
    return createdDate.format('DD MMM YYYY')
});
Vue.filter('dateTime',  function(dateTime)  {
  if  (!dateTime)  {
    return  "";
  }  
  return moment(dateTime).format('MM/DD/YYYY HH:mm A');
});
Vue.filter('strLimit',  function(value, size)  {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

window.configObj = {
  "baseUrl":  $ ('head base').attr ('href'),
  "podcastClientId": '1c8d6ca212fd78d83d884',
  "podcastClientSecret":  '4764bd04ac39161dac952',
}

Vue.config.productionTip = false

import App from './views/App' 
const app = new Vue({
    el: '#app',
    components: { App },
    router,
    store
}); 