<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5"><router-link :to="{ name: 'adminSubAdminList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link> Sub Admin Info</div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
        <div class="block-content p-5 bg-white min-h">
          <div class="text-end">
            <router-link :to="{ name: 'adminSubAdminEdit',params:{id: this.$route.params.id} }" class="edit-icon ms-auto"
                ><i class="fas fa-pencil-alt" aria-hidden="true"></i
              ></router-link>
          </div>
          <div class="row">
            <div class="col-md-3 mb-5">
              <div class="text-center mb-4">
                <div class="user-profile-box mx-auto">
                  <img :src="baseUrl + '/public/images/user-big.svg'">
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 col-lg-2 mb-4">
                  Name
                </div>
                <div class="col-md-8 mb-4">
                  <div class="fw-medium">{{profileData.first_name}} {{profileData.last_name}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-4  col-lg-2">
                  Email
                </div>
                <div class="col-md-8 mb-4">
                  <div class="fw-medium">{{profileData.email}}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 mb-4  col-lg-2">
                  Phone
                </div>
                <div class="col-md-8 mb-4">
                  <div class="fw-medium">{{phoneNo}}</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      </div>
      </div>
      </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Role } from '../../../../helper/role'
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      profileData: "",
      phoneNo: null,
    };
  },
  mounted(){
    if(this.$store.state.auth.authData.loggedInRole == Role.SubAdmin)
        this.$router.push({ name: "adminDashboard" });
    this.getSubAdminProfileData()
  },
  methods:{
    ...mapActions("adminDashboard", {
      actionSubAdminInfo: "userInfo",
    }),
    async getSubAdminProfileData(){
      let vueInstance = this;
      await vueInstance.actionSubAdminInfo({id : this.$route.params.id}).then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        vueInstance.profileData = results.response;
        var cleaned = ('' + vueInstance.profileData.mobile).replace(/\D/g, '')
        var match= cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          vueInstance.phoneNo= '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        vueInstance.email = vueInstance.profileData.email;
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },
  }
};
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
