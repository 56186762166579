<template>
  <div class="row g-5 topScroll">
    <div v-if="error" v-html="error">{{ error }}</div>
    <div class="col-md-6 mb-5" v-for="(podcast, index) in podcastsData" :key="index">
        <div class="blog-list-box" @click.prevent="redirectDetail(podcast.id)">
          <div class="img-box">
            <a href="#">
              <img :src="podcast.logo" class="img-fluid w-100" :alt="podcast.type" />
            </a>
          </div>
          <div class="p-4">
            <div class="text-green para-sm mb-2">
              {{ podcast.type }} | {{ podcast.publish_time | longDateSec }}
            </div>
            <h3 class="heading22 fw-medium mb-4">
              <a href="#" :inner-html.prop="podcast.title | strLimit(100)"></a>
            </h3>
            <p class="para-md mb-5" :inner-html.prop="podcast.content | strLimit(200)"></p>
           
          </div>
        </div>
    </div>
    <div class="mb-5" v-if="checkPodcastsData">No podcast available</div>
    
    <div class="d-flex bd-highlight">
			<div  :class="['p-2 flex-fill', (offset > 0 ? 'cursor-pointer' : 'disabled')]" v-on="(offset > 0) ? { click: prevPage} : ''" ><img :src="baseUrl+'/public/images/arrow-round-back-left.svg'"></div>
			<div :class="['p-2 flex-fill text-end', (hasMore ? 'cursor-pointer' : 'disabled')]"  v-on="(!hasMore) ? '' : { click: nextPage}"><img :src="baseUrl+'/public/images/arrow-round-back.svg'"></div>
	</div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  
  data() {
    return {
      baseUrl: configObj.baseUrl,
      podcastClientId: configObj.podcastClientId,
      podcastClientSecret: configObj.podcastClientSecret,
      podcastUrl: configObj.podcastUrl,
      offset: 0,
      limitPodbean: 50, 
      totalData:0,
      hasMore:false,
      podcastsData: {},
      checkPodcastsData: false,
      error: null,
    };
  },
  mounted() {
      this.getPodcasts();
  },

  methods: {
    async getPodcasts() { 
      let vueInstance = this;
      vueInstance.error = null;
      let auth = btoa(vueInstance.podcastClientId + ':' + vueInstance.podcastClientSecret);
      let data={
        authorization: auth,
        getToeknURL:vueInstance.podcastUrl+'/v1/oauth/token',
      }
      await axios.post(vueInstance.baseUrl+'/api/token', data).then((response) => {
        if (response != null) {
          if (response.status == 200) {
            var podCastTokenResponse = response.data.access_token;
            localStorage.setItem("podCastToken", podCastTokenResponse);
            vueInstance.getPodebeadData(podCastTokenResponse)
          }else
            vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
        }else
          vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
      })
      .catch((error) => {
        vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
      })

    },

    async getPodebeadData(podCastToken){ 
      let vueInstance = this;
      vueInstance.error = null;
      let offsetData = vueInstance.offset;
      let limitData = vueInstance.limitPodbean;
      let data={
        podCastToken:podCastToken,
        offsetData:offsetData,
        limitData:limitData,
      }
      await axios.post(vueInstance.baseUrl+'/api/podbeanData', data).then((response) => {
        if (response != null) {
          if (response.status == 200) {
              if(response.data.episodes.length <= 0){
              vueInstance.checkPodcastsData= true
              return
            }
            vueInstance.podcastsData = response.data.episodes.filter(r => r.status == 'publish');
           vueInstance.totalData= response.data.count;
           vueInstance.hasMore= response.data.has_more;
          }else
            vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
        }else
            vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
      })
      .catch((error) => {
            vueInstance.error = `<p class='alert alert-danger'>Sorry! facing some problem<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
      })
    },
    async nextPage(){
			let vueInstance = this;
      vueInstance.offset += vueInstance.limitPodbean;
      let podCastToken = localStorage.getItem("podCastToken")
			vueInstance.getPodebeadData(podCastToken)
      $("html, body").animate({ scrollTop: 0 }, "slow");
		},
		prevPage(){
		  let vueInstance = this;
      vueInstance.offset -= vueInstance.limitPodbean; 
      let podCastToken = localStorage.getItem("podCastToken")
			vueInstance.getPodebeadData(podCastToken)
			$("html, body").animate({ scrollTop: 0}, "slow");
			return true;
		},

    redirectDetail(id){
      this.$router.push({name: 'podcastDetail',params: { podCastId: id }});
    },
  },
};
</script>
