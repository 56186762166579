<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Subscription Payment Information</div>
          </div>
          
        </div>

        <div class="block-content min-h p-4"> <flash-message></flash-message>
          <div class="table-responsive border-table">
            <table class="table mb-0">

              <thead>
              <tr>
                <th scope="col">Transaction Id</th>
                <th scope="col">Amount</th>
                <th scope="col">Name</th>
                <th scope="col">Email Address</th>
                <th scope="col">Phone #</th>
                <th scope="col">Assessment Name</th>
                <th scope="col">Subscription Date</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(paymentList,index) in paymentListData.data" :key="index">
                <td>{{ paymentList.transaction_id}}</td>
                <td>{{ paymentList.amount}}</td>
                <td>{{ paymentList.user.first_name}} {{ paymentList.user.last_name}}</td>
                <td>{{ paymentList.user.email}}</td>
                <td>{{ paymentList.user.mobile | phoneNoFormate}}</td>
                <td>{{ paymentList.assessment.title}}</td>
                <td>{{ paymentList.created_at | longDate}}</td>
              </tr>
              <tr v-if="checkData">No data available</tr>
              </tbody>
              </table>
            </div>

        </div>
        <div class="text-center pagger mt-5">
          <pagination align="center" :limit=limit size="large" :data="paymentListData" @pagination-change-page="getPaymentList" />
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from '../../../helper/axiosInstance'
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      paymentListData:{
        type:Object,
        default: null
      },
      checkData: false,     
    };
  },
  mounted () {
    this.getPaymentList();
  },

  methods: {
    
    async getPaymentList(page = 1) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      axiosInstance.get(`admin/assessments/subscriptions?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }
  
        if(response && Object.keys( response.data.response.data).length > 0)
          vueInstance.paymentListData = response.data.response
        else
          vueInstance.checkData= true
      }).catch(error=> { 
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
