<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">Resources</div>
          </div>
        </div>

        <div class="row justify-content-center">
          <flash-message></flash-message>
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
              <div class="blog-list-box">
                <div class="img-box position-relative blog-img-box">
                  <img :src="image" class="img-fluid w-100" :alt="title" />
                </div>
                <div class="p-4">
                  <div class="mb-4">
                    <label class="mb-2 fw-bolder">Podcast Title</label>
                  <p>  {{ title}}</p>
                    <div v-if="$v.title.$dirty">
                      <p class="text-danger" v-if="!$v.title.required">
                        Please enter podcast title.
                      </p>
                      <p
                        class="text-danger"
                        v-if="!$v.title.minLength || !$v.title.maxLength"
                      >
                        The podcast title must be between
                        {{ $v.title.$params.minLength.min }} and
                        {{ $v.title.$params.maxLength.max }} characters.
                      </p>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="mb-2 fw-bolder">Media url</label>
                    <div class="input-group">
                     <p> {{ mediaUrl}}</p>
                    </div>
                    <div v-if="$v.mediaUrl.$dirty">
                      <p class="text-danger" v-if="!$v.mediaUrl.required">
                        Please enter podcast title.
                      </p>
                      <p class="text-danger" v-if="!$v.mediaUrl.url">
                        Please enter valid url.
                      </p>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="mb-2 fw-bolder">Status</label>
                    <div class="input-group">
                     <p> {{ status}}</p>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="mb-2 fw-bolder"
                      >Podcast Description (Body Content)</label
                    >
                    <p v-html="content"></p>
                    <div v-if="$v.content.$dirty">
                      <p class="text-danger" v-if="!$v.content.required">
                        Please enter article Description.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  required,
  helpers,
  url,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";

//const imageRule = helpers.regex('image', /\.(jpe?g|png)$/)
export default {
  data() {
    return {
      spinnerShow: false,
      error: null,
      episodeData: {},
      title: "",
      image: "",
      mediaUrl: "",
      content: "",
      status: "publish",
      type: "public",
    };
  },
  validations: {
    image: {
      required,
      //  imageRule
    },
    mediaUrl: {
      required,
      url,
    },
    title: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350),
    },
    content: {
      required,
    },
  },
  mounted() {
    this.getPodcastsData();
  },
  methods: {
    onImageChange(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.image = e.target.result;
      };
    },
    ...mapActions("assessment", {
      actionEditPodcast: "editPodcast",
    }),
    ...mapActions("assessment", {
      actionGetSignalPodcast: "getCoachSignalPodcast",
    }),
    async getPodcastsData() {
      let vueInstance = this;
      await vueInstance
        .actionGetSignalPodcast({ id: this.$route.params.id })
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }

          vueInstance.episodeData = results.response.episode;
          vueInstance.title = vueInstance.episodeData.title;
          vueInstance.mediaUrl = vueInstance.episodeData.media_url;
          vueInstance.content = vueInstance.episodeData.content;
          vueInstance.image = vueInstance.episodeData.logo;
          vueInstance.status = vueInstance.episodeData.status;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
    async editPodcast() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance
          .actionEditPodcast({
            title: vueInstance.title,
            //  image: vueInstance.image,
            content: vueInstance.content,
            type: vueInstance.type,
            status: vueInstance.status,
            mediaUrl: vueInstance.mediaUrl,
            id: vueInstance.$route.params.id,
          })
          .then((results) => {
            $(".btn").prop("disabled", false);
            if (results.error == true) {
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false;
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.$router.push({ name: "adminPodcastList" });
            return false;
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });

        vueInstance.spinnerShow = false;
      }
    },
  },
};
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
