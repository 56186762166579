<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text-green fw-medium ms-5 ps-3">{{assessmentData.title}}</div>
            <div class="mb-5 text22 fw-medium"><router-link :to="{ name: 'adminQuestion',params: {  assessmentId: this.$route.params.assessmentId,moduleId: this.$route.params.moduleId } }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link> {{moduleData.title}}</div>
          </div>
          <div class="mb-3 col-md-4 text-end">
            <a href="#" @click.prevent="addQuestionPlan" class="text-center btn btn-primary btn-rounded">Save</a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
              <flash-message></flash-message>
              <div class="p-5 bg-white block-content min-h">
                <div class="mb-5 text22 fw-medium">Add Questions {{countQuestions}}</div>
                <div class="mb-4">
                  <input v-model.trim="question" @input="$v.question.$touch()" type="text" class="border form-control bg-grey fw-medium" placeholder="I have a positive attitude towards my future.">
                  <div v-if="$v.question.$dirty">
                    <p class="text-danger" v-if="!$v.question.required">Please enter question.</p>
                    <p class="text-danger" v-if="!$v.question.minLength || !$v.question.maxLength">
                      The question must be between {{$v.question.$params.minLength.min}} and {{$v.question.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="option1" @input="$v.option1.$touch()" type="text" class="border form-control bg-grey " placeholder="No not at all">
                  <div v-if="$v.option1.$dirty">
                    <p class="text-danger" v-if="!$v.option1.required">Please enter option 1.</p>
                    <p class="text-danger" v-if="!$v.option1.minLength || !$v.option1.maxLength">
                      The options 1 must be between {{$v.option1.$params.minLength.min}} and {{$v.option1.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="option2" @input="$v.option2.$touch()" type="text" class="border form-control bg-grey " placeholder="I am slightly concerned">
                  <div v-if="$v.option2.$dirty">
                    <p class="text-danger" v-if="!$v.option2.required">Please enter option 2.</p>
                    <p class="text-danger" v-if="!$v.option2.minLength || !$v.option2.maxLength">
                      The options 2 must be between {{$v.option2.$params.minLength.min}} and {{$v.option2.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="option3" @input="$v.option3.$touch()" type="text" class="border form-control bg-grey " placeholder="I am neutral">
                  <div v-if="$v.option3.$dirty">
                    <p class="text-danger" v-if="!$v.option3.required">Please enter option 3.</p>
                    <p class="text-danger" v-if="!$v.option3.minLength || !$v.option3.maxLength">
                      The options 3 must be between {{$v.option3.$params.minLength.min}} and {{$v.option3.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="option4" @input="$v.option4.$touch()" type="text" class="border form-control bg-grey " placeholder="I am slightly optimistic">
                  <div v-if="$v.option4.$dirty">
                    <p class="text-danger" v-if="!$v.option4.required">Please enter option 4.</p>
                    <p class="text-danger" v-if="!$v.option4.minLength || !$v.option4.maxLength">
                      The options 4 must be between {{$v.option4.$params.minLength.min}} and {{$v.option4.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="option5" @input="$v.option5.$touch()" type="text" class="border form-control bg-grey " placeholder="I am ready to launch">
                  <div v-if="$v.option5.$dirty">
                    <p class="text-danger" v-if="!$v.option5.required">Please enter option 5.</p>
                    <p class="text-danger" v-if="!$v.option5.minLength || !$v.option5.maxLength">
                      The options 5 must be between {{$v.option5.$params.minLength.min}} and {{$v.option5.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>

                <div class="mt-5 text-end">
                  <router-link :to="{ name: 'adminQuestion',params: {  assessmentId: this.$route.params.assessmentId,moduleId: this.$route.params.moduleId }}" class="text-center btn btn-primary btn-rounded">Cancel</router-link>
                </div>
              </div>

            </div>
          </div>
        </div>  
      </section>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
 
  data() {
    return {
      question: null,
      countQuestions: '',
      option1: "Disagree", 
      option2: "Slightly Disagree",
      option3: "Neutral",
      option4: "Slightly Agree",
      option5: "Agree",
      // option1: "No not at all",
      // option2: "I am slightly concerned",
      // option3: "I am neutral",
      // option4: "I am slightly optimistic",
      // option5: "I am ready to launch",
      assessmentData: {},
      moduleData: {},
    };
  },
 
  validations: {
    question: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350)
    },
    option1: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    option2: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    option3: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    option4: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    option5: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
  },
  mounted () {
    this.getData();
  },
  methods: {
    ...mapActions("assessment", {
      actionQuestionList: "questionList",
    }),
    ...mapActions("assessment", {
      actionAddQuestionOption: "addQuestionOption",
    }),
    ...mapActions("assessment", {
      actionSingalAssessment: "singalAssessmentData",
    }),
    ...mapActions("assessment", {
      actionSingalModule: "singalModuleData",
    }),
    async getData() {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      let data ={
        assessmentId: vueInstance.$route.params.assessmentId,
        moduleId: vueInstance.$route.params.moduleId,
      }
      await vueInstance.actionSingalAssessment(data).then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentData = req.response
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
      await vueInstance.actionSingalModule(data).then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.moduleData = req.response
      })
      await vueInstance.actionQuestionList(data).then(req => {
			
        let questionListData = req.response
        vueInstance.countQuestions = '#'+(questionListData.questions.length +1)
      })
	  },
    async addQuestionPlan(){ 
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        $("html, body").animate({ scrollTop:0 }, "slow");
        const data = [vueInstance.option1,vueInstance.option2,vueInstance.option3,vueInstance.option4,vueInstance.option5]
        await vueInstance.actionAddQuestionOption({
          questionAdd: vueInstance.question,
          optionsAdd: data,
          assessmentId: vueInstance.$route.params.assessmentId,
          moduleId: vueInstance.$route.params.moduleId,
        }).then((results) => { 
            if (results.error == true) { 
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            vueInstance.$router.push({ name: 'adminQuestion',params: {  assessmentId: vueInstance.$route.params.assessmentId,moduleId: vueInstance.$route.params.moduleId } });
            return false;
            
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
   
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
