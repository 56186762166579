<template>
  <div>
    <div class="section bg-grey wrap">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-5 col-xl-4">
            <h3 class="text-green text-center mb-3">Forgot Password</h3>
            <p class="text-center mb-5 text-green">Please enter your mobile number<br> used at the time of registration.</p>
            <form id="myform" action="#">
              <div class="mb-5">
                <label for="exampleInputEmail1" class="form-label d-none">Mobile number</label>
                <input @keyup="acceptNumber" v-model.trim="phoneNo" @input="$v.phoneNo.$touch()" type="text" class="form-control email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your mobile number">
                <div v-if="$v.phoneNo.$dirty">
                  <p class="text-danger" v-if="!$v.phoneNo.required">Please enter your phone no.</p>
                </div>
              </div>
              <div v-if="error" v-html="error">{{error}}</div>
              <div class="text-center mx-md-5 d-grid gap-2">
              <button @click.prevent="resetSubmit" type="submit" class="btn btn-primary text-center" data-bs-toggle="modal" data-bs-target="#staticForgot">
                <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Reset Password
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      spinnerShow: false,
      phoneNo: "",
      error: null,
    };
  },

  validations: {
    phoneNo: {
      required,
    },
  },
  mounted() {
    localStorage.removeItem("enterData")
  },
  methods: {
    ...mapActions("auth", {
      actionCheckExistUser: "checkExistUser",
    }),
    async resetSubmit(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        var phoneNumber = vueInstance.phoneNo.replace(/[^\d]/g, "");
        var phoneNo = phoneNumber.substr(0, 3) + phoneNumber.substr(3, 3) + phoneNumber.substr(6);
        
        const forgotData ={
          mobile:  phoneNo
        }
        await vueInstance.actionCheckExistUser(forgotData).then((req ) => {

            if(req.error == true){
              vueInstance.error = req.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${req.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
            localStorage.setItem("enterData", JSON.stringify(forgotData));
            vueInstance.$router.push({ name: "forgotPasswordVerification" });
            
        }).catch((err) => {
          
            vueInstance.error = err.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
        })
      }
    },
    acceptNumber() {
      var x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNo = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}

</script>