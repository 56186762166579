<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">
              <router-link
                :to="{
                  name: 'userAssessmentInfo',
                  params: {
                    assessmentId: $route.params.assessmentId,
                    userId: $route.params.userId,
                  },
                }"
                class="d-inline-block me-4 text-dark"
                ><i class="fas fa-arrow-left"></i
              ></router-link>
              {{ assementInfo.title }}
            </div>
          </div>
        </div>
        <div class="p-4 block-content min-h">
          <div class="accordion answer-accordion" id="accordionExample">
            <div
              v-for="(listModule, index) in assessmentQuestionAnswer"
              :key="index"
              class="accordion-item"
            >
              <h2 class="accordion-header" id="heading1">
                <a
                  @click.prevent=""
                  :class="[
                    'accordion-button collapsed',
                    Math.round(listModule.score / 4) < 3
                      ? 'border-left-secondary'
                      : Math.round(listModule.score / 4) == 5
                      ? ''
                      : 'border-green-light',
                  ]"
                  href="#"
                  :data-bs-target="`#collapse${index}`"
                  data-bs-toggle="collapse"
                >
                  <div class="flex-grow-1 text-start">
                    <div class="small-text d-block">Module</div>
                    {{ listModule.title }}
                  </div>
                  <div class="score-text me-3">
                    Score <span>{{ Math.round(listModule.score / 4) }}</span>
                  </div>
                </a>
              </h2>
              <div
                :id="`collapse${index}`"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="border accordion-body border-1 border-grey">
                  <ol>
                    <li
                      v-for="(
                        questionAnswer, questionIndex
                      ) in listModule.questions"
                      :key="questionIndex"
                    >
                      <div class="ms-lg-5">
                        <div class="mb-4 fw-bold text-15">
                          {{ questionAnswer.question }}.
                        </div>
                        <div
                          v-for="(
                            option, optionIndex
                          ) in questionAnswer.options"
                          :key="optionIndex"
                          :class="[
                            'mb-4 text-15',
                            option == questionAnswer.selected_option
                              ? 'fw-bold text-green'
                              : '',
                          ]"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <a
            :href="downloadPDF"
            v-if="downloadPDF"
            class="px-5 py-2 btn btn-dark rounded-5 para-md"
            target="_blank"
          >
            Download Assessment Result
            <i class="fas fa-file-pdf" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      assementInfo: {},
      assessmentQuestionAnswer: {},
      downloadPDF: null,
    };
  },
  mounted() {
    this.getAssessmentData();
  },
  methods: {
    ...mapActions("assessment", {
      actionQuestionAnswer: "assessmentQuestionAnswer",
    }),

    async getAssessmentData() {
      let vueInstance = this;
      await vueInstance
        .actionQuestionAnswer({
          userId: vueInstance.$route.params.userId,
          assessmentId: vueInstance.$route.params.assessmentId,
        })
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }
          const data = results.response;
          vueInstance.assementInfo = data.assessment_info;
          vueInstance.assessmentQuestionAnswer = data.modules;
          vueInstance.downloadPDF = data.pdf_url;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
  },
};
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
