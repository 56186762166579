<template>
  <div class="section bg-grey wrap">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-5 col-xl-4">
          <h3 class="text-green text-center mb-3">Client Sign In</h3>
          <p class="para18 text-center mb-5">Enter your account details below</p>
          <flash-message></flash-message>
          <div v-if="error" v-html="error">{{ error }}</div>
          <div class="mb-5">
            <label for="exampleInputEmail1" class="form-label d-none">User Name</label>
            <input
              type="email"
              v-model.trim="userName"
              @input="$v.userName.$touch()"
              class="form-control email email-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="johndoe@gmail.com"
            />
            <div v-if="$v.userName.$dirty">
              <p class="text-danger" v-if="!$v.userName.required">
                Please enter user name.
              </p>
            </div>
          </div>
          <div class="mb-5">
            <label for="exampleInputPassword1" class="form-label d-none">Password</label>
            <div class="input-group mb-3" id="show_hide_password">
              <input
                :type="passwordFieldType"
                v-model.trim="password"
                @input="$v.password.$touch()"
                class="form-control password-input"
                placeholder="Enter your password"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span
                @click.prevent="switchVisibility"
                class="input-group-text"
                id="basic-addon2"
                ><a href="#" class="eye"
                  ><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i
                ></a>
              </span>
            </div>
            <div v-if="$v.password.$dirty">
              <p class="text-danger" v-if="!$v.password.required">
                Please enter password.
              </p>
              <p class="text-danger" v-if="!$v.password.minLength">
                Please enter password minimum
                {{ $v.password.$params.minLength.min }} lenght.
              </p>
            </div>
          </div>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label text-black-50" for="exampleCheck1"
              >Remember Me</label
            >
          </div>
          <p class="my-5 text-center">
            <router-link :to="{ name: 'forgotPassword' }" class="text-dark"
              >Forgot password?</router-link
            >
          </p>
          <div class="text-center mx-md-5 d-grid gap-2 mb-5">
            <button
              @click.prevent="loginSubmit"
              type="submit"
              class="btn btn-primary text-center"
            >
              <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Log In
            </button>
          </div>

          <p class="my-5 text-center">
            Don't have an account?
            <router-link :to="{ name: 'signUp' }" class="text-green fw-bold text-uppercase">Sign Up</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { Role } from '../../helper/role'
export default {
  data() {
    return {
      spinnerShow: false,
      password: "",
      userName: "",
      error: null,
      passwordFieldType: "password",
      pwIcon: false,
    };
  },

  validations: {
    userName: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },

  mounted() {
    localStorage.removeItem("userData")
    localStorage.removeItem("userLogData")
  },

  methods: {
    ...mapActions("auth", {
      actionCheckLogin: "checkLogin",
    }),

    async loginSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance.actionCheckLogin({
          userName: vueInstance.userName,
          password: vueInstance.password,
        }).then((results) => {

            if (results.error == false) {
              if(results.response.user.account.role == Role.User){
                if(results.response.user.first_time_login == true){
                  vueInstance.$router.push({ name: "changePassword" });
                  return false
                }
                vueInstance.$router.push({ name: "verification" });
                return false
              }
              vueInstance.error = `<p class='alert alert-danger'>Please log in with your registered client credentials<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              localStorage.removeItem("userData");
              localStorage.removeItem("userLogData");
              return false;
            }
            vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
             return false;
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.pwIcon = !this.pwIcon;
    },
  },
};
</script>
