<template>
  <div class="wrap">
    <div class="inner-small-h-banner">
      <div class="container">
        <h1 class="text-center text-white">Notification</h1>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row justify-content-center g-md-5">
          <flash-message></flash-message>
          <div class="col-xxl-11" v-if="getNotificationsData.length > 0">
            <div class="text-green mb-5 text-end"><a href="#" @click.prevent="markRead()" class="text-green fw-medium">Mark all as read</a> </div>
              
              <div v-for="(notificationData, index) in getNotificationsData" :key="index" :class="['notice-box shadow', (notificationData.type == 'SUPPORT')?'support-notice':(notificationData.type == 'ARTICLE' || notificationData.type == 'PODCAST')?'article-notice':'']">
                <div class="d-flex w-100 justify-content-between mb-3">
                  <div class="d-flex align-items-center">
                    <span class="circle-icon me-3">
                      <i :class="['fas', (notificationData.type == 'SUPPORT')?'fa-hands-helping':(notificationData.type == 'ARTICLE' || notificationData.type == 'PODCAST')?'fa-file-alt':'fa-comments']"></i>
                     
                    </span>
                    <h4 class="mb-0" v-html="notificationData.title"></h4>
                  </div>
                  <small class="text-green">{{notificationData.created_at | noteDayFormate}} <i class="fas fa-circle"></i></small>
                </div>
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1" v-html="notificationData.content.message"></p>
                  <a href="#" class="remove-link" @click.prevent="markRead(notificationData.id)">
                    <i class="fas fa-times-circle"></i>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { mapActions } from 'vuex'	
export default {
  data() {
    return {
      getNotificationsData:[],
    };
  },
  created() {
    this.getNotification()
  },
  methods: {
    ...mapActions("notificationChat", {
			actionAllNotifications: "allNotifications",
		}),
    ...mapActions("notificationChat", {
			actionReadNotifications: "readNotifications",
		}),
    async getNotification(){
      let vueInstance = this;
      vueInstance.getNotificationsData = []
      await vueInstance.actionAllNotifications().then((req) => {
				
				if(req.error == true){
					vueInstance.flashError(req.message, { timeout: 10000});
          return false;
				}else{
          if(req.response.length <= 0){
            vueInstance.flashError("You don't have any notification", { timeout: 15000});
            return false;
          }
		      vueInstance.getNotificationsData = req.response;
        }
				
			}).catch((err) => {
				vueInstance.flashError(err.message, { timeout: 10000});
				return false;
			})
    },
    async markRead(id=''){
      let vueInstance = this;
      await vueInstance.actionReadNotifications({id:id}).then((req) => {
				
				if(req.error == true){
					vueInstance.flashError(req.message, { timeout: 10000});
          return false;
				}
				vueInstance.getNotification()
				
			}).catch((err) => {
				vueInstance.flashError(err.message, { timeout: 10000});
				return false;
			})
    },
  }
}

</script>