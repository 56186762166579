<template>
	<div class="block-content p-0">
      <div class="row">
        <div class="col-lg-6 bg-green-with-line"></div>
        <div class="col-lg-6">
          <div class="form-box p-lg-5 m-5">
            <div class="text22 fw-medium mb-5">{{title.formHeading}}</div>
            <form action="add-user-success.php">
              <div class="mb-5">
                <label for="" class="form-label">First Name</label>
                <input v-model.trim="firstName" @input="$v.firstName.$touch()"
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Enter your first name"
                />
                <div v-if="$v.firstName.$dirty">
                  <p class="text-danger" v-if="!$v.firstName.required">Please enter your first name.</p>
                  <p class="text-danger" v-if="!$v.firstName.minLength || !$v.firstName.maxLength ">The first name must be between {{$v.firstName.$params.minLength.min}} and {{$v.firstName.$params.maxLength.max}} characters.</p>
                </div>
              </div>
              <div class="mb-5">
                <label for="" class="form-label">Last Name</label>
                <input v-model.trim="lastName" @input="$v.lastName.$touch()"
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Enter your last name"
                />
                <div v-if="$v.lastName.$dirty">
                  <p class="text-danger" v-if="!$v.lastName.required">Please enter your last name.</p>
                  <p class="text-danger" v-if="!$v.lastName.minLength || !$v.lastName.maxLength ">The last name must be between {{$v.lastName.$params.minLength.min}} and {{$v.lastName.$params.maxLength.max}} characters.</p>
                </div>
              </div>
              <div class="mb-5">
                <label for="" class="form-label">Email Address</label>
                <input v-model.trim="email" @input="$v.email.$touch()"
                  type="email"
                  class="form-control"
                  id=""
                  placeholder="Enter your email"
                />
                <div v-if="$v.email.$dirty">
                  <p class="text-danger" v-if="!$v.email.required">Please enter your email address.</p>
                  <p class="text-danger" v-if="!$v.email.email">Please enter valid email address.</p>
                </div>
              </div>
              <div class="mb-5">
                <label for="" class="form-label">Phone</label>
                <input @keyup="acceptNumber" v-model.trim="phoneNo" @input="$v.phoneNo.$touch()"
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="Enter your Phone #"
                />
                <div v-if="$v.phoneNo.$dirty">
                  <p class="text-danger" v-if="!$v.phoneNo.required">Please enter your phone no.</p>
                </div>
              </div>
            <!--  <div class="mb-5">
                <label for="" class="form-label">Role</label>
                <select :disabled="(this.$route.params.userId? true: false)" v-model="role" class="browser-default custom-select form-control">
                  <option selected value="USER">User</option>
                  <option value="COACH">Coach</option>
                </select>
              </div>
            -->  
              <div v-if="error" v-html="error">{{error}}</div>
              <button  @click.prevent="addUser" type="submit" class="btn btn-primary btn-rounded">
                {{title.btnTitle}}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from 'vuex'	
import evenBus from "../../../event-bus";	
export default {
  //props:[userID],
	data() {
		return {
			spinnerShow: false,
      error: null,
      firstName:"",
      lastName:"",
      phoneNo: "",
      email: "",
    //  password: "",
      role: "USER",
		};
	},
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16)
    },
    lastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(16)
    },
    phoneNo: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    title(){ 
      this.subject= '';
      this.message= '';
      if(this.$route.params.userId){
        let vueInstance = this;
        vueInstance.actionUserInfo({id : this.$route.params.userId}).then(results => {
          
          if(results.error == true){
            vueInstance.flashError(results.message, { timeout: 10000});
            return false;
          }
          const profileData = results.response;
          vueInstance.firstName = profileData.first_name;
          vueInstance.lastName = profileData.last_name;
          vueInstance.email = profileData.email;
        //  vueInstance.role = profileData.account.role;
          var cleaned = ('' + profileData.mobile).replace(/\D/g, '')
          var match= cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
          if (match) {
            vueInstance.phoneNo= '(' + match[1] + ') ' + match[2] + '-' + match[3]
          }
        })
        return {
          formHeading: "Update a New User Information Below",
          btnTitle: "Update User"
        }
      }else{
        return {
          formHeading: "Enter a User Information Below",
          btnTitle: "Create User"
        }
      }
    }
  },
  methods: {
    ...mapActions("adminDashboard", {
      actionAddUser: "addUser",
    }),
    ...mapActions("adminDashboard", {
      actionUserInfo: "userInfo",
    }),
    async addUser(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var phoneNumber = vueInstance.phoneNo.replace(/[^\d]/g, "");
        var phoneNo = phoneNumber.substr(0, 3) + phoneNumber.substr(3, 3) + phoneNumber.substr(6);
        
        await vueInstance.actionAddUser({
          firstName: vueInstance.firstName,
          lastName: vueInstance.lastName,
          email: vueInstance.email,
          mobile: phoneNo,
        //  password: vueInstance.password,
          role: vueInstance.role,
          userId: vueInstance.$route.params.userId
        })
        .then(req  => {

            if(req.error == true){
              vueInstance.error = req.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${req.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            } else{
            //   vueInstance.flashSuccess(req.message, { timeout: 3000 });
            //   vueInstance.$router.push({ name: "adminUserList" });
              evenBus.$emit("successfulAddedUser", {userId: vueInstance.$route.params.userId});
               return false;
            }
            
            
        }).catch((err) => {
            vueInstance.error = err.message;
              vueInstance.error =
                `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
        })

        vueInstance.spinnerShow = false;
      }
    },
    acceptNumber() {
      var x = this.phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phoneNo = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}

</script>
