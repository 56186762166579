<template>
  <div>
    <div class="banner-section d-flex w-100 align-items-center position-relative">
      <div class="container h-100">
        <div id="carouselHome" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselHome"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselHome"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img :src="baseUrl+'/public/images/banner1.svg'" class="d-inline-block banner-img" alt="..." />
              <div class="carousel-caption">
                <!-- <p>Welcome to</p> -->
                <h1>Lifelaunch Consulting helps you define your next chapter and move forward with confidence.</h1>
                <!-- <a
                  href="#"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  >Readiness Assessment</a
                > -->
              </div>
            </div>
            <div class="carousel-item">
              <img
                :src="baseUrl+'/public/images/banner-2.svg'"
                class="d-inline-block banner-img"
                alt="..."
              />
              <div class="carousel-caption">
                <h1>
                  Plan your retirement or<br />
                  your career shift with purpose and intention.
                </h1>
                <!-- <a
                  href="#"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  >Readiness Assessment</a
                > -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main>
      <section class="section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="mb-4 heading22 text-green">Welcome to Lifelaunch</div>
              <p class="para18">
                “The launch plan identifies areas of opportunity and connects you with key resources so you can become launch ready!”
              </p> 
              <a :href="baseUrl+'/public/storage/sample-launch-plan.pdf'" target="_blank" class="btn btn-primary m-w-auto mt-5">Sample Launch Plan</a>
            </div>
          </div>
        </div>
      </section>
      <div class="shape bg-grey">
        <img :src="baseUrl+'/public/images/white-curve-2.svg'" class="w-100" alt="shape" />
      </div>

      <section :class="['section', (index%2 !== 0 ? 'bg-grey' : '')]" v-for="(assessmentInfo,index) in assessmentInfoData" :key="index">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xxl-11">
              <div class="row g-5">
                <div :class="['col-md-5 mb-5 mb-md-0', (index%2 !== 0 ? 'order-md-last' : '')]">
                  <div class="block-img">
                    <div class="m-5 mb-4 text-white heading26">{{ assessmentInfo.type}}</div>
                    <img :src="baseUrl+'/public/images/block-img.svg'" class="my-5 img-fluid" />
                  </div>
                </div>
                <div class="mb-5 col-md-7 mb-md-0">
                  <div class="ms-md-5">
                    <div class="mb-4 heading26 text-green"> {{ assessmentInfo.title}}</div>
                    <p class="mb-5">
                      {{ assessmentInfo.description}}
                    </p>
                    <br class="br" />
                    <br class="br" />
                    <br class="br" />
                    <br class="br" />




                    <button type="submit"  v-if="((assessmentInfo.subscribe && !payLaterRatioBtn) || !$store.state.auth.authData.token)" @click.prevent="assessmentLogin(assessmentInfo.id)" class="btn btn-primary">
                      <svg
                        class="me-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <g id="btn-icon" transform="translate(-692 -1642)">
                          <circle
                            id="Oval"
                            cx="27.5"
                            cy="27.5"
                            r="27.5"
                            transform="translate(692 1642)"
                            fill="#fdfeff"
                          />
                          <path
                            id="Icon_ionic-ios-arrow-back"
                            data-name="Icon ionic-ios-arrow-back"
                            d="M20.683,18,11.746,9.07a1.688,1.688,0,1,1,2.391-2.384L24.262,16.8a1.685,1.685,0,0,1,.049,2.327L14.144,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
                            transform="translate(702.498 1650.806)"
                          />
                        </g>
                      </svg>
                      {{ (assessmentInfo.subscribe)? 'Continue Assessment':'Purchase Assessment'}}
                    </button>

                    <button v-else-if="!assessmentInfo.subscribe && !payLaterRatioBtn" @click.prevent="openPayOption(assessmentInfo.id)" :id="'purchaseAssessmentBtn'+assessmentInfo.id" type="submit" class="btn btn-primary m-w-auto purchaseAssessmentBtn">
                      <svg
                        class="me-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <g id="btn-icon" transform="translate(-692 -1642)">
                          <circle
                            id="Oval"
                            cx="27.5"
                            cy="27.5"
                            r="27.5"
                            transform="translate(692 1642)"
                            fill="#fdfeff"
                          />
                          <path
                            id="Icon_ionic-ios-arrow-back"
                            data-name="Icon ionic-ios-arrow-back"
                            d="M20.683,18,11.746,9.07a1.688,1.688,0,1,1,2.391-2.384L24.262,16.8a1.685,1.685,0,0,1,.049,2.327L14.144,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
                            transform="translate(702.498 1650.806)"
                          />
                        </g>
                      </svg>
                      {{ (assessmentInfo.subscribe)? 'Continue Assessment':'Purchase Assessment' }}
                    </button>


                    <div class="payment-options" :id="'payLaterRatioBtn'+assessmentInfo.id">
                      <div class="form-check form-check-inline me-lg-5" :id="'payLaterCodeInput'+assessmentInfo.id" @click.prevent="checkShowInput(assessmentInfo.id,'PayLaterCode')">
                        <input v-model="payLaterCodeRadioBtn" class="form-check-input" type="radio" :id="'inlineRadio1'+assessmentInfo.id" value="PayLaterCode" autocomplete="off">
                        <label class="form-check-label" :for="'inlineRadio1'+assessmentInfo.id">Pay Via Code</label>
                      </div>
                      <div class="form-check form-check-inline" @click.prevent="checkShowInput(assessmentInfo.id,'payNow')">
                        <input v-model="payLaterCodeRadioBtn" class="form-check-input" type="radio" :id="'inlineRadio2'+assessmentInfo.id" value="payNow" autocomplete="off">
                        <label class="form-check-label" :for="'inlineRadio2'+assessmentInfo.id">Pay Via Card</label>
                      </div>
                    </div>

                    <div class="payment-PayLaterCode" :id="'PayLaterCode'+assessmentInfo.id">
                      <div class="text-end">
                        <button @click.prevent="closeInput(assessmentInfo.id)"  type="button" class="btn-close closePyament" aria-label="Close"></button>
                      </div>
                      <p class="mb-3 para-md text-dark">Please enter the one-time code sent to your email address</p>
                      <div class="mb-3 input-group border-bottom">
                        <input v-model="payLaterInput" required type="text" class="px-0 form-control rounded-0" placeholder="Enter Code" aria-label="Enter Code" aria-describedby="button-addon2">
                        <button  @click.prevent="checkCodeExist(assessmentInfo.id)" class="btn btn-link" type="button" id="button-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Arrow_left" data-name="Arrow left" transform="translate(0.023)">
                              <rect id="Component_7_Icons_Arrow_left_Background_" data-name="Component 7/Icons/Arrow left (Background)" width="24" height="24" transform="translate(23.977 24) rotate(180)" fill="none"/>
                              <path id="arrow_left_361_" data-name="arrow_left [#361]" d="M8.235.234a.8.8,0,0,1,0,1.129L3.083,6.507a.4.4,0,0,0,.282.681H15.2a.806.806,0,0,1,.8.806V8a.791.791,0,0,1-.8.788H3.365a.4.4,0,0,0-.282.682L8.26,14.636a.8.8,0,0,1,0,1.13h0a.8.8,0,0,1-1.131,0L.469,9.117a1.6,1.6,0,0,1,0-2.259L7.1.234A.8.8,0,0,1,8.235.234Z" transform="translate(20 20) rotate(180)"/>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>


                  <button :id="'payNowBtn'+assessmentInfo.id" @click.prevent="assessmentLogin(assessmentInfo.id)" type="submit" class="mt-5 btn btn-primary m-w-auto payNowBtn">
									 <svg
                        class="me-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <g id="btn-icon" transform="translate(-692 -1642)">
                          <circle
                            id="Oval"
                            cx="27.5"
                            cy="27.5"
                            r="27.5"
                            transform="translate(692 1642)"
                            fill="#fdfeff"
                          />
                          <path
                            id="Icon_ionic-ios-arrow-back"
                            data-name="Icon ionic-ios-arrow-back"
                            d="M20.683,18,11.746,9.07a1.688,1.688,0,1,1,2.391-2.384L24.262,16.8a1.685,1.685,0,0,1,.049,2.327L14.144,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
                            transform="translate(702.498 1650.806)"
                          />
                        </g>
                      </svg>
                      Continue Assessment
								    </button>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </main>
  </div>
</template>

<script>
import axiosInstance from '../../helper/axiosInstance'
import { Role } from '../../helper/role'
export default {
  data() {
    return {
       baseUrl: configObj.baseUrl,
        assessmentInfoData:{},
        payLaterCodeRadioBtn: '',
        payLaterRatioBtn: false,
        payLaterInput: '',
      };
  },
   mounted () {
     const loginRole =  this.$store.getters['auth/getAuthData']
     if(loginRole.loggedInRole && loginRole.loggedInRole != Role.User){
       this.$router.push({ name: "unauthenticated"});
       return false
     }
     var appUrl = 'assessments/info';
     if(this.$store.state.auth.authData.token){
       appUrl = 'assessments';
     }

    axiosInstance.get(appUrl)
      .then(response => {
        if(response){
          this.assessmentInfoData = response.data.response
        }
      })
  },
  methods: {
    async openPayOption(id){
      this.payLaterCodeRadioBtn = ''
      this.payLaterInput = ''
      $(".purchaseAssessmentBtn").show();
      $(".payment-options, .payment-PayLaterCode, .payNowBtn").hide();
      $("#payLaterRatioBtn"+id).show();

      $("#purchaseAssessmentBtn"+id).hide();
    },
    async checkShowInput(id,type){
      this.payLaterCodeRadioBtn = type
      if(type == 'payNow'){
        $("#payNowBtn"+id).show();
        $("#PayLaterCode"+id).hide();

        // $("#inlineRadio1"+id).prop("checked", false);
        // $("#inlineRadio2"+id).prop("checked", true);
      }else{
        $("#payNowBtn"+id).hide();
        $("#PayLaterCode"+id).show();
        $("#payLaterRatioBtn"+id).hide();
        // $("#inlineRadio2"+id).prop("checked", false);
        // $("#inlineRadio1"+id).prop("checked", true);
      }

    },

    async closeInput(id){
      $("#payLaterRatioBtn"+id).show();
      $("#PayLaterCode"+id).hide();
    },

    async checkCodeExist(id){
      $(".btn").prop("disabled", true);
      let vueInstance = this;
      let options = {
        loader: false,
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true,
      };
      axiosInstance.post(`assessments/${id}/pay-via-code`, {'pay_via_code': vueInstance.payLaterInput}).then(req  => {

        $(".btn").prop("disabled", false);
        if(req.data.error == true){
          vueInstance.$dialog.alert(req.data.message,options)
          return false;
        }
        setTimeout(function () {
				  vueInstance.$router.push({ name: "assessmentQuestion",params: { assessmentId: id } });
			  }, 1000);
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
    },
    assessmentLogin(assessmentId){
      if(this.$store.state.auth.authData.token){
        let checkSubscribe = this.assessmentInfoData.filter(r => r.id == assessmentId);
        if(!checkSubscribe[0].subscribe){
            this.$router.push({ name:"cardsList" ,params: { assessmentId: assessmentId }})
            return false
        }
        this.$router.push({ name: "assessmentQuestion",params: { assessmentId: assessmentId } });
        return false
      }
   //   this.flashError('The Launch Plan identifies your area of opportunity and connects you with key resources so you can become launch ready', { timeout: 15000});
      this.$router.push({ name: "login" });

    }
  },
}
</script>
<style scoped>
.payment-options, .payment-PayLaterCode, .payNowBtn{
	display: none;
}
</style>
