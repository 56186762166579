<template>
        <div>
            <header-component v-if="showHeader==role.User" :checkedAuthData="checkedAuthData"/>
            <admin-header-component v-else-if="showHeader==role.Admin" :checkedAuthData="checkedAuthData"/>
            <coach-header-component v-else-if="showHeader==role.Coach" :checkedAuthData="checkedAuthData"/>
        <main>
            <router-view :key="$route.fullPath"></router-view>
        </main>
        <footer-component/>
    </div>
</template>
<script> 
import { Role } from '../helper/role'
import { mapGetters } from "vuex";
import FooterComponent from '../components/template/FooterComponent.vue'
import HeaderComponent from '../components/template/HeaderComponent.vue'
import AdminHeaderComponent from '../components/template/admin/AdminHeaderComponent.vue';
import CoachHeaderComponent from '../components/template/coach/CoachHeaderComponent.vue';
    export default {
        components: { FooterComponent, HeaderComponent, AdminHeaderComponent, CoachHeaderComponent },
        data() {
            return {
                role:Role
            };
        },
        computed:{
            showHeader(){
                const currentRole = this.checkedAuthData.loggedInRole
                if(this.$route.meta.permission == Role.Admin || currentRole == Role.Admin)
                    return Role.Admin
                else if(this.$route.meta.permission == Role.Coach || currentRole == Role.Coach)
                    return Role.Coach
                else    
                    return Role.User
            },
            ...mapGetters("auth", {
                checkedAuthData: "getAuthData",
            }),
        },
    } 
</script>


