<template>
  	<div>
		<section class="section bg-grey pt-0">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-xxl-10">
						<div class="meter-content">
							<div class="d-flex pb-3 mb-5">
								<div class="flex-grow-1 para-md ps-3">Note Details</div>
							</div>
							<div class="add-note-box">
								<div class="fw-bold mb-4 pb-4 border-bottom">{{showNotesData.subject}}</div>
								<div class="text-end">{{showNotesData.created_at | noteDayFormate}}</div>
								<div class="border-0 bg-transparent">{{showNotesData.comment}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name:"noteDetail",
	props:['showNotesData'],
}
</script>

