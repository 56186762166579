import axiosInstance from '../../helper/axiosInstance'
const state = () => ({
});
const mutations = {
};
const getters = {
};
const actions = {
    async dashboard({ commit }, payload) {
        return axiosInstance.get(`admin/dashboard`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updateStatus({ commit }, payload) {
        var userId = payload.id;
        return axiosInstance.post(`admin/users/${userId}/change-status`, {status:payload.status}).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async deleteUser({ commit }, payload) { 
        return axiosInstance.delete(`admin/users/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addUser({ commit }, payload) {
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            mobile: payload.mobile,
        //    password: payload.password,
            role: payload.role,
        };
        if(payload.userId){
            return axiosInstance.put(`admin/users/${payload.userId}`, payloadData).then(req => {
                return req.data;
            }).catch(err => {
                throw err;
            })
        }else{
            return axiosInstance.post('admin/users', payloadData).then(req => {
                return req.data;
            }).catch(err => {
                throw err;
            })
        }
    },
    async adminInfo({ commit }, payload) {
        return axiosInstance.get(`auth/profile`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async userInfo({ commit }, payload) {
        return axiosInstance.get(`admin/users/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async allGetAssessmentStatus({ commit }, payload) {
        return axiosInstance.get(`admin/users/${payload.id}/assessments/status`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async allGetAssessmentComplete({ commit }, payload) {
        return axiosInstance.get(`admin/users/${payload.id}/assessments`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async deleteExercise({ commit }, payload) {
        return axiosInstance.delete(`admin/resources-exercise/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async userAssessmentInfo({ commit }, payload) {
        return axiosInstance.get(`admin/users/${payload.userId}/assessments/${payload.assessmentId}/modules`).then(req => { 
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentQuestionAnswer({ commit }, payload) { 
        return axiosInstance.get(`admin/users/${payload.userId}/assessments/${payload.assessmentId}/modules/lists`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assignCoach({ commit }, payload) {
        return axiosInstance.post(`admin/users/${payload.id}/assign-coach`, {coach_id:payload.coachId}).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async coachUsers({ commit }, payload) {
        return axiosInstance.get(`admin/coach`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addCoach({ commit }, payload) {
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            mobile: payload.mobile,
        //    message: payload.message,
        };
        return axiosInstance.post('admin/coach', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async coachInfo({ commit }, payload) {
        return axiosInstance.get(`admin/coach/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async deleteInquiry({ commit }, payload) {
        return axiosInstance.delete(`admin/inquiries/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async coachUserInfo({ commit }, payload) {
        return axiosInstance.get(`coach/auth/profile`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async addSubAdmin({ commit }, payload) {
        return axiosInstance.post(`admin/sub-admins`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
