<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Launch Plan</div>
          </div>
          <div class="col-md-4 text-end mb-5">
            <router-link :to="{ name: 'adminPlanAdd' }" class="btn btn-primary text-center btn-rounded">Add  Plans</router-link>
          </div>

        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">  <flash-message></flash-message>
        <div class="block-content p-5 bg-white min-h"> 
          <div class="row">
            <div v-for="(assessmentLists,index) in assessmentListData" :key="index" class="col-lg-6 mb-5">
              <div class="text-center para18 fw-medium mb-4">{{assessmentLists.title}}</div>
              <div class="border rounded p-5 rounded-3">
                <div class="d-flex justify-content-end">
                  <div class="text-start mb-1 form-check form-switch">
                    <!-- <input :checked="assessmentLists.visibility" @change="changeUserStatus(assessmentLists.id,$event)"
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      ></label> -->
                  </div>        
                  <div class="text-end mb-1">
                    <router-link :to="{ name: 'adminPlanEdit', params: {assessmentId: assessmentLists.id } }" class="edit-icon ms-auto"><i class="fas fa-pencil-alt"></i></router-link>
                  </div>
                   <div class="text-end mb-1" v-if="assessmentLists.status == 'UNPUBLISHED'">
                    <a href="#" @click.prevent="deleteAssessment(assessmentLists.id, index)" class="text-danger edit-icon ms-auto"><i class="fas fa-trash-alt"></i></a>
                  </div>
                </div>
                <div class="text-green text22 fw-medium mb-4">{{assessmentLists.title}}</div>
                <p class="mb-5">{{assessmentLists.description | strLimit(100)}}</p>
                <div v-for="(point,pointIndex) in assessmentLists.points" :key="pointIndex" class="d-flex mb-4">
                  <div class="flex-shrink-1 me-4">
                    <svg id="list-check" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <circle id="Ellipse_66" data-name="Ellipse 66" cx="10" cy="10" r="10" fill="#ecca7c"/>
                      <g id="Group_752" data-name="Group 752" transform="translate(1.753 5.259)">
                        <path id="Path_1883" data-name="Path 1883" d="M1480.53-1875.685,1464-1872.509l3.02,1.751Z" transform="translate(-1463.999 1875.731)" fill="#fff"/>
                        <path id="Path_1885" data-name="Path 1885" d="M1473.639-1870.444l.518,4.718,1.347-3.159,11.106-6.287Z" transform="translate(-1470.55 1875.383)" fill="#afbabc"/>
                        <path id="Path_1886" data-name="Path 1886" d="M1478.487-1855.087l-3.918,3.115,1.352-3.115Z" transform="translate(-1470.934 1861.514)" fill="#d7d8d8"/>
                        <path id="Path_1884" data-name="Path 1884" d="M1482.9-1866.5l8.1-9.334-11.55,6.459Z" transform="translate(-1474.495 1875.83)" fill="#fff"/>
                      </g>
                    </svg>

                  </div>
                  <p>{{point | strLimit(25)}}</p>
                </div>
                
              </div>
            </div>
            
          </div>

        </div>

      </div>
      </div>
      </div>
      </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
 
  data() {
    return {
      assessmentListData:{},
    };
  },
  mounted () {
    this.getAssessmentList();
  },

  methods: {
    ...mapActions("assessment", {
      actionAssessmentLists: "assessmentLists",
    }),
    ...mapActions("assessment", {
      actionUpdateStatus: "updateAssessmentStatus",
    }),
    ...mapActions("assessment", {
      actionDeleteAssessment: "deleteAssessment",
    }),
    async deleteAssessment(assessmentId, index) { 
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      vueInstance.$dialog.confirm('Do you want to delete?').then(function(dialog) {
        
        vueInstance.actionDeleteAssessment({id : assessmentId}).then((req) => {
			
          if(req.error == true){
            vueInstance.$dialog.alert(req.message,options);
            return false;
          }
          vueInstance.assessmentListData.splice(index, 1);
        }).catch((err) => {
          vueInstance.$dialog.alert(err.message,options)
          return false;
        })
      })  
	  },
    async changeUserStatus(assessmentListsId, event){ 
      $(".form-check-input").prop("disabled", true);
      var vueInstance = this;
      let value = (event.target.checked == true)?'on':'off';        
      $(".flash__wrapper").empty();
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      const data = {
        assessmentId: assessmentListsId,
        status: value
      }

      await vueInstance.actionUpdateStatus(data).then((req) => { 
        $(".form-check-input").prop("disabled", false);
          if(req.error == true){ 
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.flashSuccess(req.message, { timeout: 3000 });
        $("html, body").animate({ scrollTop: $(".section").offset().top }, "slow");
        return false
      }).catch((err) => { 
        vueInstance.$dialog.alert(err.message,options)
        $(".form-check-input").prop("disabled", false);
        return false;
      });
    },
    async getAssessmentList() {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      await vueInstance.actionAssessmentLists().then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentListData = req.response
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
      
	  },
	}
}
</script>


<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
