<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <flash-message></flash-message>
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">Pay Via Code</div>
          </div>
          <div class="col-md-4 text-end mb-3">
            <router-link :to="{ name: 'adminUserAdd' }" class="btn btn-primary text-center btn-rounded"
              >Create a New User</router-link>
          </div>
        </div>

        <div class="p-4 block-content min-h">
          <div class="table-responsive border-table">
            <table class="table mb-0">

              <thead>
              <tr>
                <th scope="col">#</th>

                <th scope="col">First Name</th>
                <th scope="col"> Last Name</th>
                <th scope="col">Email Address</th>
                <th scope="col">Phone #</th>
                <th scope="col">Registered Date</th>
                <th scope="col" width="150"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(codeListData,index) in laterCodesListData.data" :key="index">
                  <td>{{++index}}</td>
                  <td>{{codeListData.first_name}}</td>
                  <td>{{codeListData.last_name}}</td>
                  <td>{{codeListData.email}}</td>
                  <td>{{codeListData.mobile | phoneNoFormate}}</td>
                  <td>{{codeListData.created_at | longDate}}</td>
                  <td class="text-end">
                    <a href="#" class="btn btn-orange" @click.prevent="geneCodeModal(codeListData.id)">Generate Code</a>
                  </td>
                </tr>
                <tr v-if="checkUserData">No data available</tr>
              </tbody>
              </table>
            </div>

        </div>
        <div class="mt-5 text-center pagger">
          <pagination align="center" :limit=limit size="large" :data="laterCodesListData" @pagination-change-page="payLaterCodeList" />
        </div>

      </div>
    </section>

    <div class="modal fade" id="geneCode" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      	<div class="modal-dialog modal-dialog-centered" style="max-width: 530px;">
          <div class="modal-content">
            <div class="modal-header border-0">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mx-md-4">
              <div class="para18 fw-medium mt-1 text-center mb-4">Please select an assessment to send a pay Via code.</div>
                <div class="mb-5">
                <select v-model.trim="selectAssessment" class="form-select para15">
                  <option value="">Select Assessment</option>
                  <option  v-for="(assessmentLists,index) in assessmentListData" :key="index" :value="assessmentLists.id">{{assessmentLists.title}}</option>
                </select>
                 <div v-if="$v.selectAssessment.$dirty">
                  <p class="text-danger" v-if="!v.selectAssessment.required">Please select an one option.</p>
                </div>
              </div>
              <div class="mb-5 text-center">
                <button type="button" :disabled="$v.$invalid" @click.prevent="sendCode" class="btn btn-primary btn-rounded">Send Code</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiosInstance from '../../../helper/axiosInstance'
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      laterCodesListData:{
        type:Object,
        default: null
      },
      assessmentListData:{},
      checkUserData: false,
      moduleUserId: null,
      selectAssessment: ""
    };
  },
  validations: {
    selectAssessment: {
      required,
    },

  },
  mounted () {
    this.payLaterCodeList();
    this.getAssessmentList();
  },

  methods: {
    ...mapActions("assessment", {
      actionAssessmentLists: "assessmentLists",
    }),

    async sendCode(){
      let vueInstance = this;
      $(".btn").prop("disabled", true);
      let options = {
        loader: false,
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true,
      };
      axiosInstance.post(`admin/assessments/${vueInstance.selectAssessment}/pay-via-code`, {'user_id': vueInstance.moduleUserId}).then(req  => {

        $(".btn").prop("disabled", false);
        if(req.data.error == true){
          vueInstance.$dialog.alert(req.data.message,options)
          return false;
        }
        $("html, body").animate({ scrollTop:0 }, "slow");
        vueInstance.flashSuccess(req.data.message, { timeout: 4000 });
        vueInstance.selectAssessment = ''
        $('#geneCode').modal('hide')
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })

    },
    async geneCodeModal(id){
      let vueInstance = this;
      vueInstance.moduleUserId = id
      $('#geneCode').modal('show')
    },

    async getAssessmentList() {
      let vueInstance = this;
      let options = {
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true,
      };
      await vueInstance.actionAssessmentLists().then(req => {

        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.assessmentListData = req.response.filter(r => r.status == "PUBLISHED")
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })

	  },

    async payLaterCodeList(page = 1) {
      let vueInstance = this;
      let options = {
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true,
      };
      axiosInstance.get(`admin/users?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }

        if(response && Object.keys( response.data.response.data).length > 0)
          //vueInstance.usersListData = JSON.stringify(response.data.response.data)
          vueInstance.laterCodesListData = response.data.response
        else
          vueInstance.checkUserData= true
      }).catch(error=> {
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
