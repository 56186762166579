<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium"><router-link :to="{ name: 'adminPlanList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link> Edit Plans</div>
          </div>
          <div class="mb-5 col-md-4 text-end">
            <button @click.prevent="editPlan" type="button" class="text-center btn btn-primary btn-rounded">Save</button>
          </div>

        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12"><flash-message></flash-message>
            <div class="p-5 bg-white block-content min-h">
              <div class="mb-5 fw-medium text22">Retirement Plan</div>
              <div class="mb-5">
                <label class="mb-2 para18 fw-medium">Plan Name</label>
                <input v-model.trim="planName" @input="$v.planName.$touch()" type="text" class="border form-control bg-grey">
                <div v-if="$v.planName.$dirty">
                  <p class="text-danger" v-if="!$v.planName.required">
                    Please enter plan name.
                  </p>
                  <p class="text-danger" v-if="!$v.planName.minLength || !$v.planName.maxLength">Plan name must have at least {{ $v.planName.$params.minLength.min }} and {{ $v.planName.$params.maxLength.max }} letters.</p>
                </div>
              </div>
              <div class="mb-5">
                <label class="mb-2 para18 fw-medium">Description</label>
                <textarea v-model.trim="planDescription" @input="$v.planDescription.$touch()" class="border form-control bg-grey" placeholder="" rows="5"></textarea>
                <div v-if="$v.planDescription.$dirty">
                  <p class="text-danger" v-if="!$v.planDescription.required">
                    Please enter plan description.
                  </p>
                </div>
              </div>
              <!-- <div class="mb-5">
                <label class="mb-2 para18 fw-medium">Plan Price</label>
                <input v-model.trim="planPrice" @input="$v.planPrice.$touch()" type="number" class="border form-control bg-grey" placeholder="">
                <div v-if="$v.planPrice.$dirty">
                  <p class="text-danger" v-if="!$v.planPrice.required">
                    Please enter plan Price.
                  </p>
                  
                </div>
              </div> -->
              <div v-for="(v, index) in $v.planPoints.$each.$iter" :key="index" class="mb-5">
                <label class="mb-2 para18 fw-medium">Point {{++index}}</label>
                <input v-model.trim="v.point.$model" type="text" class="border form-control bg-grey" placeholder="" >
                <div v-if="$v.planPoints.$dirty">
                          
                  <p class="text-danger" v-if="!v.point.required">Point is required.</p>
                  <p class="text-danger" v-if="!v.point.minLength || !v.point.maxLength">Point must have at least {{ v.point.$params.minLength.min }} and {{ v.point.$params.maxLength.max }} letters.</p>
                </div>
              </div>

              <div class="mb-5 text22 fw-medium">Edit Feedback</div>
                <div class="mb-4">
                  <input v-model.trim="feedbackQuestion" @input="$v.feedbackQuestion.$touch()" type="text" class="border form-control bg-grey fw-medium" placeholder="I have a positive attitude towards my future.">
                  <div v-if="$v.feedbackQuestion.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackQuestion.required">Please enter question.</p>
                    <p class="text-danger" v-if="!$v.feedbackQuestion.minLength || !$v.feedbackQuestion.maxLength">
                      The feedback question must be between {{$v.feedbackQuestion.$params.minLength.min}} and {{$v.feedbackQuestion.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                
                <div v-for="(v, indexFeedback) in $v.feedbackOption.$each.$iter" :key="'a'+indexFeedback"  class="mb-5">
                  
                  <label class="mb-2 para18 fw-medium">Option {{++indexFeedback}}</label>
                  <input v-model.trim="v.option.$model" type="text" class="border form-control bg-grey" placeholder="" >
                  <div v-if="$v.feedbackOption.$anyError">
                    <p class="text-danger" v-if="!v.option.required">Please enter option {{indexFeedback}}.</p>
                    <p class="text-danger" v-if="!v.option.minLength || !$v.option.maxLength">The option {{indexFeedback}} must be between {{ v.option.$params.minLength.min }}  and {{v.option.$params.maxLength.max}} characters.</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  
	data() {
		return {
      planName: null,
      planDescription: null,
    //  planPrice: null,
      planPoints: [
        {
          point: '',
        }
      ],
      feedbackQuestion:null,
      feedbackId: null,
      feedbackOption: [
        {
          option: '',
        }
      ],
		};
	},
  validations: {
    planName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350),
    },
    planDescription: {
      required,
    },
    // planPrice: {
    //   required,
    // },
    planPoints: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30),
      $each: {
        point: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        }
      }
    },
    feedbackOption: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30),
      $each: {
        option: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        }
      }
    },
    feedbackQuestion: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350)
    },
  },
  mounted () {
    this.getPlanData();
  },
  methods: {
    ...mapActions("assessment", {
      actionEditPlan: "editPlan",
    }),
    ...mapActions("assessment", {
      actionSingalAssessment: "singalAssessmentData",
    }),
    async getPlanData(){
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      let data ={
        assessmentId: vueInstance.$route.params.assessmentId,
      }
      await vueInstance.actionSingalAssessment(data).then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        const data= req.response
        vueInstance.planName = data.title
        vueInstance.planDescription = data.description
      //  vueInstance.planPrice = data.price
        vueInstance.feedbackQuestion = data.feedback.question
        vueInstance.feedbackId = data.feedback.id
       
        let pointsObj = []
        pointsObj = data.points.map(field => {

          return {
            point: field,
          }
        })
        vueInstance.planPoints = pointsObj

         let FeedbackOptionsObj = []
        FeedbackOptionsObj = data.feedback.options.map(fields => {

          return {
            option: fields,
          }
        })
        vueInstance.feedbackOption = FeedbackOptionsObj
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
    },
    async editPlan(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();
//&& !vueInstance.$v.planPrice.$invalid
      if (!vueInstance.$v.planPoints.$each.$invalid && !vueInstance.$v.planName.$invalid  && !vueInstance.$v.planDescription.$invalid
         && !vueInstance.$v.feedbackQuestion.$invalid  && !vueInstance.$v.feedbackOption.$each.$invalid) { 
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var output = [];
        for(var i = 0; i < vueInstance.planPoints.length; i++){
          output.push(vueInstance.planPoints[i].point)
        }

        var options = [];
        for(var i = 0; i < vueInstance.feedbackOption.length; i++){
          options.push(vueInstance.feedbackOption[i].option)
        }

        await vueInstance.actionEditPlan({
          name: vueInstance.planName,
          assessmentId: vueInstance.$route.params.assessmentId,
          planDescription: vueInstance.planDescription,
        //  planPrice: vueInstance.planPrice,
          points: output,
          feedbackId: vueInstance.feedbackId,
          feedbackQuestion: vueInstance.feedbackQuestion,
          options: options,
        }).then((results) => {
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            if (results.error == true) { 
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.$router.push({ name: "adminPlanList" });
            return false;
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
}
	
	
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
