<template>
  <div class="wrap">
    <div class="inner-small-h-banner">
      <div class="container">
        <h1 class="text-center text-white">{{title.pageTitle}}</h1>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="d-flex justify-content-between mb-5">
            <div></div>
            <div>
              <router-link :to="{ name: title.redirectForm, params:{'form':'form'} }" class="btn btn-primary m-w-auto">{{title.btnTitle}}</router-link>
            </div>
        </div>
        
        <div class="row justify-content-center g-md-5">
          <div class="col-xxl-11" v-if="checkDataExist">
              <div class="row">
					      <div class="col-md msg-list-col">
                  <div v-for="(chatListData, index) in getChatListData.data" :key="index" class="notice-box shadow cursor-pointer" @click.prevent="chatDetails(chatListData.group_id)">
                    
                    <div class="d-flex w-100 justify-content-between mb-3">
                      <div class="d-flex align-items-center">
                        <span class="circle-icon me-3">
                          <i class="fas fa-comments"></i>
                        </span>
                        <h4 class="mb-0" v-html="chatListData.subject"></h4>
                      </div>
                      <small class="text-green" :id="'time'+chatListData.group_id">{{chatListData.created_at | noteDayTimeFormate}} <i class="fas fa-circle"></i></small>
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                      <p class="mb-1" :id="chatListData.group_id" v-html="chatListData.message"></p>
                    </div>
                  </div>
                </div>
                <pagination align="right" :limit=limit size="large" :data="getChatListData" @pagination-change-page="getChat">
                  <span slot="prev-nav">&lt; Previous</span>
                  <span slot="next-nav">Next &gt;</span>
                </pagination>
              
                <div class="col-md-4  chat-col" v-if="chatSection">
                  <div class="chat-box position-relative">
                    <button @click.prevent="chatSection = !chatSection" type="button" class="btn-close" aria-label="Close"></button>
                    <div class="clearfix w-100">
                      <div id="contend" class="chat-content mb-4 w-100" ref="messages">
                        <div v-if="noMoreData" class="text-dark chat-list w-100 bg-grey">No More Data Found</div>
                        <div v-for="chatDetail in getChatDetailsData" :key="chatDetail.id" :class="['d-flex mb-3',(chatDetail.created_by != loginUserId)? 'justify-content-start':'justify-content-end']">
                          <div :class="['chat-list w-75', (chatDetail.created_by != loginUserId)? 'bg-grey':'']">
                            <p :class="(chatDetail.created_by != loginUserId)? 'text-dark':''">{{chatDetail.message}}</p>
                            <div :class="['fw-bold text-end', (chatDetail.created_by != loginUserId)?'text-muted':'' ]">{{chatDetail.created_at | noteDayTimeFormate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="chat-inputs w-100">
                      <div class="d-flex w-100">
                        <input @keyup.enter="enterMessage" :maxlength="maxWord" v-model.trim="chatMessage"  type="text" class="form-control bg-grey me-4" placeholder="Type here..." aria-label="Type" aria-describedby="basic-addon1">
                        <button @click.prevent="enterMessage" class="btn btn-link" type="button" id="button-addon2"><i class="fas fa-paper-plane" style="font-size: 16px;"></i></button>
                      </div>
                      <p class="text-danger" v-if="error">
                        Please enter your message.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  </div>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex'	
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      forScreen:this.$route.params.chatScreen,
      chatMessage:'',
      maxWord: 254,
      getChatListData:[],
      getChatDetailsData:[],
      messageGroupId:null,
      checkDataExist: false,
      chatSection: false,
      loginUserId: null,
      error: false,
      noMoreData: false,
      page:1,
    };
  },
  computed: {
    title(){ 
      if(this.forScreen == 'askCoach'){  
        localStorage.setItem("userHaveAskCoachData", true);
        return {
          pageTitle: "Ask A Coach",
          btnTitle: "Send a Message",
          redirectForm: "askCoach",
        }
        
      }else{
        localStorage.setItem("userHaveSupportRequestData", true);
        return {
          pageTitle: "Support",
          btnTitle: "Contact Support",
          redirectForm: "support",
        }
      }
    },
    ...mapGetters("auth", {
        checkedAuthData: "getAuthData",
    }),
  },
  mounted () {
    this.getChat();
  },
  methods: {
    ...mapActions("auth", {
      actionProfile: "profile",
    }),
    ...mapActions("notificationChat", {
			actionAllChatList: "allChatList",
		}),
    ...mapActions("notificationChat", {
			actionChatDetails: "chatDetails",
		}),
    ...mapActions("notificationChat", {
			actionConversation: "chatConversation",
		}),
    async getChat(page = 1){
      $("html, body").animate({ scrollTop: $(".wrap").offset().top }, "slow");
      let vueInstance = this;
      const token = vueInstance.checkedAuthData.token;  
      vueInstance.loginUserId = JSON.parse(atob(token.split('.')[1])).sub
      vueInstance.getNotificationsData = []
      const data = {
        forScreen: vueInstance.forScreen,
        page:page,
      };
      await vueInstance.actionAllChatList(data).then((req) => {
				
				if(req.error == true){
					vueInstance.flashError(req.message, { timeout: 10000});
          return false;
				}else{
          if(req.response.length <= 0){
            vueInstance.flashError("You don't have any data");
            return false;
          }
          vueInstance.getChatListData = req.response;
		      vueInstance.checkDataExist = true;
        }
				
			}).catch((err) => {
				vueInstance.flashError(err.message, { timeout: 10000});
				return false;
			})
    },
    async chatDetails(groupId){
      const vueInstance = this;
      vueInstance.chatMessage = ''
      vueInstance.noMoreData = false
      vueInstance.page = 1
      vueInstance.getChatDetailsData= []
      const data = {
        forScreen: vueInstance.forScreen,
        page: vueInstance.page,
        groupId:groupId,
      };
      await vueInstance.actionChatDetails(data).then((req) => {
				if(req.error == true){
					vueInstance.flashError(req.message, { timeout: 10000});
          return false;
				}
        $.each(req.response.data, function(key, value) {
          vueInstance.getChatDetailsData.push(value);
        });
        vueInstance.chatSection = true;
        vueInstance.messageGroupId = groupId;
        setTimeout(function() {
          const content = vueInstance.$refs.messages
          content.scrollTop = content.scrollHeight;
            $("#contend").scroll(function(){ 
              if($(this).scrollTop() < 125 && !vueInstance.noMoreData){
                vueInstance.scrollChatDetails();
              }
            });
        }, 100);
			}).catch((err) => {
				vueInstance.flashError(err.message, { timeout: 10000});
				return false;
			})
    },
    async scrollChatDetails(){ 
      const vueInstance = this; 
      vueInstance.page += 1;
      vueInstance.chatMessage = ''
      const data = {
        forScreen: vueInstance.forScreen,
        page: vueInstance.page,
        groupId:vueInstance.messageGroupId,
      };
      await vueInstance.actionChatDetails(data).then((req) => {
		if(req.error == true){
			return false;
		}
        if(req.response.data.length > 0){
          $.each(req.response.data, function(key, value) {
            vueInstance.getChatDetailsData.unshift(value);
          });
        }else{
          vueInstance.noMoreData= true
        }
        
    	})
    },
    async enterMessage(){
      const vueInstance = this;
      const chatMessage=  vueInstance.chatMessage
      const enterMessageGroupId=  vueInstance.messageGroupId
      vueInstance.error = false;
      if (!chatMessage) {
        vueInstance.error = true;
        return false
      }
      $(".btn").prop("disabled", true);
      const data = {
          chatMessage: chatMessage,
          forScreen: vueInstance.forScreen,
          messageGroupId: enterMessageGroupId,
      };
      await vueInstance.actionConversation(data).then(results => {
        
        if(results.error == true){
          $(".btn").prop("disabled", false);
          return false;
        }
        vueInstance.chatMessage = ''
        const currentTime= new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
        $('.chat-content').append(`<div class="d-flex mb-3 justify-content-end">
          <div class="chat-list w-75">
            <p>${chatMessage}</p>
            <div class="fw-bold text-end">${currentTime}</div>
          </div>
        </div>`);
        $(`#${enterMessageGroupId}`).html(chatMessage)
        $(`#time${enterMessageGroupId}`).html(currentTime)
        const content = vueInstance.$refs.messages
        content.scrollTop = content.scrollHeight;
        $(".btn").prop("disabled", false);
      }).catch((err) => {
        return false;
      })
    },
    
  }
}

</script>
<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
</style>