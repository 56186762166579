import axiosInstance from '../../helper/axiosInstance'
const state = () => ({
});
const mutations = {
};
const getters = {
};

const actions = {
    async assessmentStart({ commit }, payload) {
        return axiosInstance.get(`assessments/${payload.assessmentId}/start`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentQuestions({ commit }, payload) {
        return axiosInstance.get(`assessments/${payload.assessmentId}/modules`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async AssessmentAnswerSubmit({ commit }, payload) {
        var moduleId = payload.moduleId;
        var assessmentId = payload.assessmentId;
        return axiosInstance.post(`assessments/${assessmentId}/modules/${moduleId}/submit`, payload.anwser).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async AssessmentCompleted({ commit }, payload) {
        return axiosInstance.get(`assessments/${payload.assessmentId}/congratulation`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
/*    async CheckAssessmentVisible({ commit }, payload) {
        return axiosInstance.get(`assessments`).then(req => {
            let assessmentInfoData = req.data.response.filter(r => r.visibility == true);
            let assessmentId = assessmentInfoData[0].id;
            router.push({ name: payload.namePath ,params: { assessmentId: assessmentId }})
        }).catch(err => {
            throw err;
        })
    },
*/
    async UsercomplatedAssessmentList({ commit }, payload) {
        return axiosInstance.get(`assessments/launch_plans`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentLists({ commit }, payload) {
        return axiosInstance.get(`admin/assessments/lists`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updateAssessmentStatus({ commit }, payload) {
        return axiosInstance.get(`admin/assessments/${payload.assessmentId}/visibility?status=${payload.status}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async deleteAssessment({ commit }, payload) {
        var assessmentId = payload.id;
        return axiosInstance.delete(`admin/assessments/${assessmentId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async allAssessmentList({ commit }, payload) {
        return axiosInstance.get(`admin/assessments`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async singalAssessmentData({ commit }, payload) {
        var assessmentId = payload.assessmentId;
        return axiosInstance.get(`admin/assessments/${assessmentId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async singalModuleData({ commit }, payload) {
        var moduleId = payload.moduleId;
        var assessmentId = payload.assessmentId;
        return axiosInstance.get(`admin/assessments/${assessmentId}/modules/${moduleId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async moduleDelete({ commit }, payload) {
        var moduleId = payload.moduleId;
        var assessmentId = payload.assessmentId;
        return axiosInstance.delete(`admin/assessments/${assessmentId}/modules/${moduleId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addModule({ commit }, payload) {
        return axiosInstance.post(`admin/assessments/${payload.assessmentId}/modules`, {modules:payload.moduleAdd}).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async moduleEdit({ commit }, payload) {
        const assessmentId= payload.assessmentId
        const moduleId= payload.moduleId
        const data = {
            title: payload.titleUpdate,
            description:payload.editDescription,
            slogan:payload.editSlogan,
        }
        return axiosInstance.put(`admin/assessments/${assessmentId}/modules/${moduleId}`, data).then(req => {
            return req.data;
        }).catch(err => {
            console.log(err)
            throw err;
        })
    },
    async questionList({ commit }, payload) {
        var moduleId = payload.moduleId;
        var assessmentId = payload.assessmentId;
        return axiosInstance.get(`admin/assessments/${assessmentId}/modules/${moduleId}/questions`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addQuestionOption({ commit }, payload) {
        const assessmentId= payload.assessmentId
        const moduleId= payload.moduleId
        const question = {
            question: payload.questionAdd,
            options: payload.optionsAdd
        }
        return axiosInstance.post(`admin/assessments/${assessmentId}/modules/${moduleId}/questions`, question).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updatQuestionOption({ commit }, payload) {
        const assessmentId= payload.assessmentId
        const moduleId= payload.moduleId
        const questionId= payload.editQuestionId
        const question = {
            question: payload.questionUpdate,
            options: payload.optionsUpdate
        }
        return axiosInstance.put(`admin/assessments/${assessmentId}/modules/${moduleId}/questions/${questionId}`, question).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addPlan({ commit }, payload) {
        console.log(payload.points)
        const data = {
            title: payload.name,
            description: payload.planDescription,
            price: payload.planPrice,
            points: payload.points,
            assessment_feedback:{
                question: payload.feedbackQuestion,
                options: [payload.feedbackOption1, payload.feedbackOption2, payload.feedbackOption3, payload.feedbackOption4]
            }
        }
        return axiosInstance.post(`admin/assessments`, data).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async editPlan({ commit }, payload) {
        var assessmentId = payload.assessmentId;
        const data = {
            title: payload.name,
            description: payload.planDescription,
            price: payload.planPrice,
            points: payload.points,
            feedback_id: payload.feedbackId,
            question: payload.feedbackQuestion,
            options: payload.options,
        }
        return axiosInstance.put(`admin/assessments/${assessmentId}`, data).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async singalInquiryData({ commit }, payload) {
        return axiosInstance.get(`admin/inquiries/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async replyInquiry({ commit }, payload) {
        const data = {
            group_id: payload.groupId,
            message: payload.answer,
        }
        return axiosInstance.post(`admin/inquiries/conversation`, data).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getSignalArticle({ commit }, payload) {
        return axiosInstance.get(`admin/assessments/${payload.assessmentId}/articles/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async editArticle({ commit }, payload) {
        const payloadData = {
            title: payload.title,
            type: payload.type,
            assessment_id: payload.assessmentId,
            description: payload.content,
        };
        return axiosInstance.put(`admin/articles/${payload.articleId}`, payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addPodcast({ commit }, payload) {
        const payloadData = {
            title: payload.title,
            image: payload.image,
            description: payload.content,
            type: payload.type,
            status: payload.status,
            media: payload.mediaFile,
            access_token: payload.accessToken,
        };
        return axiosInstance.post('admin/podcasts', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getSignalPodcast({ commit }, payload) {
        return axiosInstance.get(`admin/podcasts/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async editPodcast({ commit }, payload) {
        const payloadData = {
            title: payload.title,
            type: payload.type,
            status: payload.status,
            media: payload.mediaUrl,
            description: payload.content,
            id: payload.id,
        };
        return axiosInstance.put(`admin/podcasts/${payload.id}`, payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async coachReplyInquiry({ commit }, payload) {
        const data = {
            group_id: payload.groupId,
            message: payload.answer,
        }
        return axiosInstance.post(`coach/inquiries/conversation`, data).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async coachSingalInquiryData({ commit }, payload) {
        return axiosInstance.get(`coach/inquiries/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentCoachLists({ commit }, payload) {
        return axiosInstance.get(`coach/assessments/lists`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async userAssessmentInfo({ commit }, payload) {
        return axiosInstance.get(`coach/users/${payload.userId}/assessments/${payload.assessmentId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentQuestionAnswer({ commit }, payload) { 
        return axiosInstance.get(`coach/users/${payload.userId}/assessments/${payload.assessmentId}/modules`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async allGetAssessmentComplete({ commit }, payload) {
        return axiosInstance.get(`coach/users/${payload.id}/assessments`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async assessmentCoachLists({ commit }, payload) {
        return axiosInstance.get(`coach/assessments/lists`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getCoachSignalPodcast({ commit }, payload) {
        return axiosInstance.get(`coach/podcasts/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getCoachSignalArticle({ commit }, payload) {
        return axiosInstance.get(`coach/assessments/${payload.assessmentId}/articles/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async uploadImage({ commit }, payload) {
        return axiosInstance.post(`file/upload`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async uploadExercise({ commit }, payload) {
        return axiosInstance.post(`resources-exercise`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async deleteExercise({ commit }, payload) {
        return axiosInstance.delete(`resources-exercise/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
