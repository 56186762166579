<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">Resources</div>
          </div>
          <div class="col-md-4 text-end mb-5">
            <router-link :to="{ name: 'adminArticleAdd',params: {  assessmentId: this.$route.params.assessmentId }  }" class="btn btn-primary text-center btn-rounded">Add New Articles</router-link>
          </div>
        </div>
        <div class="row justify-content-center">
          
          <div class="col-lg-12"> 
        <div class="block-content p-5 bg-white h-auto min-h">
          <flash-message></flash-message>
          <div class="row">
            <div v-for="(articleData,index) in articleListData.data" :key="index" class="col-md-4 mb-5">
              <div class="blog-list-box">
                <div class="img-box position-relative blog-img-box">
                  <router-link :to="{ name: 'adminArticleEdit',params: {  id: articleData.id, assessmentId: articleData.assessment_id } }" class="edit-icon ms-auto position-absolute"><i class="fas fa-pencil-alt"></i></router-link>
                  <img :src="articleData.image" class="img-fluid w-100" alt="Blog">
 
                </div>
                <div class="p-4">
                  <div class="text-green para-sm mb-2">Life |  {{ articleData.created_at | longDate}}</div>
                  <h3 class="text22 fw-medium mb-4"><a href="#" @click.prevent="">{{ articleData.title | strLimit(50)}}</a> </h3>
                  <p class="para-md mb-5">{{ articleData.description | strLimit(75)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
            <div class="text-center pagger mt-5">
              <pagination align="center" :limit=limit size="large" :data="articleListData" @pagination-change-page="getArticleList" />
            </div>
      </div>
      </div>
      </div>
      </section>
  </div>
</template>
<script>
import axiosInstance from '../../../../helper/axiosInstance'

export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      articleListData:{
        type:Object,
        default: null
      },
      checkUserData: false,     
    };
  },
  mounted () {
    this.getArticleList();
  },

  methods: {
    
    async getArticleList(page = 1) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      axiosInstance.get(`admin/assessments/${vueInstance.$route.params.assessmentId}/articles?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }
  
        if(response && Object.keys( response.data.response.data).length > 0)
          vueInstance.articleListData = response.data.response
        else
          vueInstance.flashError('There is no article available', { timeout: 4000 });
      }).catch(error=> { 
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
