<template>
    <div>
        <section class="section bg-grey pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="text22 fw-medium mb-5">Inquiries</div>
                    </div>
                </div>
                <div class="block-content min-h p-4"> <flash-message></flash-message>
                    <div class="table-responsive border-table">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                  <th scope="col">First & Last Name</th>
                                  <th scope="col">Subject</th>
                                  <th scope="col">Message Date</th>
                                  <th scope="col">Plans</th>
                                  <th scope="col"></th>
                                  <th scope="col" width="100">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(inquiryData,index) in inquiryListData.data" :key="index">
                                  <td> {{ inquiryData.user_first_name}}  {{ inquiryData.user_last_name}}</td>
                                  <td>{{ inquiryData.subject}} </td>
                                  <td> {{ inquiryData.created_at | longDate}}</td>
                                  <td>{{ inquiryData.title}}</td>
                                  <td><span class="msg-count"> {{ inquiryData.total_messages}}</span></td>
                                  <td class="text-end" >
                                    <div class="d-flex align-items-center justify-content-center">
                                      <router-link :to="{ name: 'coachInquiryReply',params: { id: inquiryData.id, groupId: inquiryData.group_id } }"  class="me-4"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
                                          <g id="Group_5329" data-name="Group 5329" transform="translate(-1108 -258)">
                                              <circle id="Ellipse_10954" data-name="Ellipse 10954" cx="13.5" cy="13.5" r="13.5" transform="translate(1108 258)" fill="#97c453"/>
                                              <path id="Icon_awesome-reply" data-name="Icon awesome-reply" d="M.345,8.812,7.663,2.493a1,1,0,0,1,1.65.755V6.577c6.678.076,11.974,1.415,11.974,7.744a8.5,8.5,0,0,1-3.465,6.408.741.741,0,0,1-1.167-.775c1.885-6.029-.894-7.63-7.342-7.722v3.655a1,1,0,0,1-1.65.755L.345,10.323a1,1,0,0,1,0-1.51Z" transform="translate(1111.856 260.75)" fill="#fdfeff"/>
                                          </g>
                                          </svg>
                                        </router-link>
                                        <router-link :to="{ name: 'coachInquiryView',params: { groupId: inquiryData.group_id } }" class="btn btn-dark py-1 px-4">
                                          View</router-link>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="checkUserData" colspan="6"> Don't have any inquiry data</tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-center pagger mt-5">
                    <pagination align="center" :limit=limit size="large" :data="inquiryListData" @pagination-change-page="getInquiryList" />
                </div>
            </div>
        </section>    
    </div>
</template>
<script>
import axiosInstance from '../../../helper/axiosInstance'

export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      inquiryListData:{
        type:Object,
        default: null
      },
      checkUserData: false,     
    };
  },
  mounted () {
    this.getInquiryList();
  },

  methods: {
    
    async getInquiryList(page = 1) {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      axiosInstance.get(`coach/inquiries?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }
  
        if(response && Object.keys( response.data.response.data).length > 0)
          vueInstance.inquiryListData = response.data.response
        else
          vueInstance.checkUserData= true
      }).catch(error=> { 
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>


