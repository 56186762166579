import { initializeApp } from "firebase/app";
import { getAuth,signOut , RecaptchaVerifier, signInWithPhoneNumber, } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

    apiKey: "AIzaSyADVJMGHUQQEMU0CyMXESqAwNVnvnJBnWI",
    authDomain: "lifelaunch-c71cb.firebaseapp.com",
    projectId: "lifelaunch-c71cb",
    storageBucket: "lifelaunch-c71cb.appspot.com",
    messagingSenderId: "376764362904",
    appId: "1:376764362904:web:2e950539e36a186f2ce0ed",
    measurementId: "G-RKSVNEPXB0"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp); 
const auth = getAuth(firebaseApp);
const countryCode = '+1'; 
 
export { auth, signOut, RecaptchaVerifier, signInWithPhoneNumber, countryCode };
