<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-5 text22 fw-medium">
              <router-link
                :to="{
                  name: 'assessmentCompleteDetails',
                  params: {
                    userId: userAssementInfo.user_id,
                  },
                }"
                class="d-inline-block me-4 text-dark"
                ><i class="fas fa-arrow-left"></i
              ></router-link>
              Completed Assessment
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
              <div class="mb-4 text-center">
                <div class="mx-auto user-profile-box">
                  <img :src="baseUrl + '/public/images/user-big.svg'" />
                </div>
              </div>
              <div class="text-center">
                {{ userAssementInfo.first_name }}
                {{ userAssementInfo.last_name }}
              </div>
              <div class="text-center text-green">
                {{ userAssementInfo.email }}
              </div>
              <div class="mb-5 text-center">
                {{ userAssementInfo.phoneNo | phoneNoFormate }}
              </div>
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="fw-medium">{{ userAssementInfo.title }}</div>
                </li>
                <li class="list-group-item para-sm">
                  <div class="d-flex justify-content-between">
                    <div class="fw-medium">Module</div>
                    <div class="fw-medium">Score</div>
                  </div>
                </li>
                <li
                  v-for="(listModule, index) in userAssementModules"
                  :key="index"
                  class="list-group-item"
                >
                  <div class="d-flex justify-content-between">
                    <div class="">{{ listModule.title }}</div>
                    <div class="fw-medium text-green">
                      {{ Math.round(listModule.score / 4) }}
                    </div>
                  </div>
                </li>
              </ul>
              <div class="mt-5 text-center">
                <router-link
                  :to="{
                    name: 'planUserQuestionsAnswer',
                    params: {
                      assessmentId: $route.params.assessmentId,
                      userId: $route.params.userId,
                    },
                  }"
                  class="px-5 py-2 btn btn-dark rounded-5 para-md"
                  >View Assessment Answers</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      userAssementInfo: {},
      userAssementModules: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("adminDashboard", {
      actionUserAssessment: "userAssessmentInfo",
    }),

    async getData() {
      let vueInstance = this;
      await vueInstance
        .actionUserAssessment({
          userId: vueInstance.$route.params.userId,
          assessmentId: vueInstance.$route.params.assessmentId,
        })
        .then((results) => {
          if (results.error == true) {
            vueInstance.flashError(results.message, { timeout: 10000 });
            return false;
          }
          const data = results.response;
          vueInstance.userAssementInfo = data.assessment_info;
          vueInstance.userAssementModules = data.modules;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 10000 });
          return false;
        });
    },
  },
};
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
