import axiosInstance from '../../helper/axiosInstance'
const state = () => ({
});
const mutations = {
};
const getters = {
};
const actions = {
    async launchPlans({ commit }, payload) {
        return axiosInstance.get(`assessments/${payload.assessmentId}/launch_plan`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async planModule({ commit }, payload) {
        return axiosInstance.get(`assessments/${payload.assessmentId}/modules/${payload.moduleId}/detail`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async noteSubmit({ commit }, payload) {
        var moduleId = payload.moduleId;
        var assessmentId = payload.assessmentId;
        var dataPlayload = {
            subject:payload.subject,
            comment:payload.message,
        }
        return axiosInstance.post(`assessments/${assessmentId}/modules/${moduleId}/notes`, dataPlayload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async feedbackAnswerSubmit({ commit }, payload) {
        var assessmentId = payload.assessmentId;
        var dataPlayload = {
            assessment_feedback_id:payload.assessmentFeedbackId,
            option:payload.feedbackAnswer,
        }
        return axiosInstance.post(`assessments/${assessmentId}/feedback`, dataPlayload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}