<template>
  	<div>
		<section class="section bg-grey pt-5">
		<div class="container">
			<div class="text22 fw-medium mb-5">Dashboard</div>
			<div class="row">
				<div class="col-md-4 mb-5">
					<div class="block-content">
						<div class="text-end para18 fw-medium mb-3 text-uppercase">Total Users</div>
						<div class="d-flex justify-content-between">
							<div class="user-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="54.271" height="37.99" viewBox="0 0 54.271 37.99">
									<path id="Icon_awesome-users" data-name="Icon awesome-users" d="M8.141,18.531A5.427,5.427,0,1,0,2.714,13.1,5.432,5.432,0,0,0,8.141,18.531Zm37.99,0A5.427,5.427,0,1,0,40.7,13.1,5.432,5.432,0,0,0,46.131,18.531Zm2.714,2.714H43.417a5.411,5.411,0,0,0-3.824,1.577A12.4,12.4,0,0,1,45.961,32.1h5.6a2.711,2.711,0,0,0,2.714-2.714V26.672A5.432,5.432,0,0,0,48.844,21.245Zm-21.709,0a9.5,9.5,0,1,0-9.5-9.5A9.493,9.493,0,0,0,27.136,21.245Zm6.513,2.714h-.7a13.113,13.113,0,0,1-11.617,0h-.7a9.771,9.771,0,0,0-9.769,9.769V36.17a4.071,4.071,0,0,0,4.07,4.07H39.347a4.071,4.071,0,0,0,4.07-4.07V33.727A9.771,9.771,0,0,0,33.648,23.959Zm-18.97-1.136a5.411,5.411,0,0,0-3.824-1.577H5.427A5.432,5.432,0,0,0,0,26.672v2.714A2.711,2.711,0,0,0,2.714,32.1H8.3A12.435,12.435,0,0,1,14.679,22.822Z" transform="translate(0 -2.25)" fill="#fff" />
								</svg>

							</div>
							<div class="number-text">{{totalUsers}}</div>
						</div>

					</div>
				</div>
			</div>	
			<div class="row">	
				<div class="col-md-4 mb-5" v-for="(planData,index) in plansData" :key="index">
					<div class="block-content" >
						<div class="text-end para18 fw-medium mb-3 text-uppercase">{{planData.title}}</div>

						<div class="text-end para18 fw-medium mb-3 text-uppercase">
							<span v-if="planData.status == 'UNPUBLISHED'" class="badge rounded-pill bg-warning text-dark">{{planData.status}}</span>
							<span v-else class="badge rounded-pill publish text-dark" style="color:#publish">{{planData.status}}</span>
						</div>
						<div class="d-flex justify-content-between">
							<div class="user-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="54.271" height="37.99" viewBox="0 0 54.271 37.99">
									<path id="Icon_awesome-users" data-name="Icon awesome-users" d="M8.141,18.531A5.427,5.427,0,1,0,2.714,13.1,5.432,5.432,0,0,0,8.141,18.531Zm37.99,0A5.427,5.427,0,1,0,40.7,13.1,5.432,5.432,0,0,0,46.131,18.531Zm2.714,2.714H43.417a5.411,5.411,0,0,0-3.824,1.577A12.4,12.4,0,0,1,45.961,32.1h5.6a2.711,2.711,0,0,0,2.714-2.714V26.672A5.432,5.432,0,0,0,48.844,21.245Zm-21.709,0a9.5,9.5,0,1,0-9.5-9.5A9.493,9.493,0,0,0,27.136,21.245Zm6.513,2.714h-.7a13.113,13.113,0,0,1-11.617,0h-.7a9.771,9.771,0,0,0-9.769,9.769V36.17a4.071,4.071,0,0,0,4.07,4.07H39.347a4.071,4.071,0,0,0,4.07-4.07V33.727A9.771,9.771,0,0,0,33.648,23.959Zm-18.97-1.136a5.411,5.411,0,0,0-3.824-1.577H5.427A5.432,5.432,0,0,0,0,26.672v2.714A2.711,2.711,0,0,0,2.714,32.1H8.3A12.435,12.435,0,0,1,14.679,22.822Z" transform="translate(0 -2.25)" fill="#fff" />
								</svg>

							</div>
							<div v-if="planData.status != 'UNPUBLISHED'" class="number-text">{{planData.total}}</div>
						</div>

					</div>
				</div>
			</div>
			<div class="row">
				<flash-message></flash-message>
				<div class="col-lg-12 mb-5">
					<div class="block-content">
						<div class="row">
							<div class="col-md-8">
								<div class="para18 fw-medium mb-3 text-uppercase">Recent Registrations</div>
							</div>
							<!-- <div class="col-md-4 text-end mb-3">
								<router-link :to="{ name: 'adminUserList' }" class="btn btn-primary text-center btn-rounded">View All</router-link>
							</div> -->
						</div>
						<div class="table-responsive border-table">
							<table class="table mb-0">

								<thead>
									<tr>
										<th scope="col">First Name</th>
										<th scope="col">Last Name</th>
										<th scope="col">Email Address</th>
										<th scope="col">Phone #</th>
										<th scope="col">Registered Date</th>
										<th scope="col">Role</th>
										<th scope="col">STATUS</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(userListData,index) in usersListData" :key="index">
										<td>{{userListData.first_name}}</td>
										<td>{{userListData.last_name}}</td>
										<td>{{userListData.email}}</td>
										<td>{{userListData.mobile | phoneNoFormate}}</td>
										<td>{{userListData.created_at | longDate}}</td>
										<td>{{userListData.role }}</td>
										<td class="text-end">
											<div class="form-check form-switch">
												<input :checked="userListData.status" @change="changeUserStatus(userListData.id,$event)" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
												<label class="form-check-label" for="flexSwitchCheckDefault"></label>
											</div>
										</td>
									</tr>
									<tr v-if="notFoundUser">No user data available</tr>	
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	</div>
</template>
<script>
import axiosInstance from '../../../helper/axiosInstance'
import { mapActions } from "vuex";
export default {
	
  data() {
    return {
		totalUsers: null,
		plansData:{},
		usersListData:[],
		notFoundUser:false 
    };
  },
  
 created() {
	this.dashboardData();
 },
 methods: {
	...mapActions("adminDashboard", {
      actionDashboard: "dashboard",
    }),
	...mapActions("adminDashboard", {
      actionUpdateStatus: "updateStatus",
    }),
	async dashboardData(){
		let vueInstance = this;
		vueInstance.notFoundUser = false
		let options = { 
			okText: 'Close',
			animation: 'bounce',
			type: 'basic',
			backdropClose: true, 
		};
		await vueInstance.actionDashboard().then((req) => {
			
			if(req.error == true){
				vueInstance.$dialog.alert(req.message,options)
				return false;
			}
			vueInstance.totalUsers = req.response.totalUsersCounts;
			vueInstance.plansData = req.response.planWiseCounts;
			if(req.response.latestUsers.length < 1)
			{
				vueInstance.notFoundUser = true
				return false
			}
			vueInstance.usersListData = req.response.latestUsers;
		}).catch((err) => {
			vueInstance.$dialog.alert(err.message,options)
			return false;
		})
		
	},
	async changeUserStatus(userId, event){ 
		$(".form-check-input").prop("disabled", true);
		var vueInstance = this;
		let value = (event.target.checked == true)?1:0;        
		$(".flash__wrapper").empty();
		let options = { 
			okText: 'Close',
			animation: 'bounce',
			type: 'basic',
			backdropClose: true, 
		};
		const data = {
			id: userId,
			status: value
		}

      	await vueInstance.actionUpdateStatus(data).then((req) => { 
			$(".form-check-input").prop("disabled", false);
		  	if(req.error == true){ 
				vueInstance.$dialog.alert(req.message,options)
				return false;
			}
			vueInstance.flashSuccess(req.message, { timeout: 3000 });
			
        }).catch((err) => { 
			vueInstance.$dialog.alert(err.message,options)
			$(".form-check-input").prop("disabled", false);
			return false;
		});
    },
 },
}

</script>
<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
.publish {
	color:white !important;
	background:#88C837 !important;
}
</style>