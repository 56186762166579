<template>
  <div>
    <nav
      v-if="checkedAuthData.token"
      class="navbar navbar-expand-lg navbar-light bg-white after-login"
    >
      <div class="container position-relative">
        <router-link
          :to="{ name: 'home' }"
          class="navbar-brand"
          @click.prevent=""
        >
          <img
            :src="baseUrl + '/public/images/logo.png'"
            alt="Life launch Consulting"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <!-- <div class="d-lg-none">
          <router-link :to="{ name: 'coachNotification' }" class="notification text-green">
            <i class="fas fa-bell"></i>
          </router-link>
          <router-link :to="{ name: 'coachInfo' }" active-class="active"  class="login-link">
            <img :src="baseUrl+'/public/images/pic.svg'" class="user-pic">
             {{fullName}}
         </router-link>
      </div> -->
        <!-- <div class="navbar-text">
        <router-link :to="{ name: 'coachNotification' }" class="notification text-green">
          <i class="fas fa-bell"></i>
        </router-link>
        <router-link :to="{ name: 'coachInfo' }" active-class="active"  class="login-link">
          <img :src="baseUrl+'/public/images/pic.svg'" class="user-pic">
             {{fullName}}
          </router-link>
      </div> -->
        <div
          class="collapse navbar-collapse position-static"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav mx-auto mt-lg-5 pt-lg-2 me-2">
            <!--
           <li class="dropdown nav-item" >
            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
              Assessment List
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li v-for="(assessmentLists,index) in assessmentListData" :key="index">

                <router-link :to="{ name: 'coachLaunchPlanList' ,params: { id: assessmentLists.id, title:assessmentLists.title  }}" class="dropdown-item">
                  {{assessmentLists.title}}
                </router-link>
              </li>
            </ul>
           </li>
          -->
            <li class="nav-item">
              <router-link :to="{ name: 'coachUserList' }" class="nav-link"
                >Clients</router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'coachResourcesList' }" class="nav-link"
                >Resources + Exercises</router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'coachInquiryList' }" class="nav-link"
                >Inquiries</router-link
              >
            </li>
            <li class="nav-item" @click.prevent="logout">
              <a class="nav-link" href="#">Logout</a>
            </li>

            <li class="dropdown nav-item">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </a>
              <ul
                class="dropdown-menu under-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li class="p-2">
                  <router-link
                    :to="{ name: 'coachNotification' }"
                    class="dropdown-item"
                  >
                    <i class="fas fa-bell notification text-green"></i>
                    Notification
                  </router-link>
                </li>
                <li class="p-2">
                  <router-link
                    :to="{ name: 'coachInfo' }"
                    class="dropdown-item"
                  >
                    <img
                      :src="baseUrl + '/public/images/pic.svg'"
                      class="user-pic"
                    />
                    {{ fullName }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import jwt_decode from "jwt-decode";
export default {
  props: ["checkedAuthData"],
  data() {
    return {
      baseUrl: configObj.baseUrl,
      // assessmentListData: {},
    };
  },
  computed: {
    fullName() {
      const getLocalToken = this.$store.state.auth.authData.token;
      if (getLocalToken) {
        this.actionAssessmentCoachLists().then((req) => {
          if (req.error == true) return false;
          // this.assessmentListData = req.response;
        });
        const roleInToken = jwt_decode(getLocalToken);
        return roleInToken.first_name + " " + roleInToken.last_name;
      }
    },
  },
  methods: {
    ...mapActions("assessment", {
      actionAssessmentCoachLists: "assessmentCoachLists",
    }),
    /*
    async getAssessmentList() {
      let vueInstance = this;

      await vueInstance.actionAssessmentCoachLists().then((req) => {
        if (req.error == true) return false;
        vueInstance.assessmentListData = req.response;
      });
    },
*/
    async logout() {
      this.$store.dispatch("auth/logoutCoach");
    },
  },
};
</script>
<style scoped>
.dropdown-item {
  font-size: 1.6rem;
}
.under-menu {
  right: 0 !important;
  left: auto !important;
}
</style>
