<template>
  <div class="section bg-green bg-green-white position-relative main">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-6 col-xl-5">
          <div class="white-box">
            <div class="text-center mb-5">
              <img :src="baseUrl + '/public/images/logo.svg'" />
            </div>
            <h3 class="text-center text-green fw-medium mb-5 text28">
              Verify Information
            </h3>
            <p class="text-center mb-5 text-green">Enter your code here</p>

            <!-- <div class="mb-4">
              <label for="exampleInputPassword1" class="form-label d-none">Enter OTP</label>
              <div class="input-group mb-3" id="show_hide_password2">
                <input maxlength="4" :type="passwordFieldType" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model.trim="code" @input="$v.code.$touch()"  class="form-control otp-input" placeholder="Enter OTP" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <span @click.prevent="switchVisibility" class="input-group-text" id="basic-addon3"><a href="#" class="eye"><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i></a> </span>
              </div>
              <div v-if="$v.code.$dirty">
                <p class="text-danger" v-if="!$v.code.required"> Please enter code.</p>
                <p class="text-danger" v-if="!$v.code.maxLength"> Code must have at most {{$v.code.$params.maxLength.max}} digits.</p>
              </div>
            </div> -->
            <div class="row g-5 text-center mb-5">
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code1"
                  @input="$v.code1.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                  autofocus
                />
              </div>
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code2"
                  @input="$v.code2.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                />
              </div>
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code3"
                  @input="$v.code3.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                />
              </div>
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code4"
                  @input="$v.code4.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                />
              </div>
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code5"
                  @input="$v.code5.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                />
              </div>
              <div class="col-2">
                <input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  v-model.trim="code6"
                  @input="$v.code6.$touch()"
                  class="code-input"
                  type="text"
                  maxlength="1"
                />
              </div>
            </div>

            <br />
            <div v-if="error" v-html="error">{{ error }}</div>
            <br />
            <div class="text-center mb-5">
              <button
                @click.prevent="verifiedSubmit"
                type="submit"
                class="btn btn-primary text-center btn-rounded"
              >
                Submit
              </button>
            </div>
            <div id="recaptcha-container"></div>
          </div>
        </div>
      </div>
    </div>
    <modalOTP :modalMsg="modalMsg" />
  </div>
</template>

<script>
import * as firebase from "../../../firebaseConfig";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import modalOTP from "./modalOTP";
export default {
  components: { modalOTP },
  data() {
    return {
      baseUrl: configObj.baseUrl,
      existUserId: JSON.parse(localStorage.getItem("userLogData")).id,
      otpMobileNo: JSON.parse(localStorage.getItem("userLogData")).mobile,
      modalMsg:
        "We have sent you a one-time passcode on your registered phone number",
      spinnerShow: false,
      //  code: null,
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      code5: null,
      code6: null,
      error: null,
      //  passwordFieldType: "password",
      pwIcon: false,
      error: null,

      countryCode: firebase.countryCode,
      appVerifier: '',
      confirmResult: "",
    };
  },
  validations: {
    code1: {
      required,
    },
    code2: {
      required,
    },
    code3: {
      required,
    },
    code4: {
      required,
    },
    code5: {
      required,
    },
    code6: {
      required,
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("userLogData");
    localStorage.removeItem("userData");
    return next();
  },
  created() {
    if (this.otpMobileNo) this.sendingOTP();
  },
  mounted() {
    $("input").keyup(function () {
      var $this = $(this);
      setTimeout(function () {
        if ($this.val().length >= parseInt($this.attr("maxlength"), 10))
          $this.parent().next(".col-2").find("input").focus();
      }, 0);
    });
  },
  methods: {
    // ...mapActions("auth", {
    //   actionCheckOTP: "otpVerify",
    // }),
    // ...mapActions("auth", {
    //   actionSendOTP: "sendOTP",
    // }),
    ...mapActions("auth", {
      actionLogin: "login",
    }),
    async sendingOTP() {
        let vueInstance = this;
        setTimeout(function() {
            window.recaptchaVerifier = new firebase.RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    },
                },
                firebase.auth
                );
            vueInstance.appVerifier = window.recaptchaVerifier;

            vueInstance.sendOTP();
        },1000);
    },
    async sendOTP() {
        let vueInstance = this;
        vueInstance.error = null;
        vueInstance.code1 = null;
        vueInstance.code2 = null;
        vueInstance.code3 = null;
        vueInstance.code4 = null;
        vueInstance.code5 = null;
        vueInstance.code6 = null;
        setTimeout(function() {

            var phoneNumber = vueInstance.countryCode+vueInstance.otpMobileNo;
            var appVerifier = vueInstance.appVerifier;

            firebase.signInWithPhoneNumber(firebase.auth, phoneNumber, appVerifier).then(confirmationResult => {
                vueInstance.confirmResult = confirmationResult;
                $("#myModal").modal("show");
            }).catch(error => {
                console.log(error);
                localStorage.removeItem("userData");
                localStorage.removeItem("userLogData");
                vueInstance.flashError('Too many requests.Please try again later.', { timeout: 10000 });
                vueInstance.$router.push({ name: "adminLogin" });
                return false;
            });
        },1000);
        // 9752112112
    },

    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var code =
          vueInstance.code1 +
          vueInstance.code2 +
          vueInstance.code3 +
          vueInstance.code4 +
          vueInstance.code5 +
          vueInstance.code6;

        vueInstance.confirmResult.confirm(code).then((result)=>{
            if (vueInstance.existUserId) vueInstance.login();
            firebase.signOut(firebase.auth)
        }).catch((err)=>{
            vueInstance.error = `<p class='alert alert-danger'>Invalid verification code<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
        })
      }
    },
/*
    async sendOTP() {
      let vueInstance = this;
      vueInstance.error = null;
      await vueInstance
        .actionSendOTP({
          mobileNo: vueInstance.otpMobileNo,
        })
        .then((results) => {
          if (results.error == true) {
            localStorage.removeItem("userData");
            localStorage.removeItem("userLogData");
            vueInstance.flashError(results.message, { timeout: 10000 });
            //  vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.$router.push({ name: "adminLogin" });
            return false;
          } else {
            $("#myModal").modal("show");
          }
        });
    },
    async verifiedSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var code =
          vueInstance.code1 +
          vueInstance.code2 +
          vueInstance.code3 +
          vueInstance.code4;
        await vueInstance
          .actionCheckOTP({
            mobileNo: vueInstance.otpMobileNo,
            code: code,
          })
          .then((results) => {
            if (results.error == false) {
              if (vueInstance.existUserId) vueInstance.login();
            } else {
              vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
*/
    async login() {
      let vueInstance = this;
      vueInstance.error = null;
      var userName = JSON.parse(localStorage.getItem("userData")).user_name;
      var password = JSON.parse(localStorage.getItem("userData")).password;
      vueInstance
        .actionLogin({
          userName: userName,
          password: password,
        })
        .then((results) => {
          if (results.error == false) {
            vueInstance.$router.push({ name: "adminDashboard" });
            return false;
          } else {
            vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          }
        })
        .catch((err) => {
          vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
    },
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
