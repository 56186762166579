<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">{{$route.params.title}}</div>
          </div>
        </div>

        <div class="block-content min-h p-4">
          <div class="table-responsive border-table">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Plan Date</th>
                  <th scope="col">First & Last Name</th>
                  <th scope="col" width="100">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(planListModule,index) in planListData.data" :key="index">
                  <td>{{planListModule.id}}</td>
                  <td>{{planListModule.completed_at | longDate}}</td>
                  <td>{{planListModule.first_name}} {{planListModule.last_name}}</td>
                  <td>
                    <router-link :to="{ name: 'userAssessmentInfo',params: { assessmentId: planListModule.assessment_id, userId: planListModule.user_id} }" class="btn btn-dark py-1 px-4"
                      >View</router-link>
                  </td>
                </tr>
                <tr class="justify-content-center" colspan="4" v-if="checkUserData">Data not found</tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center pagger mt-5">
         <pagination align="center" :limit=limit size="large" :data="planListData" @pagination-change-page="getPlanList" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axiosInstance from "../../../helper/axiosInstance";

export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
        return limit - 1;
      },
    },
  },
  data() {
    return {
      planListData: {
        type: Object,
        default: null,
      },
      checkUserData: false,
    };
  },
  mounted() {
    this.getPlanList();
  },

  methods: {
    async getPlanList(page = 1) { 
      let vueInstance = this;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      axiosInstance.get(`coach/assessments/${vueInstance.$route.params.id}?page=${page}`).then(response => {
        if (response.error == true) {
          vueInstance.$dialog.alert(response.message, options);
          return false;
        }

        if (response && Object.keys(response.data.response.data).length > 0){
          vueInstance.planListData = response.data.response;
        }
        else vueInstance.checkUserData = true;
      })
      .catch((error) => {
        vueInstance.flashError(error.message, { timeout: 10000 });
      });
    },
  },
};
</script>


