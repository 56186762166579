<template>
  <div class="wrap">
    <div class="inner-small-h-banner bg-grey pb-0">
      <div class="container">
        <h1 class="text-center mb-5">Resources</h1>
        <ul
          class="nav nav-tabs justify-content-center blog-tabs border-bottom-0"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="Retirement-Articles"
              aria-selected="true"
            >
              Retirement Articles
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="Retirement-Podcast"
              aria-selected="false"
            >
              Retirement Podcast
            </button>
          </li>
        </ul>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row justify-content-center g-md-5">
          <div class="col-xxl-11">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="Retirement-Articles-tab"
              >
                <div class="col-md-12 text-end mb-5">
                  <router-link
                    :to="{
                      name: 'uploadedExerciseList',
                    }"
                    class="btn text-center btn-rounded btn-dark btn-primary"
                    >Uploaded Exercise</router-link
                  >
                </div>
                <div class="row g-5">
                  <flash-message></flash-message>
                  <div
                    class="col-md-6 mb-5"
                    v-for="(article, index) in articleData.data"
                    :key="index"
                  >
                    <div
                      class="blog-list-box cursor-pointer"
                      @click.prevent="articleDetails(article.id)"
                    >
                      <div class="img-box">
                        <img
                          :src="article.image"
                          class="img-fluid w-100"
                          :alt="article.type"
                        />
                      </div>
                      <div class="p-4">
                        <div class="text-green para-sm mb-2">
                          Life | {{ article.created_at | longDate }}
                        </div>
                        <h3 class="heading22 fw-medium mb-4">
                          <a href="#">{{ article.title | strLimit(50) }}</a>
                        </h3>
                        <p class="para-md mb-5">
                          {{ article.description | strLimit(75) }}
                        </p>
                        <div class="avatar para-sm">
                          <img src="" class="me-3" />
                          {{ article.created_by_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-5" v-if="checkArticleData">
                    No article available
                  </div>
                  <pagination
                    align="right"
                    :limit="limit"
                    size="large"
                    :data="articleData"
                    @pagination-change-page="getArticle"
                  >
                    <span slot="prev-nav">&lt; Previous</span>
                    <span slot="next-nav">Next &gt;</span>
                  </pagination>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="Retirement-Podcast-tab"
              >
                <resources-podcasts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from "../../../helper/axiosInstance";
import resourcesPodcasts from "./resourcesPodcasts .vue";

export default {
  components: { resourcesPodcasts },
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
        return limit - 1;
      },
    },
  },
  data() {
    return {
      articleData: {},
      checkArticleData: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "podcastDetail") {
      return next();
    }
    localStorage.removeItem("podCastToken");
    return next();
  },
  mounted() {
    this.getArticle();
  },

  methods: {
    async getArticle(page = 1) {
      let vueInstance = this;
      await axiosInstance
        .get("articles?page=" + page)
        .then((response) => {
          if (response && Object.keys(response.data.response.data).length > 0)
            this.articleData = response.data.response;
          else
            vueInstance.flashError("No Articles Available", { timeout: 3000 });
        })
        .catch((error) => {
          vueInstance.flashError(error.message, { timeout: 3000 });
        });
    },
    articleDetails(articleId) {
      localStorage.setItem("detailsId", articleId);
      //this.$router.push({ name: "articleDetail",params: { articleId: articleId } });
      this.$router.push({ name: "articleDetail" });
    },
  },
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
