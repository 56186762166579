var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"pt-5 section bg-grey"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"p-5 bg-white block-content min-h"},[_c('div',{staticClass:"mt-5 row justify-content-center"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"pills-home","role":"tabpanel","aria-labelledby":"pills-home-tab"}},[_c('div',{staticClass:"table-responsive border-table"},[_c('table',{staticClass:"table mb-0"},[_vm._m(2),_vm._v(" "),_c('tbody',_vm._l((_vm.assessmentListData),function(assessmentLists,indexKey){return _c('tr',{key:indexKey},[_c('td',{staticClass:"text-center"},[_vm._v("\n                            "+_vm._s(assessmentLists.title)+"\n                          ")]),_vm._v(" "),_c('td',{staticClass:"text-end"},[_c('div',{staticClass:"\n                                d-flex\n                                align-items-center\n                                justify-content-center\n                              "},[_c('router-link',{staticClass:"btn btn-primary btn-white btn-lg",attrs:{"to":{
                                  name: 'coachResourcesArticle',
                                  params: {
                                    assessmentId: assessmentLists.id,
                                  },
                                }}},[_vm._v("\n                                "+_vm._s(assessmentLists.title)+" Articles\n                              ")])],1)])])}),0)])])]),_vm._v(" "),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"pills-profile","role":"tabpanel","aria-labelledby":"pills-profile-tab"}},[_c('div',{staticClass:"h-auto p-5 bg-white block-content"},[_c('div',{staticClass:"row"},_vm._l((_vm.podcastListData.episodes),function(podcastData,index){return _c('div',{key:index,staticClass:"mb-5 col-md-4"},[_c('div',{staticClass:"blog-list-box"},[_c('div',{staticClass:"img-box position-relative blog-img-box"},[_c('router-link',{staticClass:"edit-icon ms-auto position-absolute",attrs:{"to":{
                                name: 'coachPodcastEdit',
                                params: { id: podcastData.id },
                              }}},[_c('i',{staticClass:"fas fa-eye"})]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                                name: 'coachPodcastEdit',
                                params: { id: podcastData.id },
                              }}},[_c('img',{staticClass:"img-fluid w-100",attrs:{"src":podcastData.logo,"alt":"Blog"}})])],1),_vm._v(" "),_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mb-2 para-sm text-green"},[_vm._v("\n                                Life |\n                                "+_vm._s(_vm._f("longDateSec")(podcastData.publish_time))+"\n                              ")]),_vm._v(" "),_c('div',{staticClass:"mb-2 para-sm text-green"},[_vm._v("\n                                "+_vm._s(_vm._f("convertSecToMin")(podcastData.duration))+"\n                              ")])]),_vm._v(" "),_c('h3',{staticClass:"mb-4 text22 fw-medium"},[_c('router-link',{attrs:{"to":{
                                  name: 'coachPodcastEdit',
                                  params: { id: podcastData.id },
                                }},domProps:{"innerHTML":_vm._f("strLimit")(podcastData.title,20)}})],1),_vm._v(" "),_c('p',{staticClass:"mb-2 para-md",domProps:{"innerHTML":_vm._f("strLimit")(podcastData.content,30)}})])])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-5 text-center pagger"},[_c('pagination',{attrs:{"align":"center","limit":_vm.limit,"size":"large","data":_vm.podcastListData},on:{"pagination-change-page":_vm.getPodcastList}})],1)])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"mb-5 text22 fw-medium"},[_vm._v("Resources")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"mb-5 nav nav-pills justify-content-center tabs-links",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link active",attrs:{"id":"pills-home-tab","data-bs-toggle":"pill","data-bs-target":"#pills-home","type":"button","role":"tab","aria-controls":"pills-home","aria-selected":"true"}},[_vm._v("\n                    Articles\n                  ")])]),_vm._v(" "),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"pills-profile-tab","data-bs-toggle":"pill","data-bs-target":"#pills-profile","type":"button","role":"tab","aria-controls":"pills-profile","aria-selected":"false"}},[_vm._v("\n                    Podcast\n                  ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("\n                            Assessment Name\n                          ")]),_vm._v(" "),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Action")])])])}]

export { render, staticRenderFns }