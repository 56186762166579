<template>
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5"><router-link :to="{ name: 'adminPlanList' }" class="d-inline-block me-4 text-dark"><i class="fas fa-arrow-left"></i></router-link> Add Plans</div>
          </div>
          <div class="col-md-4 text-end mb-5">
            <button @click.prevent="addPlan" type="button" class="btn btn-primary text-center btn-rounded">Save</button>
          </div>

        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12"><flash-message></flash-message>
            <div class="block-content p-5 bg-white min-h">
              <div class="mb-5">
                <label class="para18 fw-medium mb-2">Plan Name</label>
                <input v-model.trim="planName" @input="$v.planName.$touch()" type="text" class="form-control bg-grey border" placeholder="">
                <div v-if="$v.planName.$dirty">
                  <p class="text-danger" v-if="!$v.planName.required">
                    Please enter plan name.
                  </p>
                  <p class="text-danger" v-if="!$v.planName.minLength || !$v.planName.maxLength">Plan name must have at least {{ $v.planName.$params.minLength.min }} and {{ $v.planName.$params.maxLength.max }} letters.</p>
                </div>
              </div>
              <div class="mb-5">
                <label class="para18 fw-medium mb-2">Plan Description</label>
                <input v-model.trim="planDescription" @input="$v.planDescription.$touch()" type="text" class="form-control bg-grey border" placeholder="">
                <div v-if="$v.planDescription.$dirty">
                  <p class="text-danger" v-if="!$v.planDescription.required">
                    Please enter plan description.
                  </p>
                </div>
              </div>
              <!-- <div class="mb-5">
                <label class="para18 fw-medium mb-2">Plan Price</label>
                <input v-model.trim="planPrice" @input="$v.planPrice.$touch()" type="number" class="form-control bg-grey border" placeholder="">
                <div v-if="$v.planPrice.$dirty">
                  <p class="text-danger" v-if="!$v.planPrice.required">
                    Please enter plan Price.
                  </p>
                  
                </div>
              </div> -->
              <div v-for="(v, index) in $v.planPoints.$each.$iter" :key="index" class="mb-5 field-list">

                <label class="para18 fw-medium mb-2">Description Points</label>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <input v-model.trim="v.point.$model" type="text" class="form-control bg-grey border" placeholder="">
                    <div v-if="$v.planPoints.$dirty">
                      
                      <p class="text-danger" v-if="!v.point.required">Description Point is required.</p>
                      <p class="text-danger" v-if="!v.point.minLength || !v.point.maxLength">Point must have at least {{ v.point.$params.minLength.min }} and {{ v.point.$params.maxLength.max }} letters.</p>
                    </div>
                  </div>
                  
                  <div class="flex-shrink-1 ms-4">
                    <a v-if="planPoints.length <= ++index" @click.prevent="planPoints.push({point: ''})" href="#" class="addMore"><svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                        <g id="add" transform="translate(-3.375 -3.375)">
                          <path id="Path_2295" data-name="Path 2295" d="M25.523,19.477H19.477v6.047H16.523V19.477H10.477V16.523h6.047V10.477h2.953v6.047h6.047Z" fill="#8ac73c"/>
                          <path id="Path_2296" data-name="Path 2296" d="M18,6.328A11.667,11.667,0,1,1,9.745,9.745,11.623,11.623,0,0,1,18,6.328m0-2.953A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" fill="#8ac73c"/>
                        </g>
                      </svg>
                    </a>
                    <a href="#" v-else @click.prevent="planPoints.splice(--index, 1)" class="remove"><i class="far fa-times-circle" aria-hidden="true"></i></a>
                  </div>
                </div>
              </div>

              <div class="text22 fw-medium mb-5">Add Feedback</div>
                <div class="mb-4">
                  <input v-model.trim="feedbackQuestion" @input="$v.feedbackQuestion.$touch()" type="text" class="form-control bg-grey border fw-medium" placeholder="I have a positive attitude towards my future.">
                  <div v-if="$v.feedbackQuestion.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackQuestion.required">Please enter question.</p>
                    <p class="text-danger" v-if="!$v.feedbackQuestion.minLength || !$v.feedbackQuestion.maxLength">
                      The fquestion must be between {{$v.feedbackQuestion.$params.minLength.min}} and {{$v.feedbackQuestion.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="feedbackOption1" @input="$v.feedbackOption1.$touch()" type="text" class="form-control bg-grey border " placeholder="No not at all	">
                  <div v-if="$v.feedbackOption1.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackOption1.required">Please enter option 1.</p>
                    <p class="text-danger" v-if="!$v.feedbackOption1.minLength || !$v.feedbackOption1.maxLength">
                      The options 1 must be between {{$v.feedbackOption1.$params.minLength.min}} and {{$v.feedbackOption1.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="feedbackOption2" @input="$v.feedbackOption2.$touch()" type="text" class="form-control bg-grey border " placeholder="I am slightly concerned	">
                  <div v-if="$v.feedbackOption2.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackOption2.required">Please enter option 2.</p>
                    <p class="text-danger" v-if="!$v.feedbackOption2.minLength || !$v.feedbackOption2.maxLength">
                      The option 2 must be between {{$v.feedbackOption2.$params.minLength.min}} and {{$v.feedbackOption2.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="feedbackOption3" @input="$v.feedbackOption3.$touch()" type="text" class="form-control bg-grey border " placeholder="I am neutral	">
                  <div v-if="$v.feedbackOption3.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackOption3.required">Please enter option 3.</p>
                    <p class="text-danger" v-if="!$v.feedbackOption3.minLength || !$v.feedbackOption3.maxLength">
                      The option 3 must be between {{$v.feedbackOption3.$params.minLength.min}} and {{$v.feedbackOption3.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <input v-model="feedbackOption4" @input="$v.feedbackOption4.$touch()" type="text" class="form-control bg-grey border " placeholder="I am slightly optimistic		">
                  <div v-if="$v.feedbackOption4.$dirty">
                    <p class="text-danger" v-if="!$v.feedbackOption4.required">Please enter option 4.</p>
                    <p class="text-danger" v-if="!$v.feedbackOption4.minLength || !$v.feedbackOption4.maxLength">
                      The option 4 must be between {{$v.feedbackOption4.$params.minLength.min}} and {{$v.feedbackOption4.$params.maxLength.max}} characters.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
	data() {
		return {
      planName: null,
      planDescription: null,
    //  planPrice: null,
      planPoints: [
        {
          point: '',
        }
      ],
      feedbackQuestion:null,
      feedbackOption1:null,
      feedbackOption2:null,
      feedbackOption3:null,
      feedbackOption4:null,
		};
	},
  validations: {
    planName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350),
    },
    planDescription: {
      required,
    },
    // planPrice: {
    //   required,
    // },
    planPoints: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30),
      $each: {
        point: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        }
      }
    },
    feedbackQuestion: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(350)
    },
    feedbackOption1: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    feedbackOption2: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    feedbackOption3: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    feedbackOption4: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    
  },
  methods: {
    ...mapActions("assessment", {
      actionAddPlan: "addPlan",
    }),
    
    async addPlan(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();
//&& !vueInstance.$v.planTitle.$invalid
//&& !vueInstance.$v.planPrice.$invalid
      if (!vueInstance.$v.planPoints.$each.$invalid && !vueInstance.$v.planName.$invalid  && !vueInstance.$v.planDescription.$invalid ) { 
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var output = [];
        for(var i = 0; i < vueInstance.planPoints.length; i++){
          output.push(vueInstance.planPoints[i].point)
        }

        await vueInstance.actionAddPlan({
          name: vueInstance.planName,
          planDescription: vueInstance.planDescription,
        //  planPrice: vueInstance.planPrice,
          points: output,
          feedbackQuestion: vueInstance.feedbackQuestion,
          feedbackOption1: vueInstance.feedbackOption1,
          feedbackOption2: vueInstance.feedbackOption2,
          feedbackOption3: vueInstance.feedbackOption3,
          feedbackOption4: vueInstance.feedbackOption4,
        }).then((results) => {
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            if (results.error == true) { 
              vueInstance.flashError(results.message, { timeout: 4000 });
              return false
            }
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.$router.push({ name: "adminPlanList" });
            return false;
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });
      }
    },
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
