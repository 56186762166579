<template> 
  <div>
    <section class="section bg-grey pt-5">
      <div class="container">

        <div class="row">
          <div class="col-md-8">
            <div class="text22 fw-medium mb-5">All Admin</div>
          </div>
          <div class="col-md-4 text-end mb-3">
            <router-link :to="{ name: 'adminAddSubAdmin' }" class="btn btn-primary text-center btn-rounded">Create A Sub Admin User</router-link>
          </div>
        </div>

        <div class="block-content min-h p-4"> <flash-message></flash-message>
          <div class="table-responsive border-table">
            <table class="table mb-0">

              <thead>
              <tr>
                <th scope="col">Add on</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email Address</th>
                <th scope="col">Phone #</th>
                <th scope="col" width="100">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(subAdminList,index) in subAdminListData.data" :key="index">
                <td>{{ subAdminList.created_at | longDate}}</td>
                <td>{{ subAdminList.first_name}}</td>
                <td>{{ subAdminList.last_name}}</td>
                <td>{{ subAdminList.email}}</td>
                <td>{{ subAdminList.mobile | phoneNoFormate}}</td>
                <td class="text-end" >
                  <div class="d-flex align-items-center justify-content-center">
                    <router-link :to="{ name: 'adminSubAdminInfo',params: { id: subAdminList.id } }"  class="edit-icon me-4"><i class="fas fa-pencil-alt"></i></router-link>
                    <div class="d-flex me-4">
                      <div class="form-check form-switch">
                        <input :checked="subAdminList.status" @change="changeAdminStatus(subAdminList.id,$event)" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </div>
                  <a href="#" @click.prevent="deleteAdmin(subAdminList.id, index)" class="btn btn-danger">Delete</a>
                  </div>
                </td>

              </tr>
              <tr v-if="checkData">No data available</tr>
              </tbody>
              </table>
            </div>

        </div>
        <div class="text-center pagger mt-5">
          <pagination align="center" :limit=limit size="large" :data="subAdminListData" @pagination-change-page="getsubAdminList" />
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from '../../../../helper/axiosInstance'
import { mapActions } from "vuex";
import { Role } from '../../../../helper/role'
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
      coerce: function (limit) {
          return limit - 1;
      }
    }
	},
  data() {
    return {
      subAdminListData:{
        type:Object,
        default: null
      },
      checkData: false,
    };
  },
  mounted () {
    if(this.$store.state.auth.authData.loggedInRole == Role.SubAdmin)
        this.$router.push({ name: "adminDashboard" });
    this.getsubAdminList();
  },

  methods: {
    ...mapActions("adminDashboard", {
      actionDeleteUser: "deleteUser",
    }),
    ...mapActions("adminDashboard", {
      actionUpdateStatus: "updateStatus",
    }),
    async deleteAdmin(userId, index) { 
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      vueInstance.$dialog.confirm('Do you want to delete?').then(function(dialog) {
        
        vueInstance.actionDeleteUser({id : userId}).then((req) => {
			
          if(req.error == true){
            vueInstance.$dialog.alert(req.message,options);
            return false;
          }
          vueInstance.subAdminListData.data.splice(index, 1);
          vueInstance.flashSuccess(req.message, { timeout: 3000 });
        }).catch((err) => {
          vueInstance.$dialog.alert(err.message,options)
          return false;
        })
      })  
	  },
    async changeAdminStatus(userId, event){ 
      $(".form-check-input").prop("disabled", true);
      var vueInstance = this;
      let value = (event.target.checked == true)?1:0;        
      $(".flash__wrapper").empty();
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      const data = {
        id: userId,
        status: value
      }

      await vueInstance.actionUpdateStatus(data).then((req) => { 
        $(".form-check-input").prop("disabled", false);
          if(req.error == true){ 
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.flashSuccess(req.message, { timeout: 3000 });
			
      }).catch((err) => { 
			  vueInstance.$dialog.alert(err.message,options)
			  $(".form-check-input").prop("disabled", false);
			  return false;
		  });
    },

    async getsubAdminList(page = 1) {
      let vueInstance = this;
      let options = {
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true,
      };
      axiosInstance.get(`admin/sub-admins?page=${page}`).then(response => {
        if(response.error == true){
          vueInstance.$dialog.alert(response.message,options)
          return false;
        }

        if(response && Object.keys( response.data.response.data).length > 0)
          vueInstance.subAdminListData = response.data.response
        else
          vueInstance.checkData= true
      }).catch(error=> {
        vueInstance.flashError(error.message, { timeout: 10000});
      });
	  },
	}
}
</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
