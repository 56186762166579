<template>
  <div v-if="checkedAuthData.token">
    <!-- header -->
    <nav class="navbar navbar-expand-lg navbar-light bg-white after-login">
      <div class="container position-relative">
        <router-link :to="{ name: 'home' }" class="navbar-brand" @click.prevent="">
          <img :src="baseUrl+'/public/images/logo.png'" alt="Life launch Consulting" />
         </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <!-- <div class="d-lg-none">
          <router-link :to="{ name: 'adminNotification' }" class="notification text-green">
            <i class="fas fa-bell"></i>
          </router-link>
          <router-link :to="{ name: 'adminInfo' }" active-class="active" class="login-link">
            <img :src="baseUrl+'/public/images/pic.svg'" class="user-pic">
            {{fullName}}
          </router-link>
        </div>
        <div class="navbar-text">
          <router-link :to="{ name: 'adminNotification' }"  class="notification text-green">
            <i class="fas fa-bell"></i>
          </router-link>
           <router-link :to="{ name: 'adminInfo' }" active-class="active" class="login-link">
             <img :src="baseUrl+'/public/images/pic.svg'" class="user-pic">
            {{fullName}}
          </router-link>
        </div> -->

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto mt-lg-5 pt-lg-2">
            <li class="nav-item">
              <router-link :to="{ name: 'adminDashboard' }" active-class="active" class="nav-link" href="#" >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.934"
                  height="19.934"
                  viewBox="0 0 19.934 19.934"
                >
                  <path
                    id="Icon_material-dashboard"
                    data-name="Icon material-dashboard"
                    d="M4.5,15.574h8.859V4.5H4.5Zm0,8.859h8.859V17.789H4.5Zm11.074,0h8.859V13.359H15.574Zm0-19.934v6.645h8.859V4.5Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#000"
                  />
                </svg>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'adminUserList' }" active-class="active" class="nav-link">Clients</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'adminAssessmentList' }" active-class="active" class="nav-link">Assessment</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'adminPlanList' }" active-class="active" class="nav-link">Launch Plan</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'adminResourcesList' }" active-class="active" class="nav-link">Resources + Exercises</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'adminInquiryList' }" active-class="active" class="nav-link">Inquiries</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'admininviteCoachList' }" active-class="active" class="nav-link">Coach</router-link>
            </li>
            <!-- <li class="nav-item" @click.prevent="logout">
              <a class="nav-link" href="#">Logout</a>
            </li> -->

            <li class="dropdown nav-item" >
              <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </a>
              <ul class="dropdown-menu under-menu" aria-labelledby="dropdownMenuButton1">
                <li class="p-2">
                  <router-link :to="{ name: 'adminPayment' }" active-class=""  class="dropdown-item">Payment Information</router-link>
                </li>
                <li class="p-2">
                  <router-link :to="{ name: 'adminLaterCodeList' }" active-class=""  class="dropdown-item"> Pay Via Code</router-link>
                </li>
                <li class="p-2" v-if="checkedAuthData.loggedInRole != role">
                  <router-link :to="{ name: 'adminSubAdminList' }" active-class=""  class="dropdown-item">Sub Admin</router-link>
                </li>
                <li class="p-2">
                  <router-link :to="{ name: 'adminInfo' }" active-class=""  class="dropdown-item">{{fullName}}</router-link>
                </li>
                <li class="p-2" @click.prevent="logout">
                  <a class="dropdown-item" href="#">Logout</a>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!--header end-->
  </div>
</template>

<script>

import jwt_decode from "jwt-decode";
import { Role } from '../../../helper/role'
export default {
  props:['checkedAuthData'],
  data() {
    return {
      baseUrl: configObj.baseUrl,
      role: Role.SubAdmin
    };
  },
  computed: {
    fullName(){
      const getLocalToken = this.$store.state.auth.authData.token;
      if(getLocalToken){
        const roleInToken = jwt_decode(getLocalToken)
        return roleInToken.first_name +' '+ roleInToken.last_name ;
      }
    },
  },
  methods: {
    async logout() {
      this.$store.dispatch("auth/logoutAdmin");
    },
  },
};
</script>
</script>
<style scoped>
.dropdown-item {
  font-size: 1.6rem;
}
.under-menu {
  right: 0 !important;
  left: auto !important;
}
</style>
