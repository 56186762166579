<template>
  <div>
    <div
      class="
        banner-section
        d-flex
        w-100
        align-items-center
        position-relative
        inner-banner
        justify-content-center
      "
    >
      <img
        :src="baseUrl+'/public/images/banner3.svg'"
        class="d-inline-block banner-img"
        alt="..."
      />
      <div
        class="position-absolute w-100 h-100 d-flex align-items-center top-0"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <h1 class="mb-5 text-white text-center">Card</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section cards-box pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xxl-9">
            <div class="text-end mb-5 pb-md-5">
              <router-link  v-if="showAddCardBtn" :to="{ name: 'addCards', params: { assessmentId: this.$route.params.assessmentId } }" class="btn btn-light">New</router-link>
            </div>

            <div class="row g-5 mb-5">

              <div v-for="(cardList,index) in cardLists" :key="index" class="col-md-6">
                <div class="card-data shadow mb-5">
                  <div class="p-4"> 
                    <div class="text-end mb-4">
                        <img v-if="cardList.card_type == 'Visa'" :src="baseUrl+'/public/images/visa.svg'">
                        <img v-else-if="cardList.card_type == 'MasterCard'" :src="baseUrl+'/public/images/mastercard.svg'">
                        <img v-else-if="cardList.card_type == 'AmericanExpress'" :src="baseUrl+'/public/images/cc-amex-brands.svg'">
                        <img v-else-if="cardList.card_type == 'JCB'" :src="baseUrl+'/public/images/cc-jcb-brands.svg'">
                        <img v-else-if="cardList.card_type == 'Discover'" :src="baseUrl+'/public/images/cc-discover-brands.svg'">
                        <img v-else :src="baseUrl+'/public/images/credit-card-regular.svg'" width="42" height="30">
                    </div>
                    <div class="d-flex text-center mb-4">
                      <div
                        class="flex-fill text-white text20 fw-bold text-start"
                      >
                        XXXX
                      </div>
                      <div class="flex-fill text-white text20 fw-bold">
                        XXXX
                      </div>
                      <div class="flex-fill text-white text20 fw-bold">
                        XXXX
                      </div>
                      <div class="flex-fill text-white text20 fw-bold text-end">
                        {{ cardList.card_number.slice(-4)}}
                      </div>
                    </div>
                    <div class="d-flex justify-content-end"> 
                      <!-- <div class="text-white">
                        <div class="text12">Card Holder</div>
                        <div class="text18 fw-medium">{{ cardList.card_holder_name}}</div>
                      </div> -->
                      <div class="text-white">
                        <div class="text12">Expiry</div>
                        <div class="text18 fw-medium">{{ cardList.expiration_date}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <div class="form-check d-inline-block">
                    <input
                        class="form-check-input form-check-input-big"
                        type="radio"
                        name="flexRadioDefault"
                        :id="cardList.customer_payment_profile_id"
                        v-model="addedCard"
                        @change="onChange($event,cardList.customer_payment_profile_id)"
                        :value="cardList.customer_payment_profile_id"
                    />
                    <label class="form-check-label" :for="cardList.customer_payment_profile_id">
                    </label>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="$v.addedCard.$dirty">
                <p class="text-danger" v-if="!$v.addedCard.required">
                    Please checked your existing card.
                </p>
            </div>
            <flash-message></flash-message>
            <div v-if="showAddBtn" class="pt-md-5 text-center d-flex">
              <a href="#" @click.prevent="deleteCard" class="btn btn-light m-2 flex-fill">Remove</a>
              <button type="button" @click.prevent="payAmount" class="text-center btn btn-primary br-radius m-2 flex-fill">Pay</button>
            </div>
            <!-- <div v-if="showAddBtn" class="d-grid gap-2 col-md-4 mx-auto">
              <a href="#" class="btn btn-primary br-radius">Pay </a>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import axiosInstance from '../../../../helper/axiosInstance'
import { required } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            baseUrl: configObj.baseUrl,
            cardLists:{},
            showAddBtn: false,
            showAddCardBtn: false,
            addedCard:''
        };
    },
    validations: {
        addedCard: {
            required
        },
    },
    mounted () {
        axiosInstance.get('cards').then(response => {
            if(response.error == true){
                this.$router.push({ name: "paymentInfo",params: { assessmentId: this.$route.params.assessmentId } });
                return false;
            }else{ 
                if(response.data.response ){
                    this.cardLists = response.data.response;
                    this.showAddBtn = true;
                    return false;
                }else{
                    this.$router.push({ name: "paymentInfo",params: { assessmentId: this.$route.params.assessmentId } });
                }
            }

        })
    },

  methods: {
        async payAmount(){
            let vueInstance = this;
            vueInstance.error = null;
            vueInstance.$v.$touch();

            if (!vueInstance.$v.$invalid) {
                vueInstance.$router.push({ name: "paymentInfo",params: { assessmentId: vueInstance.$route.params.assessmentId, customerPaymentProfileId: vueInstance.addedCard } });
            }
        },
        async deleteCard(){
            let vueInstance = this;
            vueInstance.error = null;
            vueInstance.$v.$touch();

            if (!vueInstance.$v.$invalid) {
                axiosInstance.delete(`cards/${vueInstance.addedCard}`).then(results => {
                if (results.data.error == false) {
                    vueInstance.$router.push({name:'paymentInfo', params:{  assessmentId: this.$route.params.assessmentId}})
                    return false;
                }else{
                    vueInstance.flashError(results.data.message, { timeout: 10000});
                    vueInstance.spinnerShow = false;
                    $(".btn").prop("disabled", false);
                    return false;
                }
                }).catch((err) => {
                    vueInstance.flashError(err.message, { timeout: 10000});
                    return false;
                });

            }
        },
        onChange(event, customerPaymentProfileId) {
            var data = event.target.value;
            this.addedCard = customerPaymentProfileId
        }
  },
};
</script>
