<template>
  <div>
    <section class="pt-5 section bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="mb-5 text22 fw-medium">Details</div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="p-5 bg-white block-content min-h">
              <div class="mt-5 row justify-content-center">
                <ul
                  class="mb-5 nav nav-pills justify-content-center tabs-links"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'assessmentPaymentDetails',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                      >Payment status</router-link
                    >
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'assessmentCompleteDetails',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Completed Assessments
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link
                      :to="{
                        name: 'uploadedExercise',
                        params: { userId: this.$route.params.userId },
                      }"
                      class="nav-link"
                      type="button"
                    >
                      Uploaded Exercises
                    </router-link>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="h-auto p-5 bg-white block-content">
                      <div class="table-responsive border-table">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th scope="col">Assessment</th>
                              <th scope="col">Payment Method</th>
                              <th scope="col">Enrollment Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                getAssessmentData, indexJ
                              ) in allGetAssessmentPaymentData"
                              :key="indexJ"
                            >
                              <td>{{ getAssessmentData.title }}</td>
                              <td>{{ getAssessmentData.payment_method }}</td>
                              <td>
                                {{
                                  getAssessmentData.enrollment_date | longDate
                                }}
                              </td>
                            </tr>
                            <tr v-if="notAssessmentData">
                              <td colspan="3" class="text-center">
                                <b>No data available</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axiosInstance from "../../../../helper/axiosInstance";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      allGetAssessmentPaymentData: [],
      notAssessmentData: false,
    };
  },
  mounted() {
    this.getAssessmentPayment();
  },

  methods: {
    ...mapActions("adminDashboard", {
      actionAllGetAssessmentStatus: "allGetAssessmentStatus",
    }),
    async getAssessmentPayment() {
      let vueInstance = this;
      vueInstance.notAssessmentData = false;
      let options = {
        okText: "Close",
        animation: "bounce",
        type: "basic",
        backdropClose: true,
      };
      await vueInstance
        .actionAllGetAssessmentStatus({ id: vueInstance.$route.params.userId })
        .then((req) => {
          if (req.error == true) {
            vueInstance.$dialog.alert(req.message, options);
            return false;
          }
          if (req.response.length < 1) {
            vueInstance.notAssessmentData = true;
            return false;
          }
          vueInstance.allGetAssessmentPaymentData = req.response;
        })
        .catch((err) => {
          vueInstance.$dialog.alert(err.message, options);
          return false;
        });
    },
  },
};
</script>
