import axios from 'axios'
import axiosInstance from '../../helper/axiosInstance'
import router from '../../router';
import { Role } from '../../helper/role'

const state = () => ({
    authData: {
        token: "",
        fullName:"",
        loggedInRole:"",
    },
});
const mutations = {
    setAuthData(state, data) {
        const token = data.token;
        const fullName = data.fullName;
        const loggedInRole = data.role;
        if(loggedInRole == Role.User){ 
            const userHaveAskCoachData = data.userHaveAskCoachData;
            const userHaveSupportRequestData = data.userHaveSupportRequestData;
            localStorage.setItem("userHaveAskCoachData", userHaveAskCoachData);
            localStorage.setItem("userHaveSupportRequestData", userHaveSupportRequestData);
        }
        localStorage.setItem("token", token);
    //    localStorage.setItem("fullName", fullName);
        const newAuthData = {
            token: token,
            fullName:fullName,
            loggedInRole:loggedInRole,
        };
        state.authData = newAuthData;
    },
    BEFORE_VERIFIED(state, data) {
        localStorage.setItem("userData", JSON.stringify(data));
    },
};
const getters = {
    getAuthData(state) {
        return state.authData
    },
};
const actions = {
    async login({ commit }, payload) {
        const payloadData = {
            user_name: payload.userName,
            password: payload.password,
        };
        return axiosInstance.post('auth/login', payloadData).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    userHaveAskCoachData: req.data.response.user.userHaveAskCoachData,
                    userHaveSupportRequestData: req.data.response.user.userHaveSupportRequestData,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async signupValidation({ commit }, payload) {
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            mobile: payload.mobile,
            password: payload.password,
        };
        return axiosInstance.post('auth/login-check', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async sinUp({ commit }, payload) {
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            mobile: payload.mobile,
            password: payload.password,
            code: payload.code,
        };
        return axiosInstance.post('auth/register', payloadData).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    userHaveAskCoachData: req.data.response.user.userHaveAskCoachData,
                    userHaveSupportRequestData: req.data.response.user.userHaveSupportRequestData,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async checkExistUser({ commit }, payload) {
        const payloadData = {
            mobile: payload.mobile,
        };
        return axiosInstance.post('auth/forgot/check', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async checkLogin({ commit }, payload) {
        const payloadData = {
            user_name: payload.userName,
            password: payload.password,
        };
        return axiosInstance.post('auth/login', payloadData).then(req => {
            if (req.data.error == false) {
                localStorage.setItem("userLogData", JSON.stringify(req.data.response.user));
                commit('BEFORE_VERIFIED', payloadData)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async firstTimeChangePassword({ commit }, payload) {
        
        return axiosInstance.post('auth/first-time-change-password', payload).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    userHaveAskCoachData: req.data.response.user.userHaveAskCoachData,
                    userHaveSupportRequestData: req.data.response.user.userHaveSupportRequestData,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async sendOTP({ commit }, payload) {
        const payloadData = {
            mobile: payload.mobileNo,
        };
        return axiosInstance.get('otp', {params:payloadData}).then(req => {
            //if (req.data.error == false) {
                return req.data;
           // }
           // return false;
        }).catch(err => {
            throw err;
        })
    },
    async otpVerify({ commit }, payload) {
        const payloadData = {
            mobile: payload.mobileNo,
            code: payload.code,
        };
        return axiosInstance.get('otp/verify', {params:payloadData}).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async logout({ commit }) {
        delete axios.defaults.headers.common['Authorization'];
        const newAuthData = {
            token: "",
            role: "",
            fullName:"",
        };
        commit('setAuthData', newAuthData)
        localStorage.removeItem("token");
        localStorage.removeItem("userHaveAskCoachData");
        localStorage.removeItem("userHaveSupportRequestData");
        router.push({ name: 'login' })
    },
    async profile() { 
        return axiosInstance.get('auth/profile').then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updateProfile({ commit }, payload) { 
        const payloadData = {
            mobile: payload.mobileNo,
            password: payload.password,
            email: payload.email,
        };
        return axiosInstance.post('auth/profile', payloadData).then(req => {
            if (req.data.error == false) { 
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updatePssword({ commit }, payload) {
        const payloadData = {
            mobile: payload.userName,
            password: payload.password,
        };
        return axiosInstance.post('auth/forgot-password', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async logoutAdmin({ commit }) {
        delete axios.defaults.headers.common['Authorization'];
        const newAuthData = {
            token: "",
            role: "",
            fullName: "",
        };
        commit('setAuthData', newAuthData)
        localStorage.removeItem("token");
        router.push({ name: 'adminLogin' })
    },
    async logoutCoach({ commit }) {
        delete axios.defaults.headers.common['Authorization'];
        const newAuthData = {
            token: "",
            role: "",
            fullName: "",
        };
        commit('setAuthData', newAuthData)
        localStorage.removeItem("token");
        router.push({ name: 'coachLogin' })
    },
    async adminLogin({ commit }, payload) {
        const payloadData = {
            user_name: payload.userName,
            password: payload.password,
        };
        return axiosInstance.post('auth/login', payloadData).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async coachSignUp({ commit }, payload) {
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            email: payload.email,
            mobile: payload.mobile,
            password: payload.password,
            code: payload.code,
        };
        return axiosInstance.post('coach/auth/register', payloadData).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async WhenError({ commit }) {
        const data = {
            token: "",
            role: "",
            fullName:"",
          };
        commit('setAuthData', data)
        localStorage.removeItem("token"); 
        localStorage.removeItem("userHaveAskCoachData");
        localStorage.removeItem("userHaveSupportRequestData");
        Vue.prototype.$flashStorage.flash("Something went wrong. Please try to login in again.", 'error',{timeout: 4000}); 
        router.push({name:'login'})
        return false
    },

    async coachInfoUpdate({ commit }, payload) { 
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
        };
        return axiosInstance.put('coach/auth/profile', payloadData).then(req => {
            if (req.data.error == false) { 
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async admimInfoUpdate({ commit }, payload) { 
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
        };
        return axiosInstance.put('admin/auth/profile', payloadData).then(req => {
            if (req.data.error == false) { 
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}