<template>
  <div class="section bg-green bg-green-white position-relative main">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-6 col-xl-5">
          <div class="white-box">
            <div class="text-center mb-5">
              <img :src="baseUrl + '/public/images/logo.svg'" />
            </div>
            <flash-message></flash-message>
            <h3 class="text-center text-green fw-medium mb-5 text28">Admin Log In</h3>
            <div v-if="error" v-html="error">{{ error }}</div>

            <div class="mb-4">
              <label for="exampleInputEmail1" class="form-label d-none"
                >Email address</label
              >
              <input
                type="email"
                v-model.trim="userName"
                @input="$v.userName.$touch()"
                class="form-control email email-input"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="johndoe@gmail.com"
              />
              <div v-if="$v.userName.$dirty">
                <p class="text-danger" v-if="!$v.userName.required">
                  Please enter user name.
                </p>
              </div>
            </div>
            <div class="mb-4">
              <label for="exampleInputPassword1" class="form-label d-none"
                >Password</label
              >
              <div class="input-group mb-3" id="show_hide_password">
                <input
                  :type="passwordFieldType"
                  v-model.trim="password"
                  @input="$v.password.$touch()"
                  class="form-control password-input"
                  placeholder="Enter your password"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <span
                  @click.prevent="switchVisibility"
                  class="input-group-text"
                  id="basic-addon2"
                  ><a href="#" class="eye"
                    ><i :class="[pwIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"></i
                  ></a>
                </span>
              </div>
              <div v-if="$v.password.$dirty">
                <p class="text-danger" v-if="!$v.password.required">
                  Please enter password.
                </p>
                <p class="text-danger" v-if="!$v.password.minLength">
                  Please enter password minimum
                  {{ $v.password.$params.minLength.min }} lenght.
                </p>
              </div>
            </div>
            <div class="d-flex mb-5">
              <div class="flex-fill">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  <label class="form-check-label text-black-50" for="exampleCheck1"
                    >Remember Me</label
                  >
                </div>
              </div>
              <p class="mb-0 text-end flex-fill">
                <router-link :to="{ name: 'adminForgotPassword' }" class="text-dark"
                  >Forgot password?</router-link
                >
              </p>
            </div>

            <div class="text-center mb-5">
              <button
                @click.prevent="loginSubmit"
                type="submit"
                class="btn btn-primary text-center btn-rounded"
              >
                <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { Role } from '../../../helper/role'
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      spinnerShow: false,
      password: "",
      userName: "",
      error: null,
      passwordFieldType: "password",
      pwIcon: false,
    };
  },

  validations: {
    userName: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },

  mounted() {
    localStorage.removeItem("userData");
    localStorage.removeItem("userLogData");
  },

  methods: {
    ...mapActions("auth", {
      actionCheckLogin: "checkLogin",
    }),

    async loginSubmit() {
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        await vueInstance
          .actionCheckLogin({
            userName: vueInstance.userName,
            password: vueInstance.password,
          })
          .then((results) => {

            if (results.error == false) {
              if(results.response.user.account.role == Role.SubAdmin){
                if(results.response.user.first_time_login == true){
                  vueInstance.$router.push({ name: "changePassword" });
                  return false
                }
                vueInstance.$router.push({ name: "adminVerification" });
                return false;
              }

              if(results.response.user.account.role == Role.Admin){
                vueInstance.$router.push({ name: "adminVerification" });
                return false;
              }

              vueInstance.error = `<p class='alert alert-danger'>Please log in with your registered admin credentials<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              localStorage.removeItem("userData");
              localStorage.removeItem("userLogData");
              return false;
            } else {
              vueInstance.error = `<p class='alert alert-danger'>${results.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
              vueInstance.spinnerShow = false;
              $(".btn").prop("disabled", false);
              return false;
            }
          })
          .catch((err) => {
            vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false;
          });

        return false;
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.pwIcon = !this.pwIcon;
    },
  },
};
</script>

<style scoped>
main .section .btn-primary{
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
