<template>
    <div>
        <div
            class="banner-section d-flex w-100 align-items-center position-relative inner-banner justify-content-center"
        >
            <img
                :src="baseUrl + '/public/images/banner3.svg'"
                class="d-inline-block banner-img"
                alt="..."
            />
            <div
                class="position-absolute w-100 h-100 d-flex align-items-center top-0"
            >
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8">
                            <h1 class="mb-5 text-white text-center">
                                Payment Information
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xxl-10">
                        <flash-message></flash-message>
                        <div class="row g-md-5">
                            <div class="col-lg-6 circle-bg text-end">
                                <div class="payment-info-box text-start">
                                    <div class="p-4 para18 text-white pb-0">
                                        Lifelaunch Consulting
                                    </div>
                                    <div
                                        class="text22 text-white p-4 pt-0"
                                    ></div>
                                    <div
                                        class="text24 text-green p-4 pt-5 mt-5 text-end"
                                    >
                                        ${{ subscriptionPeriodPrice }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ms-lg-5">
                                    <div class="mb-5">
                                        <label
                                            for="Card_Number"
                                            class="form-label para-md"
                                            >Card Number</label
                                        >
                                        <input
                                            maxlength="19"
                                            @keyup="formatCardNumber"
                                            v-model.trim="cardNo"
                                            @input="$v.cardNo.$touch()"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            type="text"
                                            class="form-control border-bottom rounded-0 ps-0"
                                            id="Card_Number"
                                            placeholder="XXXX XXXX XXXX XXXX"
                                        />
                                        <div v-if="$v.cardNo.$dirty">
                                            <p
                                                class="text-danger"
                                                v-if="!$v.cardNo.required"
                                            >
                                                Please enter valid card number
                                                field.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-5">
                                        <div class="flex-fill me-3">
                                            <label
                                                for="ex"
                                                class="form-label para-md"
                                                >Expiry Date</label
                                            >
                                            <input
                                                autocomplete="off"
                                                v-on:keyup="
                                                    formatCardExpirationDate
                                                "
                                                maxlength="5"
                                                v-model.trim="cardExpiry"
                                                @input="$v.cardExpiry.$touch()"
                                                type="text"
                                                class="form-control border-bottom rounded-0 ps-0"
                                                id="ex"
                                                placeholder="MM/YY"
                                            />
                                            <div v-if="$v.cardExpiry.$dirty">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        !$v.cardExpiry.required
                                                    "
                                                >
                                                    Please enter card expiry
                                                    date.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex-fill ms-3">
                                            <label
                                                for="cvv"
                                                class="form-label para-md"
                                                >CVV</label
                                            >
                                            <input
                                                autocomplete="off"
                                                maxlength="4"
                                                v-model.trim="cardCVV"
                                                @input="$v.cardCVV.$touch()"
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                type="password"
                                                class="form-control border-bottom rounded-0 ps-0 "
                                                id="cvv"
                                                placeholder="CVV"
                                            />
                                            <div v-if="$v.cardCVV.$dirty">
                                                <p
                                                    class="text-danger"
                                                    v-if="!$v.cardCVV.required"
                                                >
                                                    Please enter card CVV
                                                    number.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-5">
                                        <div class="payment-options" style="">
                                            <div
                                                class="form-check form-check-inline me-lg-5"
                                            >
                                                <input
                                                    v-model="
                                                        subscriptionPeriodPrice
                                                    "
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio2"
                                                    :value="
                                                        assessmentData.yearly_price
                                                    "
                                                    autocomplete="off"
                                                />
                                                <label
                                                    class="form-check-label mt-2"
                                                    for="inlineRadio2"
                                                    >${{
                                                        assessmentData.yearly_price
                                                    }}
                                                    Annual One-time</label
                                                >
                                            </div>
                                            <div
                                                class="form-check form-check-inline"
                                            >
                                                <input
                                                    v-model="
                                                        subscriptionPeriodPrice
                                                    "
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    :value="
                                                        assessmentData.monthly_price
                                                    "
                                                    autocomplete="off"
                                                />
                                                <label
                                                    class="form-check-label mt-2"
                                                    for="inlineRadio1"
                                                    >${{
                                                        assessmentData.monthly_price
                                                    }}
                                                    Monthly</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pt-md-5 text-center d-flex">
                                        <button
                                            @click.prevent="cardSubmit"
                                            type="button"
                                            class="btn btn-primary text-center br-radius m-2 flex-fill"
                                        >
                                            <i
                                                v-if="spinnerShow"
                                                class="fa fa-spinner fa-spin"
                                            ></i
                                            >Pay ${{ subscriptionPeriodPrice }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axiosInstance from "../../helper/axiosInstance";
import axios from 'axios';
import { required } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            baseUrl: configObj.baseUrl,
            spinnerShow: false,
            cardNo: "",
            cardExpiry: "",
            cardCVV: "",
            cardSave: true,
            assessmentData: {},
            subscriptionPeriodPrice: 0,
            yearlyPrice: "",
            monthlyPrice: ""
        };
    },
    validations: {
        cardNo: {
            required
        },
        cardExpiry: {
            required
        },
        cardCVV: {
            required
        }
    },
    mounted() {
        let vueInstance = this;
        axiosInstance.get(`payment/assessments/${vueInstance.$route.query.assessmentId}`).then(req => {
            vueInstance.assessmentData = req.data.response;
            vueInstance.subscriptionPeriodPrice = vueInstance.assessmentData.yearly_price;
            vueInstance.yearlyPrice = vueInstance.assessmentData.yearly_price;
            vueInstance.monthlyPrice = vueInstance.assessmentData.monthly_price;
        })
    },
    methods: {
        async cardSubmit() {
            let vueInstance = this;
            vueInstance.error = null;
            vueInstance.$v.$touch();
            let cardYearMonth = vueInstance.cardExpiry.split("/");

            if (!vueInstance.$v.$invalid) {
                $(".btn").prop("disabled", true);
                vueInstance.spinnerShow = true;
                let subscriptionPeriod = "MONTHLY";
                if (vueInstance.subscriptionPeriodPrice == vueInstance.yearlyPrice) subscriptionPeriod = "ONE-TIME";
                var data = {
                    card_number: vueInstance.cardNo,
                    expiration_date:"20" + cardYearMonth[1] + "-" + cardYearMonth[0],
                    card_code: vueInstance.cardCVV,
                    save_card: vueInstance.cardSave,
                    subscription_type: subscriptionPeriod
                };
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+vueInstance.$route.query.clientId
                }

                axios.post(`${vueInstance.baseUrl}/api/payment/assessments/${vueInstance.$route.query.assessmentId}/subscribe`,
                    data, {
                        headers: headers
                    }
                ).then(results => {
                    if (results.data.error == false) {
                        vueInstance.$router.push({
                            name: "appPaymentSuccess",
                            params: {
                                assessmentId:
                                    vueInstance.$route.params.assessmentId
                            }
                        });
                        return false;
                    } else {
                        vueInstance.flashError(results.data.message, {
                            timeout: 10000
                        });
                        vueInstance.spinnerShow = false;
                        $(".btn").prop("disabled", false);
                        return false;
                    }
                }).catch(err => {
                    vueInstance.flashError(err.message, { timeout: 10000 });
                    vueInstance.spinnerShow = false;
                    $(".btn").prop("disabled", false);
                    return false;
                });
            }
        },

        async formatCardExpirationDate(event) {
            var inputChar = String.fromCharCode(event.keyCode);
            var code = event.keyCode;
            var allowedKeys = [8];
            if (allowedKeys.indexOf(code) !== -1) {
                return;
            }

            event.target.value = event.target.value
                .replace(/^([1-9]\/|[2-9])$/g, "0$1/")
                .replace(/^(0[1-9]|1[0-2])$/g, "$1/")
                .replace(/^([0-1])([3-9])$/g, "0$1/$2")
                .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
                .replace(/^([0]+)\/|[0]+$/g, "0")
                .replace(/[^\d\/]|^[\/]*$/g, "")
                .replace(/\/\//g, "/");
        },

        async formatCardNumber(e) {
            var str = this.cardNo.replace(/[^0-9]/gim, "");
            if (str) {
                var numberChunks = str.match(/.{1,4}/g);
                var result = numberChunks.join(" ");
                $(e.currentTarget).val(result);
            }
        }
    }
};
</script>
<style scoped>
.payment-options .form-check {
    padding-left: 29px;
}
.payment-options .form-check .form-check-input {
    margin-left: -2em;
}
.backBtn {
    font-size: 1.6rem;
}
</style>
