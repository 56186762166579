<template>
  <div> 
    <section class="section bg-grey pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="text-green fw-medium ms-5 ps-3">{{questionListData.assessment ?questionListData.assessment.title: ''}}</div>
            <div class="text22 fw-medium mb-5">
              <router-link :to="{ name: 'adminAssessmentList' }" class="d-inline-block me-4 text-dark"
                ><i class="fas fa-arrow-left"></i
              ></router-link>
              {{questionListData.title}}
            </div>
          </div>
          <div v-if="showAddNtn" class="col-md-4 text-end mb-5">
            <router-link :to="{ name: 'adminQuestionAdd',params: {  assessmentId: this.$route.params.assessmentId,moduleId: this.$route.params.moduleId } }" class="btn btn-primary text-center btn-rounded"
              >Add Questions</router-link>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12"> 
            <div class="block-content p-5 bg-white min-h"> <flash-message></flash-message>
              <div class="table-responsive border-table">
                <table class="table mb-0 table-striped">
                  <thead>
                    <tr> 
                      <th scope="col"></th>
                      <th scope="col">Questions</th>

                      <th scope="col" width="100">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(questionData,index) in questionListData.questions" :key="index">
                      <td class="align-top">{{ ++index}}</td>
                      <td class="align-top">
                        <div class="fw-medium mb-3">
                          {{questionData.question}}
                        </div>
                        <div class="mb-3" v-for="(optionData,index) in questionData.options" :key="index">{{optionData}}</div>
                      </td>

                      <td class="text-end align-top">
                        <div class="d-flex align-items-center justify-content-center">
                          <a @click.prevent="editQuestionModal(questionData.id)" href="#" class="edit-icon me-4"><i class="fas fa-pencil-alt"></i></a>
                        </div>
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
              <div
                class="modal fade"
                id="editQuestionModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg rounded-0">
                  <div class="modal-content rounded-0">
                    <div class="d-flex justify-content-end border-0 p-4">
                      <button @click.prevent="editQuestionPlan" type="button" class="btn btn-green ms-auto me-4">Save</button>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="mb-4">
                        <input v-model.trim="editQuestion" @input="$v.editQuestion.$touch()"
                          type="text"
                          class="form-control bg-grey border fw-medium"
                          placeholder="I have a positive attitude towards my future."
                        />
                        <div v-if="$v.editQuestion.$dirty">
                          <p class="text-danger" v-if="!$v.editQuestion.required">Please enter editQuestion.</p>
                          <p class="text-danger" v-if="!$v.editQuestion.minLength || !$v.editQuestion.maxLength">
                            The question must be between {{$v.editQuestion.$params.minLength.min}} and {{$v.editQuestion.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>
                      <div class="mb-4">
                        <input v-model="editOption1" @input="$v.editOption1.$touch()"
                          type="text"
                          class="form-control bg-grey border"
                          placeholder="No not at all	"
                        />
                        <div v-if="$v.editOption1.$dirty">
                          <p class="text-danger" v-if="!$v.editOption1.required">Please enter option 1.</p>
                          <p class="text-danger" v-if="!$v.editOption1.minLength || !$v.editOption1.maxLength">
                            The options 1 must be between {{$v.editOption1.$params.minLength.min}} and {{$v.editOption1.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>
                      <div class="mb-4">
                        <input v-model="editOption2" @input="$v.editOption2.$touch()"
                          type="text"
                          class="form-control bg-grey border"
                          placeholder="I am slightly concerned	"
                        />
                        <div v-if="$v.editOption2.$dirty">
                          <p class="text-danger" v-if="!$v.editOption2.required">Please enter option 2.</p>
                          <p class="text-danger" v-if="!$v.editOption2.minLength || !$v.editOption2.maxLength">
                            The options 2 must be between {{$v.editOption2.$params.minLength.min}} and {{$v.editOption2.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>
                      <div class="mb-4">
                        <input v-model="editOption3" @input="$v.editOption3.$touch()"
                          type="text"
                          class="form-control bg-grey border"
                          placeholder="I am neutral	"
                        />
                        <div v-if="$v.editOption3.$dirty">
                          <p class="text-danger" v-if="!$v.editOption3.required">Please enter option 3.</p>
                          <p class="text-danger" v-if="!$v.editOption3.minLength || !$v.editOption3.maxLength">
                            The options 3 must be between {{$v.editOption3.$params.minLength.min}} and {{$v.editOption3.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>
                      <div class="mb-4">
                        <input v-model="editOption4" @input="$v.editOption4.$touch()"
                          type="text"
                          class="form-control bg-grey border"
                          placeholder="I am slightly optimistic"
                        />
                        <div v-if="$v.editOption4.$dirty">
                          <p class="text-danger" v-if="!$v.editOption4.required">Please enter option.</p>
                          <p class="text-danger" v-if="!$v.editOption4.minLength || !$v.editOption4.maxLength">
                            The options 4 must be between {{$v.editOption4.$params.minLength.min}} and {{$v.editOption4.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>
                      <input type="hidden" v-model.trim="editQuestionId"/>
                      <div class="mb-4">
                        <input v-model="editOption5" @input="$v.editOption5.$touch()"
                          type="text"
                          class="form-control bg-grey border"
                          placeholder="I am ready to launch"
                        />
                        <div v-if="$v.editOption5.$dirty">
                          <p class="text-danger" v-if="!$v.editOption5.required">Please enter option.</p>
                          <p class="text-danger" v-if="!$v.editOption5.minLength || !$v.editOption5.maxLength">
                            The options 5 must be between {{$v.editOption5.$params.minLength.min}} and {{$v.editOption5.$params.maxLength.max}} characters.
                          </p>
                        </div>
                      </div>

                      <div class="text-end mt-5">
                        <a href="#" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary text-center btn-rounded">Cancel</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
		      </div>
        </div>
      </div>
    </section>
    
  </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
 
  data() {
    return {
      questionListData:{},
      editQuestion: null,
      editOption1: null,
      editOption2: null,
      editOption3: null,
      editOption4: null,
      editOption5: null,
      editQuestionId: null,
      checkquestionData: false,   
      showAddNtn:false,  
    };
  },
  mounted () {
    this.getquestionList();
  },
  validations: {
    editQuestion: {
      required,
       minLength: minLength(2),
      maxLength: maxLength(350)
    },
    editOption1: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    editOption2: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    editOption3: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    editOption4: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
    editOption5: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30)
    },
  },
  methods: {
    ...mapActions("assessment", {
      actionQuestionList: "questionList",
    }),
    ...mapActions("assessment", {
      actionQuestionOption: "updatQuestionOption",
    }),
    async editQuestionPlan(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;

        const data = [vueInstance.editOption1,vueInstance.editOption2,vueInstance.editOption3,vueInstance.editOption4,vueInstance.editOption5]
        await vueInstance.actionQuestionOption({
          questionUpdate: vueInstance.editQuestion,
          optionsUpdate: data,
          editQuestionId: vueInstance.editQuestionId,
          assessmentId: vueInstance.$route.params.assessmentId,
          moduleId: vueInstance.$route.params.moduleId,
        }).then((results) => {
          //  $("html, body").animate({ scrollTop:0 }, "slow");
            if (results.error == true) {  
              vueInstance.flashError(results.message, { timeout: 4000 });
              $('#editQuestionModal').modal('hide')
              return false
            }
            vueInstance.getquestionList()
            vueInstance.flashSuccess(results.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            $('#editQuestionModal').modal('hide')
            return false;
            
          })
          .catch((err) => {
            vueInstance.flashError(err.message, { timeout: 4000 });
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            $('#editQuestionModal').modal('hide')
            return false;
          });
      }
    },

    async editQuestionModal(id){
      let vueInstance = this;
      vueInstance.editQuestion = null
      vueInstance.editOption1 = null
      vueInstance.editOption2 = null
      vueInstance.editOption3 = null
      vueInstance.editOption4 = null
      vueInstance.editOption5 = null
      vueInstance.editQuestionId = null
      const questionData = vueInstance.questionListData.questions.filter(quest => quest.id == id); 
      const editQuestionsData=questionData[0]
      vueInstance.editQuestionId = editQuestionsData.id
      vueInstance.editQuestion = editQuestionsData.question
      vueInstance.editOption1 = editQuestionsData.options[0]
      vueInstance.editOption2 = editQuestionsData.options[1]
      vueInstance.editOption3 = editQuestionsData.options[2]
      vueInstance.editOption4 = editQuestionsData.options[3]
      vueInstance.editOption5 = editQuestionsData.options[4]
      $('#editQuestionModal').modal('show')
    },

    async getquestionList() {
      let vueInstance = this;
      let options = { 
        okText: 'Close',
        animation: 'bounce',
        type: 'basic',
        backdropClose: true, 
      };
      let data ={
        assessmentId: vueInstance.$route.params.assessmentId,
        moduleId: vueInstance.$route.params.moduleId,
      }
      await vueInstance.actionQuestionList(data).then(req => {
			
        if(req.error == true){
          vueInstance.$dialog.alert(req.message,options)
          return false;
        }
        vueInstance.questionListData = req.response
        vueInstance.showAddNtn = (vueInstance.questionListData.questions.length < 5)?true:false
      }).catch((err) => {
        vueInstance.$dialog.alert(err.message,options)
        return false;
      })
	  },
	}
}
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
