<template>
  <div class="container mb-5">

	<div class="row">
		<div class="col-md-12">
			<div class="text22 fw-medium mb-5">My Profile</div>
		</div>
     <flash-message></flash-message>
	</div>
	<div class="block-content p-0">
<div class="row">
	<div class="col-lg-6 bg-green-with-line"></div>
	<div class="col-lg-6">
		<div class="form-box p-lg-5 m-5">
      <div class="mb-5">
        <label for="" class="form-label">First</label>
        <input type="text" v-model.trim="firstName" @input="$v.firstName.$touch()"  class="form-control" id="" placeholder="Enter your first name">
      </div>
      <div class="mb-5">
        <label for="" class="form-label">Last Name</label>
        <input type="text" v-model.trim="lastName" @input="$v.lastName.$touch()"  class="form-control" id="" placeholder="Enter your last name">
      </div>
      <div class="mb-5">
        <label for="" class="form-label">Email Address</label>
        <input type="email" readonly v-model.trim="email" class="form-control" id="" placeholder="Enter your email">
      </div>
      <div class="mb-5">
        <label for="" class="form-label">Phone</label>
        <input type="text" readonly v-model.trim="phoneNo" read class="form-control" id="" placeholder="Enter your Phone #">
      </div>
      <button type="submit"  @click.prevent="updateSubmit" class="btn btn-primary  btn-rounded">Update</button>
		</div>
	</div>
</div>
</div>



</div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      profileData:{},
      spinnerShow: false,
      error: null,
      phoneNo: "",
      email: "",
      firstName: "",
      lastName: "",
    };
  },

  validations: {
    phoneNo: {
      required
    },
    email: {
      required,
      email,
    },
    firstName: {
      required
    },
    lastName: {
      required,
    },
},
created() {
    this.getProfileData();
  },
  methods: {

    ...mapActions("adminDashboard", {
      actionUserInfo: "adminInfo",
    }),
    ...mapActions("auth", {
      actionInfoUpdate: "coachInfoUpdate",
    }),
    async getProfileData(){
      let vueInstance = this;
      await vueInstance.actionUserInfo().then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        vueInstance.profileData = results.response.user;
        var cleaned = ('' + vueInstance.profileData.mobile).replace(/\D/g, '')
        var match= cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          vueInstance.phoneNo= '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        vueInstance.email = vueInstance.profileData.email; 
        vueInstance.firstName = vueInstance.profileData.first_name; 
        vueInstance.lastName = vueInstance.profileData.last_name; 
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },

    async updateSubmit(){
      let vueInstance = this;
      vueInstance.error = null;
      vueInstance.$v.$touch();

      if (!vueInstance.$v.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        $("html, body").animate({ scrollTop:0 }, "slow");
       
        await vueInstance.actionInfoUpdate({
          firstName: vueInstance.firstName,
          lastName: vueInstance.lastName,
        }).then((results) => {
          if (results.error == true) { 
            vueInstance.flashError(results.message, { timeout: 4000 });
            return false
          }
          vueInstance.flashSuccess(results.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        })
        .catch((err) => {
          vueInstance.flashError(err.message, { timeout: 4000 });
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
      }
    },
    
  }
}

</script>

<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
