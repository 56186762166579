<template>
	<section class="section bg-grey">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xxl-8">
					<div class="question-box">
						<h3 class="text-green fw-medium mb-5">{{getFeedbackData.question}}</h3>
						<div class="form-check" v-for="(option, index) in getFeedbackData.options" :key="index">
							<input v-model="feedbackAnswer" :value="option" class="form-check-input" type="radio" id="flexRadioDefault6">
							<label class="form-check-label" for="flexRadioDefault6">
								{{option}}
							</label>
						</div>
						<div v-if="$v.feedbackAnswer.$dirty">
							<p class="text-danger" v-if="!$v.feedbackAnswer.required">
								Please select any one option.
							</p>
						</div>
					</div>
				</div>
				<div v-if="error" v-html="error">{{ error }}</div>
				<div class="text-center mt-5">
					<button @click.prevent="feedbackFormSubmit" type="submit" class="btn btn-primary">
						<i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Submit
					</button>	
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex'	
import { required} from "vuelidate/lib/validators";	
export default {
	name:"feedback",
	props:['getFeedbackData'],
	data() {
		return {
			feedbackAnswer:this.getFeedbackData.selected_option,
			spinnerShow: false,
			error: null,
		};
	},
	validations: {
		feedbackAnswer: {
			required,
		},
	},
	methods: {
		...mapActions("plan", {
			actionFeedbackAnswer: "feedbackAnswerSubmit",
		}),
		async feedbackFormSubmit(){ 
			let vueInstance = this;
			vueInstance.error = null;
			vueInstance.$v.$touch();

			if (!vueInstance.$v.$invalid) {
				$(".btn").prop("disabled", true);
				vueInstance.spinnerShow = true;
				var data={
					feedbackAnswer: vueInstance.feedbackAnswer,
					assessmentId: vueInstance.getFeedbackData.assessment_id,
					assessmentFeedbackId: vueInstance.getFeedbackData.assessment_feedback_id
				}
				await vueInstance.actionFeedbackAnswer(data).then((req) => {
					
					if(req.error == true){
						vueInstance.error = `<p class='alert alert-danger'>${req.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
					 	vueInstance.spinnerShow = false;
						$(".btn").prop("disabled", false);
						return false;
					}
					vueInstance.error = `<p class='alert alert-success'>${req.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
					vueInstance.spinnerShow = false;
					$(".btn").prop("disabled", false);
					return true;
					
				}).catch((err) => {
					vueInstance.error = `<p class='alert alert-danger'>${err.message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>`;
					vueInstance.spinnerShow = false;
					$(".btn").prop("disabled", false);
					return false;
				})
			}
		},
	},
}

</script>
<style scoped>
main .section .btn-primary {
	font-size: 1.4rem;
	min-width: 70px;
	text-align: left;
}
</style>