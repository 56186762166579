<template>
      
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-xl-4">
          <div class="block-content min-h p-0"> 
            <div class="bg-green-with-line success-content flex-column">
              <div class="text-center mb-4">
                <div class="user-profile-box">
                  <img :src="baseUrl + '/public/images/user-big.svg'" />
                </div>
              </div>
              <div class="text-center para15 fw-bold text-white">
                User Added Successfully
              </div>
            </div>
            <div class="p-5 text-center">
              <a href="#" @click.prevent="redirectAddNew" class="btn btn-primary btn-rounded"
                >Add Another User</a
              >
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import evenBus from "../../../event-bus";	
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
    };
  },
  methods:{
    redirectAddNew(){
      evenBus.$emit('addNewUser')
    }
  }
};
</script>
