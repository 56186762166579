<template>
  <section class="section bg-grey pt-5">
    <div class="container"> <flash-message></flash-message>
      <div class="row">
        <div class="col-md-12">
          <div class="text22 fw-medium mb-5"> 
            {{profileData.first_name}} {{profileData.last_name}}
          </div>
        </div>
      </div> 
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="block-content p-5 bg-white min-h">
            <div class="text-center mb-4">
              <div class="text-end">
                <router-link :to="{ name: 'coachUpdateProfile'}" class="edit-icon ms-auto"
                  ><i class="fas fa-pencil-alt" aria-hidden="true"></i
                ></router-link>
              </div>
              <div class="user-profile-box mx-auto">
                <img :src="baseUrl + '/public/images/user-big.svg'" />
              </div>
            </div>
            <div class="text-center">{{profileData.first_name}} {{profileData.last_name}}</div>
            <div class="text-center">{{profileData.email}}</div>
            <div class="text-center">{{phoneNo}}</div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
      profileData: "",
      phoneNo: null,
    };
  },
 
  created(){
    this.getProfileData()
  },
  methods:{
    ...mapActions("adminDashboard", {
      actionUserInfo: "coachUserInfo",
    }),
    async getProfileData(){
      let vueInstance = this;
      await vueInstance.actionUserInfo().then(results => {
        
        if(results.error == true){
          vueInstance.flashError(results.message, { timeout: 10000});
          return false;
        }
        vueInstance.profileData = results.response.user;
        var cleaned = ('' + vueInstance.profileData.mobile).replace(/\D/g, '')
        var match= cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          vueInstance.phoneNo= '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
      }).catch((err) => {
        vueInstance.flashError(err.message, { timeout: 10000});
        return false;
      })
    },
  }
};
</script>
<style scoped>
main .section .btn-primary {
  font-size: 1.4rem;
  min-width: 70px;
  text-align: left;
}
</style>
