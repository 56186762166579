<template>
  <div id="header">
    <!-- header -->
    <nav class="bg-white navbar navbar-expand-lg navbar-light">
      <div class="container position-relative">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
          <img
            :src="baseUrl + '/public/images/logo.png'"
            alt="Life launch Consulting"
          />
        </router-link>
        <button
          v-if="$route.name != 'appPaymentInfo'"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <div
          class="d-lg-none"
          v-if="!checkedAuthData.token && $route.name != 'appPaymentInfo'"
        >
          <router-link
            active-class="active"
            :to="{ name: 'signUp' }"
            class="login-link"
          >
            <svg
              class="me-2"
              xmlns="http://www.w3.org/2000/svg"
              width="17.034"
              height="18.009"
              viewBox="0 0 17.034 18.009"
            >
              <path
                id="user"
                d="M18.026,21.1a.886.886,0,1,1-1.772,0,6.838,6.838,0,0,0-6.745-6.915A6.839,6.839,0,0,0,2.764,21.1.9.9,0,0,1,1.878,22,.9.9,0,0,1,.992,21.1,8.71,8.71,0,0,1,7.187,12.7,4.7,4.7,0,0,1,4.933,8.685a4.639,4.639,0,0,1,4.576-4.69,4.639,4.639,0,0,1,4.576,4.69A4.7,4.7,0,0,1,11.832,12.7,8.71,8.71,0,0,1,18.026,21.1ZM9.509,5.812a2.875,2.875,0,1,0,2.8,2.873,2.843,2.843,0,0,0-2.8-2.873Z"
                transform="translate(-0.992 -3.995)"
                fill-rule="evenodd"
              />
            </svg>
            Sign Up
          </router-link>
        </div>

        <div
          v-if="$route.name != 'appPaymentInfo'"
          class="collapse navbar-collapse"
          id="navbarNavDropdown"
        >
          <ul class="mx-auto navbar-nav">
            <li class="nav-item" v-if="!checkedAuthData.token">
              <router-link
                :to="{ name: 'home' }"
                active-class="active"
                class="nav-link"
                >Home</router-link
              >
            </li>
            <li
              class="nav-item"
              @click.prevent="checkLoginUser('assessmentsInfo')"
            >
              <a href="" class="nav-link">Assessment</a>
            </li>
            <li
              class="nav-item"
              @click.prevent="checkLoginUser('userPlanList')"
            >
              <a href="" class="nav-link">Launch Plan</a>
            </li>
            <li class="nav-item" @click.prevent="checkLoginUser('resource')">
              <a href="" class="nav-link">Resources + Exercises</a>
            </li>
            <li class="nav-item" @click.prevent="checkLoginUser('askCoach')">
              <a href="" class="nav-link">Ask A Coach</a>
            </li>
            <li class="nav-item" @click.prevent="checkLoginUser('support')">
              <a href="" class="nav-link">Support</a>
            </li>
            <li
              class="nav-item"
              v-if="checkedAuthData.token"
              @click.prevent="logout"
            >
              <a href="" class="nav-link">Logout</a>
            </li>
            <li class="nav-item ms-5" v-else>
              <router-link
                :to="{ name: 'login' }"
                active-class="active"
                class="nav-link"
                >Login</router-link
              >
            </li>

            <li class="dropdown nav-item" v-if="checkedAuthData.token">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </a>
              <ul
                class="dropdown-menu under-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li class="p-2">
                  <router-link
                    :to="{ name: 'notification' }"
                    class="dropdown-item"
                  >
                    <i class="fas fa-bell notification text-green"></i>
                    Notification
                  </router-link>
                </li>
                <li class="p-2">
                  <router-link
                    :to="{ name: 'myProfile' }"
                    class="dropdown-item"
                  >
                    <img
                      :src="baseUrl + '/public/images/pic.svg'"
                      class="user-pic notification"
                    />
                    {{ checkedAuthData.fullName }}
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item" v-else>
              <router-link
                active-class="active"
                :to="{ name: 'signUp' }"
                class="mt-2 login-link d-inline-flex align-items-center"
              >
                <svg
                  class="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.034"
                  height="18.009"
                  viewBox="0 0 17.034 18.009"
                >
                  <path
                    id="user"
                    d="M18.026,21.1a.886.886,0,1,1-1.772,0,6.838,6.838,0,0,0-6.745-6.915A6.839,6.839,0,0,0,2.764,21.1.9.9,0,0,1,1.878,22,.9.9,0,0,1,.992,21.1,8.71,8.71,0,0,1,7.187,12.7,4.7,4.7,0,0,1,4.933,8.685a4.639,4.639,0,0,1,4.576-4.69,4.639,4.639,0,0,1,4.576,4.69A4.7,4.7,0,0,1,11.832,12.7,8.71,8.71,0,0,1,18.026,21.1ZM9.509,5.812a2.875,2.875,0,1,0,2.8,2.873,2.843,2.843,0,0,0-2.8-2.873Z"
                    transform="translate(-0.992 -3.995)"
                    fill-rule="evenodd"
                  />
                </svg>
                Sign Up
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!--header end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      baseUrl: configObj.baseUrl,
    };
  },
  computed: {
    ...mapGetters("auth", {
      checkedAuthData: "getAuthData",
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async checkLoginUser(redirectUrl = "") {
      if (
        this.$store.state.auth.authData.token &&
        this.$router.currentRoute.name != redirectUrl
      ) {
        this.$router.push({ name: redirectUrl });
        return false;
      }
      //    this.flashError('The Launch Plan identifies your area of opportunity and connects you with key resources so you can become launch ready', { timeout: 10000});
      if (this.$router.currentRoute.name != redirectUrl)
        this.$router.push({ name: "login" });
    },
    async logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  font-size: 1.6rem;
}
.under-menu {
  right: 0 !important;
  left: auto !important;
}
</style>
